import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { AuditHeader } from '../../../../components/audit/AuditHeader/AuditHeader';
import { DATE_TIME_FORMAT } from '../../../../constants/common';
import { useAuditTestingRequest, useTestingAuditExport } from '../../../../hooks/audit-hooks';
import { ApplicationState } from '../../../../store';
import { AuditState } from '../../../../store/reducers/audit-reducer';

export const AuditTestingPage: React.FC = () => {
  const {loadTestingItems} = useAuditTestingRequest();
  const {exportAuditItemsToCsv} = useTestingAuditExport();
  const auditState = useSelector<ApplicationState, AuditState>(state => state.audit);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {testingItems} = auditState;
  const {data, page, limit, total} = testingItems || {};

  useEffect(() => {
    loadTestingItems(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (value: string) => {
    const newParams = {search: value};

    loadTestingItems(1, newParams);
    setSearchParams(newParams);
  }, 400), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Test Date/Time',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;
          return moment(date).format(DATE_TIME_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Results Date/Time',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {result_date} = original;

          return moment(result_date).format(DATE_TIME_FORMAT);
        },
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {mfp_id} = original;
          const url = `${URLS.USER}/${mfp_id}`;

          return (
            <Link to={url}>View</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    loadTestingItems(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    loadTestingItems(prevPage, searchParams);
  };

  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const handleFilterChange = (value: Date, name: string) => {
    const newParams = {...searchParams, [name]: value};

    setSearchParams(newParams);
    loadTestingItems(1, newParams);
  };

  const exportItems = () => {
    exportAuditItemsToCsv(searchParams.search, total);
  };

  return (
    <div className="audit">
      <AuditHeader
        title="View Testing Audit Trail"
        onExport={exportItems}
        onSearch={handleSearch}
        filterData={searchParams}
        onFilterChange={handleFilterChange}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
