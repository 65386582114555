import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import './PageHeader.scss';

interface PageHeaderSection {
  route: string;
  name: string;
}

interface Props {
  title: string;
  content?: React.ReactNode;
  sections?: PageHeaderSection[];
}

export const PageHeader: React.FC<Props> = (props) => {
  const {pathname} = useLocation();
  const {title, content, sections = []} = props;

  const renderSectionsButtons = () => {
    return sections.map((item, index) => {
      const {route, name} = item;
      const linkClass = classNames('page-header__section-btn', {
        'page-header__section-btn--active': route === pathname,
      });

      return (
        <Link className={linkClass} key={index} to={route}>{name}</Link>
      );
    });
  };

  return (
    <div className="page-header">
      <div className="page-header__title">{title}</div>
      <div className="page-header__content">
        {content}
      </div>
      <div className="page-header__sections">
        {renderSectionsButtons()}
      </div>
    </div>
  );
};
