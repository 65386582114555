import React, { useEffect, useState } from 'react';
import { FormCard } from "../../../components/_common/FormCard/FormCard";

import './TwoFactorAuthPage.scss';

import QRCode from "react-qr-code";
import { Button } from '../../../components/_common/_controls/Button/Button';
import { Input } from '../../../components/_common/_controls/Input/Input';
import { Checkbox } from '../../../components/_common/_controls/Checkbox/Checkbox';
import { useTwoFactorHooks } from '../../../hooks/two-factor-hooks';
import { Timer } from '../../../components/_common/TimerV2/Timer';
import RenderCountdown from '../../../components/_common/TimeV2Render/TimerRender';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const TwoFactorAuthPage: React.FC = () => {
    const { email, qrCode, expiration, getQR, sendCode } = useTwoFactorHooks();
    const history = useHistory();

    const [expired, setExpired] = useState(false);
    const [code, setCode] = useState<number | string>('');
    const [saveToken, setSaveToken] = useState(false);

    return (
      <div>
        <FormCard>
          <div className="two-factor">
            <div className='two-factor__user'>
              <div className='label'>YOU'RE LOGGING IN AS</div>
              <div className='h4 email'>{email}</div>
            </div>
            <div className='h3 two-factor__title'>Two-factor Authentication</div>
            <div className="two-factor__subtitle">Check your Match Fit Pass mobile app for an option to scan the below QR code, or enter the authentication code manually:</div>
            <div className="two-factor__qr-actions">
              <div className='qr'>
                { qrCode && 
                  <QRCode value={`MFP2FA/${qrCode}/${saveToken}`} size={240}/>
                }
              </div> 
              <Button 
                title='Resend Code' 
                type='outline' 
                disabled={!expired}
                onClick={async () => { 
                  setExpired(false);
                  getQR();
                }} 
              />
              <Timer date={expiration} render={RenderCountdown} setComplete={setExpired}/>
            </div>
              <div className='input-label'>Enter your authentication code:</div>
            <div className='two-factor__code-actions'>
              <Input
                value={code}
                name="auth_code"
                placeholder={"Enter authentication code"}
                onChange={(e) => {
                  const val = parseInt(e);
                  console.log(_.isEmpty(e), _.isFinite(val), val);
                  if (_.isFinite(val) && e.length <= 6) { 
                    setCode(val)
                  } else if (_.isEmpty(e)) {
                    setCode('') 
                  }
                }}
              />
              <Button 
                title='Continue' 
                type='primary-2' 
                onClick={() => {
                  if (_.isNumber(code)) sendCode(code, saveToken)
                }}
                disabled={expired}
              />
            </div>
            <Checkbox 
              text="Don’t ask again on this computer" 
              checked={saveToken} 
              onChange={(val) => setSaveToken(val)}/> 
          </div>
        </FormCard>
        <div 
          className='relogin' 
          onClick={() => history.push('/')}
        >
          Log in with a different account
        </div>
      </div>
    )
}


export default TwoFactorAuthPage;