import { CustomAction } from '../index';
import { HOSTS_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import { Host, HostUser } from '../../types/hosts';
import { hostDefaults } from '../../data/defaults';
import { AppUserTicket } from '../../types/users';
import { Audit } from '../../types/audit';
import { Invoice } from '../../types/invoices';

export interface HostsState {
  hostsItems: CommonEntityRes<Host>;
  host: {
    data: Host;
    tickets: CommonEntityRes<AppUserTicket>;
    audit: CommonEntityRes<Audit>;
    users: HostUser[];
    invoices: Invoice[];
  },
}

const defaultState: HostsState = {
  hostsItems: {data: [], page: 1},
  host: {
    data: hostDefaults,
    tickets: {data: []},
    audit: {data: []},
    users: [],
    invoices: [],
  }
};

export default function hostsReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case HOSTS_ACTION_TYPES.SET_HOSTS: {
      return {
        ...state,
        hostsItems: action.data,
      };
    }
    case HOSTS_ACTION_TYPES.SET_HOST: {
      const {hostData} = action.data;

      return {
        ...state,
        host: {
          ...state.host,
          data: hostData,
        },
      };
    }
    case HOSTS_ACTION_TYPES.RESET_HOST: {
      return {
        ...state,
        host: {
          data: hostDefaults,
          tickets: {data: []},
          audit: {data: []},
        },
      };
    }
    case HOSTS_ACTION_TYPES.SET_HOST_TICKETS: {
      return {
        ...state,
        host: {
          ...state.host,
          tickets: action.data,
        },
      };
    }
    case HOSTS_ACTION_TYPES.SET_HOST_AUDIT: {
      return {
        ...state,
        host: {
          ...state.host,
          audit: action.data,
        },
      };
    }
    case HOSTS_ACTION_TYPES.SET_HOST_USERS: {
      return {
        ...state,
        host: {
          ...state.host,
          users: action.data,
        },
      };
    }
    case HOSTS_ACTION_TYPES.SET_HOST_INVOICES: {
      return {
        ...state,
        host: {
          ...state.host,
          invoices: action.data,
        },
      };
    }
    default:
      return state;
  }
}
