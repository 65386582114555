import { useDispatch } from 'react-redux';
import { API_BASE_URL, API_ENDPOINTS, API_TESTING_BASE_URL, API_VACCINES_BASE_URL } from '../constants/api';
import { Http } from '../services/http';
import { CommonEntityRes, CommonObject } from '../types/common';
import { AdminCreateEditEntity, AdminFormEntity, AdminUser, AppUser, AppUserAppNotificationSettings, AppUserGuardian, AppUserOverrideStatus, AppUserPhoneVerificationStatus, AppUserTicket, VaccinationUser, VaccinationUserFull } from '../types/users';
import {
  actionSetAdminUser,
  actionSetAdminUsers,
  actionSetAppUser,
  actionSetAppUserAudit,
  actionSetAppUserGuardian,
  actionSetAppUsers,
  actionSetAppUserTesting,
  actionSetAppUserTickets,
  actionSetTestingUser,
  actionSetTestingUserAdministered,
  actionSetTestingUserRegistered,
  actionSetTestingUsers,
  actionSetTotalAppUsers,
  actionSetVaccinationUser,
  actionSetVaccinationUserBatches,
  actionSetVaccinationUserInoculated,
  actionSetVaccinationUsers,
  actionSetVerificationUsers,
} from '../store/actions/users-actions';

import { AdminPermissionsNames, OVERRIDE_STATUS } from '../constants/users';
import { Audit } from '../types/audit';
import { TestingUser } from '../types/testing';
import { appUserGuardian } from '../data/defaults';

export function useUsersRequest() {
  const dispatch = useDispatch();

  const loadAdminUsers = async (page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.ADMIN_USERS}?page=${page}&limit=10`;
      const res: CommonEntityRes<AdminUser> = await Http.get(url);

      dispatch(actionSetAdminUsers(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAdminUser = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.ADMIN_USERS}/${id}`;
      const res: AdminUser = await Http.get(url);

      dispatch(actionSetAdminUser(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUsers = async (page: number, searchParams: CommonObject) => {
    try {
      const { search } = searchParams;
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}?page=${page}&limit=10&search=${search}`;
      const res: CommonEntityRes<AppUser> = await Http.get(url);

      dispatch(actionSetAppUsers(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUsersCount = async () => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}?page=0&limit=&search=`;
      const res: CommonEntityRes<AppUser> = await Http.get(url);

      dispatch(actionSetTotalAppUsers(res.total));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppVerificationUsers = async (page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}?page=${page}&limit=10&manualReview=true&search=`;
      const res: CommonEntityRes<AppUser> = await Http.get(url);

      dispatch(actionSetVerificationUsers(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUser = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${id}`;
      const res: AppUser = await Http.get(url);

      dispatch(actionSetAppUser(res));
    } catch (e) {
      console.log(e);
    }
  };

  const updateAppUser = async (id: string, data: any) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${id}`;
      const res = await Http.put(url, data);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUserGuardian = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${id}/${API_ENDPOINTS.GUARDIAN}`;
      const res: AppUserGuardian = await Http.get(url);

      let guardian = appUserGuardian;
      if (res) {
        guardian = res;
      }

      dispatch(actionSetAppUserGuardian(guardian));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUserTickets = async (userId: string, page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${userId}/${API_ENDPOINTS.TICKETS}?page=${page}&limit=10`;
      const tickets: CommonEntityRes<AppUserTicket> = await Http.get(url);

      dispatch(actionSetAppUserTickets(tickets));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUserAudit = async (userId: string, page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${userId}/${API_ENDPOINTS.TRACKING}?page=${page}&limit=10`;
      const audits: CommonEntityRes<Audit> = await Http.get(url);

      dispatch(actionSetAppUserAudit(audits));
    } catch (e) {
      console.log(e);
    }
  };

  const loadAppUserTesting = async (userId: string, page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${userId}/${API_ENDPOINTS.TESTING}?page=${page}&limit=10`;
      const res: CommonEntityRes<any> = await Http.get(url);

      dispatch(actionSetAppUserTesting(res));
    } catch (e) {
      console.log(e);
    }
  };

  const createAdminUser = async (data: AdminFormEntity, showMessage: boolean): Promise<boolean> => {
    try {
      const { mfp_id, ...permissions } = data;

      const permissionsArr = Object.keys(permissions).map((key) => {
        return {
          name: AdminPermissionsNames[key],
          value: permissions[key],
        };
      });
      const requestData: AdminCreateEditEntity = {
        mfp_id,
        permissions: permissionsArr,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.ADMIN_USERS}`;
      await Http.post(url, requestData);

      if (showMessage) {
        alert('Admin user is created');
      }

      return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  const updateAdminUser = async (id: string, data: AdminFormEntity, showMessage: boolean): Promise<boolean> => {
    try {
      const { mfp_id, ...permissions } = data;
      const permissionsArr = Object.keys(permissions).map((key) => {
        return {
          name: AdminPermissionsNames[key],
          value: permissions[key],
        };
      });
      console.log(1111);
      debugger;
      const requestData: AdminCreateEditEntity = {
        mfp_id,
        permissions: permissionsArr,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.ADMIN_USERS}/${id}`;
      await Http.put(url, requestData);

      if (showMessage) {
        alert('Admin user is updated');
      }

      return true;
    } catch (e) {
      console.log(e);
      alert(e);
      return false;
    }
  };

  return {
    loadAppUsers,
    loadAppUser,
    loadAppUserGuardian,
    updateAppUser,
    loadAppVerificationUsers,
    loadAdminUsers,
    loadAdminUser,
    createAdminUser,
    updateAdminUser,
    loadAppUserAudit,
    loadAppUserTickets,
    loadAppUsersCount,
    loadAppUserTesting,
  };
}

export function useVaccinationUsersRequest() {
  const dispatch = useDispatch();

  const loadVaccinationUsers = async (page: number, params: CommonObject) => {
    try {
      const { search } = params;
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USERS}?page=${page}&limit=10&search=${search}`;
      const res: CommonEntityRes<VaccinationUser> = await Http.get(url);

      dispatch(actionSetVaccinationUsers(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadVaccinationUsers };
}

export function useVaccinationUserRequest() {
  const dispatch = useDispatch();

  const loadUser = async (id: string) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USERS}/${id}`;
      const res: VaccinationUserFull = await Http.get(url);
      const { mfp_id } = res;

      const batchesUrl = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USER_BATCHES.replace('{mfp_id}', mfp_id)}`;
      const batchesRes = await Http.get(batchesUrl);

      const inoculatedUrl = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USER_INOCULATED.replace('{mfp_id}', mfp_id)}`;
      const inoculatedRes = await Http.get(inoculatedUrl);

      dispatch(actionSetVaccinationUser(res));
      dispatch(actionSetVaccinationUserBatches(batchesRes.data));
      dispatch(actionSetVaccinationUserInoculated(inoculatedRes.data));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadUser };
}

export function useTestingUsersRequest() {
  const dispatch = useDispatch();

  const loadTestingUsers = async (page: number, params: CommonObject) => {
    try {
      const { search, centre } = params;
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}?page=${page}&limit=10&search=${search}&centre_id=${centre}`;
      const res: CommonEntityRes<TestingUser> = await Http.get(url);

      dispatch(actionSetTestingUsers(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadTestingUsers };
}

export function useTestingUserRequest() {
  const dispatch = useDispatch();

  const loadUser = async (id: string) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}/${id}`;
      const res: VaccinationUserFull = await Http.get(url);
      const { mfp_id } = res;

      const registeredUrl = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USER_REGISTERED.replace('{mfp_id}', mfp_id)}`;
      const registeredRes = await Http.get(registeredUrl);

      const administeredUrl = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USER_ADMINISTERED.replace('{mfp_id}', mfp_id)}`;
      const administeredRes = await Http.get(administeredUrl);

      dispatch(actionSetTestingUser(res));
      dispatch(actionSetTestingUserRegistered(registeredRes.data));
      dispatch(actionSetTestingUserAdministered(administeredRes.data));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadUser };
}

export function useAppUserRegistrationDetails() {
  const updateUserOverrideStatus = async (id: string, override_status: OVERRIDE_STATUS, showMessage: boolean): Promise<boolean> => {
    try {
      const body: AppUserOverrideStatus = {
        override_status,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.OVERRIDE_STATUS.replace('{mfp_id}', id)}`;
      await Http.post(url, body);
      console.log(url, body);
      if (showMessage) {
        alert('User registration override status has been updated');
      }
      return true;
    } catch (e) {
      console.log(e);
      alert(e);
      return false;
    }
  };

  const getUserPhoto = async (id: string, type: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${id}${API_ENDPOINTS.USER_PHOTO}?id=${id}&type=${type}`;

      const res = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  const updatePhoneVerificationStatus = async (id: string) => {
    try {
      const body: AppUserPhoneVerificationStatus = {
        phone_verified: true,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.PHONE_VERIFICATION_STATUS.replace('{mfp_id}', id)}`;
      await Http.post(url, body);

      alert('User phone verification status has been skipped');

      return true;
    } catch (e) {
      console.log(e);
      alert(e);
      return false;
    }
  };

  return {
    updateUserOverrideStatus,
    updatePhoneVerificationStatus,
    getUserPhoto,
  };
}

export function useAppUserNotificationSettings() {
  const getUserNotificationSettings = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.USER_NOTIFICATION_SETTINGS.replace('{mfp_id}', id)}`;

      const res: Array<AppUserAppNotificationSettings> = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  const updateUserNotificationSettings = async (id: string, settings: Array<AppUserAppNotificationSettings>) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.USER_NOTIFICATION_SETTINGS.replace('{mfp_id}', id)}`;
      await Http.post(url, settings);

      return true;
    } catch (e) {
      console.log(e);
      alert(e);
      return false;
    }
  };

  return {
    getUserNotificationSettings,
    updateUserNotificationSettings,
  };
}
