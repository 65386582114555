export const URLS = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  SIGN_IN_2FA: '/sign-in/2fa',
  FORGOT_PASSWORD: '/reset-password',
  HOSTS: '/hosts',
  HOST: '/host',
  PLACEHOLDER: '/empty',
  HOST_CREATE: '/create-host',
  USERS: '/users',
  FOR_VERIFICATION: '/user-verifications',
  USER: '/user',
  MFP_USERS: '/mfp-users',
  MFP_USER: '/mfp-user',
  VACCINATION_USERS: '/vaccine-users',
  VACCINATION_USER: '/vaccine-user',
  TESTING_USERS: '/testing-users',
  TESTING_USER: '/testing-user',
  AUDIT: '/audit',
  TRAIL: '/trail',
  TRACK: '/track',
  BROADCAST_MESSAGE: '/broadcast',
  CODES: '/codes',
  CODES_CREATE: '/code',
  INVOICE: '/invoices',
  CREATE: '/create',
  BAND: '/band',
  VACCINE: '/vaccine',
  TESTING: '/testing',
  VACCINATION: '/vaccination',
  CENTERS: '/centers',
  CENTER: '/center',
  BATCHES: '/batches',
  REGISTERED: '/registered',
  ADMINISTERED: '/administered',
  PARAMS: {
    ID: ':id',
    ID_OPTIONAL: ':id?',
  },
};
