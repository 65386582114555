import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvoiceHeader } from '../../../../components/invoice/InvoiceHeader/InvoiceHeader';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { BandForm } from '../../../../components/invoice/BandForm/BandForm';
import { useInvoicesRequest } from '../../../../hooks/invoices-hooks';
import { InvoicesState } from '../../../../store/reducers/invoices-reducer';
import { ApplicationState } from '../../../../store';
import { CreateBandData } from '../../../../types/invoices';
import { actionSetBand } from '../../../../store/actions/invoices-actions';
import { Button } from '../../../../components/_common/_controls/Button/Button';

import './InvoiceBandPage.scss';

export const InvoiceBandPage: React.FC = () => {
  const dispatch = useDispatch();
  const {loadBands, createBand, updateBand} = useInvoicesRequest();
  const invoicesState = useSelector<ApplicationState, InvoicesState>(state => state.invoices);
  const [bandIsSaved, setBandIsSaved] = useState(false);
  const {bandsItems, activeBand} = invoicesState;
  const activeBandId = activeBand.id;

  useEffect(() => {
    loadBands();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Band Name',
        accessor: 'name',
      },
      {
        Header: 'Annual Charge',
        accessor: 'annual_charge',
      },
      {
        Header: 'Venue Size',
        accessor: 'venue_size',
      },
      {
        Header: 'Price Per Ticket',
        accessor: 'price_per_ticket',
      },
      {
        Header: 'Edit',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.INVOICE}/${id}`;

          const handleClick = () => {
            dispatch(actionSetBand(original));
          };

          return (
            <Button title="Edit" htmlType="button" onClick={handleClick}>Edit</Button>
          );
        },
      },
    ],
    [],
  );

  const onSubmit = async (data: CreateBandData) => {
    setBandIsSaved(false);

    if (activeBandId) {
      await updateBand(data, activeBandId);
      loadBands();
      return;
    }

    const bandIsCreated = await createBand(data);

    if (bandIsCreated) {
      setBandIsSaved(true);
      loadBands();
    }
  };

  return (
    <div>
      <InvoiceHeader title="Pricing Bands" hideActions />
      <div className="page-content">
        <Table title="Current Ticket Bands" data={bandsItems} columns={columns} />
        <BandForm isNew={!activeBandId} data={activeBand} isSaved={bandIsSaved} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
