import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../constants/api';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { CommonObject } from '../types/common';
import { LocalStorage } from './storage';
import axios from 'axios';

// API Instance
const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// API Interceptors
instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.baseURL = API_BASE_URL;
    config.headers.authorization = `Bearer ${LocalStorage.get(USER_TOKEN_NAMESPACE)}`;
    return config;
  },
  (error) => {
    console.log('Error in request: ', error);
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // Do something with response data
    if (response.error || response.statusCode) {
      return Promise.reject(response);
    }

    return {
      data: response.data,
      status: response.status,
    };
  },
  (error) => {
    console.log('Error in request: ', error);
    // Do something with request error
    return Promise.reject(error);
  }
);

// API Methods (abstracted for debugging control)
export const API = {
  post(endpoint: string, formData: CommonObject): AxiosPromise {
    return instance.post(endpoint, formData);
  },

  put(endpoint: string, formData: CommonObject): AxiosPromise {
    return instance.put(endpoint, formData);
  },

  get(endpoint: string, config?: AxiosRequestConfig<CommonObject>): AxiosPromise {
    return instance.get(endpoint, config);
  },

  delete(endpoint: string): AxiosPromise {
    return instance.delete(endpoint);
  },
  patch(endpoint: string): AxiosPromise {
    return instance.patch(endpoint);
  },
};
