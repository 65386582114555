import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { FormFooter } from '../../_common/Form/FormFooter/FormFooter';
import { Button } from '../../_common/_controls/Button/Button';
import { FilePicker } from '../../_common/_controls/FilePicker/FilePicker';
import { TestingDoctorCreateFormEntity } from '../../../types/testing';
import { testingDoctorCreateDefaults, vaccinationCenterCreateDefaults } from '../../../data/defaults';

interface Props {
  isSaved?: boolean;
  isNew?: boolean;
  title?: string;
  onSubmit: (data: TestingDoctorCreateFormEntity) => void;
}

export const TestingDoctorForm: React.FC<Props> = (props) => {
  const {isNew, title, isSaved, onSubmit} = props;
  const [formData, setFormData] = useState<TestingDoctorCreateFormEntity>(testingDoctorCreateDefaults);
  const {name, address1, address2, phone, city, email, key_contact, postcode} = formData;
  const actionTitle = isNew ? 'Create' : 'Update';
  const [dataError, setDataError] = useState({
    name: 'Required Field',
    nameDirty: false,
    contact: 'Required Field',
    contactDirty: false,
    phone: 'Required Field',
    phoneDirty: false,
    email: 'Required Field',
    emailDirty: false,
    address: 'Required Field',
    addressDirty: false,
    city: 'Required Field',
    cityDirty: false,
    postCode: 'Required Field',
    postCodeDirty: false
  })
  const onBlurArea = (name: string) => {
    switch (name) {
      case 'name' :
        return formData.name.length === 0 ?
          setDataError({...dataError, nameDirty: true}) :
          setDataError({...dataError, nameDirty: false})
      case 'address1' :
        return formData.address1.length === 0 ?
          setDataError({...dataError, addressDirty: true}) :
          setDataError({...dataError, addressDirty: false})
      case 'key_contact' :
        return formData.key_contact.length === 0 ?
          setDataError({...dataError, contactDirty: true}) :
          setDataError({...dataError, contactDirty: false})
      case 'phone' :
        return formData.phone.length === 0 ?
          setDataError({...dataError, phoneDirty: true}) :
          setDataError({...dataError, phoneDirty: false})
      case 'city' :
        return formData.city.length === 0 ?
          setDataError({...dataError, cityDirty: true}) :
          setDataError({...dataError, cityDirty: false})
      case 'email' :
        return formData.email.length === 0 ?
          setDataError({...dataError, emailDirty: true}) : null

      case 'postcode' :
        return formData.postcode.length === 0 ?
          setDataError({...dataError, postCodeDirty: true}) :
          setDataError({...dataError, postCodeDirty: false})
      default:
        return setDataError({...dataError, addressDirty: false})
    }
  }

  useEffect(() => {
    if (isSaved) {
      setFormData(testingDoctorCreateDefaults);
    }
  }, [isSaved]);

  const handleFieldChange = (value: any, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.length !== 0) {
      onBlurArea(name)
    } else {
      onBlurArea(name)
    }
    if(name === 'email'){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({...dataError, email: 'Incorrect Email', emailDirty: true})
      } else {
        setDataError({...dataError, email: '', emailDirty: false})
      }
    }
    if(name === 'phone'){
      const re = /^\d{1,}$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({...dataError, phone: 'Incorrect Phone', phoneDirty: true})
      } else {
        setDataError({...dataError, phone: '', phoneDirty: false})
      }
    }

  };
  const handleFormSubmit = () => {
    if (formData.name.length === 0 ||
      formData.key_contact.length === 0 ||
      formData.phone.length === 0 ||
      formData.email.length === 0 ||
      formData.address1.length === 0 ||
      formData.city.length === 0 ||
      formData.postcode.length === 0 ||
      dataError.phone === 'Incorrect Phone' ||
      dataError.email === 'Incorrect Email') {
      alert('Please check all fields')
    }else {
      return onSubmit(formData);
    }
  };
  return (
    <Form className="vc-form" onSubmit={handleFormSubmit}>
      <FormGroup title={title}>
        <Input
          onBlur={onBlurArea}
          value={name}
          error={dataError.name && dataError.nameDirty ? true : false}
          title={dataError.name && dataError.nameDirty ? `Test Centre Doctor Name-${dataError.name}` : "Test Centre Doctor Name"}
          placeholder="Centre Name"
          name="name"
          onChange={handleFieldChange}
        />
        <Input
          value={address1}
          onBlur={onBlurArea}
          error={dataError.address && dataError.addressDirty ? true : false}
          title={dataError.address && dataError.addressDirty ? `Address Line 1-${dataError.address}` : "Address Line 1"}
          placeholder="Address Line 1"
          name="address1"
          onChange={handleFieldChange}
        />
        <Input
          value={phone}
          error={dataError.phone && dataError.phoneDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.phone && dataError.phoneDirty ? `Phone Number-${dataError.phone}` : "Phone Number"}
          placeholder="Phone Number"
          name="phone"
          onChange={handleFieldChange}
        />
        <Input
          value={address2}
          title="Address Line 2"
          placeholder="Address Line 2"
          name="address2"
          onChange={handleFieldChange}
        />
        <Input
          value={key_contact}
          onBlur={onBlurArea}
          error={dataError.contact && dataError.contactDirty ? true : false}
          title={dataError.contact && dataError.contactDirty ? `Key Contact-${dataError.contact}` : "Key Contact"}
          placeholder="Key Contact"
          name="key_contact"
          onChange={handleFieldChange}
        />
        <Input
          value={city}
          error={dataError.city && dataError.cityDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.city && dataError.cityDirty ? `City/Town-${dataError.city}` : "City/Town"}
          placeholder="City/Town"
          name="city"
          onChange={handleFieldChange}
        />
        <Input
          value={email}
          error={dataError.email && dataError.emailDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.email && dataError.emailDirty ? `Email Address-${dataError.email}` : "Email Address"}
          placeholder="Email Address"
          name="email"
          onChange={handleFieldChange}
        />
        <FilePicker
          title="Signature"
          name="signature"
          onChange={handleFieldChange}
        />
        <Input
          value={postcode}
          error={dataError.postCode && dataError.postCodeDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.postCode && dataError.postCodeDirty ? `Postcode-${dataError.postCode}` : "Postcode"}
          placeholder="Postcode"
          name="postcode"
          onChange={handleFieldChange}
        />

      </FormGroup>
      <FormFooter>
        <Button title={actionTitle} htmlType="submit" />
      </FormFooter>
    </Form>
  );
};
