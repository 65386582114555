import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../components/_common/PageHeader/PageHeader';
import { BroadcastForm } from '../../../components/broadcast/BroadcastForm/BroadcastForm';
import { Tabs } from '../../../components/_common/Tabs/Tabs';
import { VaccineBroadcastForm } from '../../../components/vaccination/VaccineBroadcastForm/VaccineBroacastForm';
import { BroadcastCreate, TestingBroadcastFormEntity, VaccinationBroadcastFormEntity } from '../../../types/broadcast';
import {
  useBroadcastMessageRequest,
  useBroadcastRequest,
  useBroadcastTestingMessageRequest
} from '../../../hooks/broadcast-hooks';
import { useHostsRequest } from '../../../hooks/hosts-hooks';
import { useVacCentersLoadingRequest } from '../../../hooks/vaccination-hooks';
import { TestingBroadcastForm } from '../../../components/testing/TestingBroadcastForm/TestingBroacastForm';

import './BroadcastMessagePage.scss';

export const BroadcastMessagePage: React.FC = () => {
  const { createMessage } = useBroadcastRequest();
  const { loadHosts } = useHostsRequest();
  const {loadCenters: loadVaccinationCenters} = useVacCentersLoadingRequest();
  const {createMessage: createVaccineMessage} = useBroadcastMessageRequest();
  const {createMessage: createTestingMessage} = useBroadcastTestingMessageRequest();
  const [messageIsSaved, setMessageIsSaved] = useState(false);
  const [vaccineMessageIsSaved, setVaccineMessageIsSaved] = useState(false);

  useEffect(() => {
    loadHosts();
    loadVaccinationCenters();
  }, []);

  const handleCreate = async (data: BroadcastCreate) => {
    setMessageIsSaved(false);

    const messageIsCreated = await createMessage(data);

    if (messageIsCreated) {
      setMessageIsSaved(true);
    }
  };

  const handleVaccineCreate = async (data: VaccinationBroadcastFormEntity) => {
    setVaccineMessageIsSaved(false);

    const messageIsCreated = await createVaccineMessage(data);

    if (messageIsCreated) {
      setVaccineMessageIsSaved(true);
    }
  };

  const handleTestingCreate = (data: TestingBroadcastFormEntity) => {
    return createTestingMessage(data);
  };

  return (
    <div className="broadcast">
      <PageHeader title="Broadcast Message" />
      <Tabs titles={['Host Messaging', 'Vaccine Messaging', 'Testing Messaging']}>
        <div>
          <BroadcastForm isSaved={messageIsSaved} onSubmit={handleCreate} />
        </div>
        <div>
          <VaccineBroadcastForm isSaved={vaccineMessageIsSaved} onSubmit={handleVaccineCreate} />
        </div>
        <div>
          <TestingBroadcastForm onSubmit={handleTestingCreate} />
        </div>
      </Tabs>
    </div>
  );
};
