export enum TestsType {
  FTT = 'ftt',
  FTR = 'ftr',
  FTF = 'ftf',
}

export enum TESTING_STATUS {
  ALWAYS_REQUIRED = 'ALWAYS_REQUIRED',
  REQUIRED_VACCINE_INCOMPLETE = 'REQUIRED_VACCINE_INCOMPLETE',
  NOT_REQUIRED_VACCINATED = 'NOT_REQUIRED_VACCINATED',
}

export enum TESTING_TYPES {
  ANY = 'ANY',
  PCR = 'PCR',
  LFD = 'LFD',
}

export const TestingStatus = {
  [TESTING_STATUS.ALWAYS_REQUIRED]: 'Always required',
  [TESTING_STATUS.REQUIRED_VACCINE_INCOMPLETE]:
    'Required only when Vaccination Status is insufficient',
  [TESTING_STATUS.NOT_REQUIRED_VACCINATED]: 'Not required',
};

export const TestingTypes = {
  [TESTING_TYPES.ANY]: 'Any',
  [TESTING_TYPES.PCR]: 'Polymerase Chain Reaction (PCR)',
  [TESTING_TYPES.LFD]: 'Lateral Flow Device (LFD)',
};
