import React from 'react';
import { PageHeader } from '../../../../components/_common/PageHeader/PageHeader';
import { ROUTES } from '../../../../configs/routes';
import { HostForm } from '../../../../components/hosts/HostForm/HostForm';
import { Host } from '../../../../types/hosts';
import { useHostsRequest } from '../../../../hooks/hosts-hooks';
import { hostDefaults } from '../../../../data/defaults';

export const HostCreatePage: React.FC = () => {
  const {createHost, isLoading} = useHostsRequest();

  const pageSections = [
    {
      route: ROUTES.HOSTS,
      name: 'View Active Hosts',
    },
    {
      route: ROUTES.HOST_CREATE,
      name: 'Add a New Host',
    },
  ];

  const handleHostCreate = async (data: Host) => {
    await createHost(data);
  };

  return (
    <div className="host-create">
      <PageHeader title="Add a New Host" sections={pageSections} />
      <div className="page-content">
        <HostForm data={hostDefaults} isLoading={isLoading} onSubmit={handleHostCreate} />
      </div>
    </div>
  );
};
