import React from 'react';

import { Button } from '../../../components/_common/_controls/Button/Button';
import './UserInfoItem.scss';

interface Props {
  title: string;
  value: string;
  onPress?: () => void;
}

export const UserInfoItem: React.FC<Props> = (props) => {
  const { title, value, onPress } = props;

  return (
    <div className="user-info-item">
      <div className="user-info-item__title">{title}:</div>
      {onPress ? (
        <Button title={value} type="empty" onClick={onPress} />
      ) : (
        <div className="user-info-item__value">{value}</div>
      )}
    </div>
  );
};
