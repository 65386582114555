import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { TESTING_ACTION_TYPES } from '../actions-types';
import {
  AdministeredVaccine,
  RegisteredVaccine,
  VaccinationCenter,
} from '../../types/vaccination';
import { VaccinationUser } from '../../types/users';

export const actionSetTestingCenters = (data: CommonEntityRes<VaccinationCenter>): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_CENTERS,
    data,
  };
};

export const actionSetTestingCenter = (data: VaccinationCenter): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_CENTER,
    data,
  };
};

export const actionSetTestingCenterRegistered = (data: VaccinationCenter): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_CENTER_REGISTERED,
    data,
  };
};

export const actionSetTestingCenterAdministered = (data: VaccinationCenter): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_CENTER_ADMINISTERED,
    data,
  };
};

export const actionSetTestingRegisteredItems = (data: CommonEntityRes<RegisteredVaccine>): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_REGISTERED_ITEMS,
    data,
  };
};

export const actionSetTestingAdministeredItems = (data: CommonEntityRes<AdministeredVaccine>): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_ADMINISTERED_ITEMS,
    data,
  };
};

export const actionSetTestingUsers = (data: VaccinationUser[]): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_USERS,
    data,
  };
};
