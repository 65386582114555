import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer, { UserState } from './reducers/user-reducer';
import commonReducer, { CommonState } from './reducers/common-reducer';
import usersReducer, { UsersState } from './reducers/users-reducer';
import hostsReducer, { HostsState } from './reducers/hosts-reducer';
import invoicesReducer, { InvoicesState } from './reducers/invoices-reducer';
import codesReducer, { CodesState } from './reducers/codes-reducer';
import broadcastReducer, { BroadcastState } from './reducers/broadcast-reducer';
import auditReducer, { AuditState } from './reducers/audit-reducer';
import vaccinationReducer, {
  VaccinationState,
} from './reducers/vaccination-reducer';
import testingReducer, { TestingState } from './reducers/testing-reducer';
import loginReducer, { LoginState } from './reducers/login-reducer';

export interface CustomAction {
  type: string;
  data?: any;
}

export interface ApplicationState {
  common: CommonState;
  user: UserState;
  users: UsersState;
  login: LoginState;
  hosts: HostsState;
  invoices: InvoicesState;
  codes: CodesState;
  broadcast: BroadcastState;
  audit: AuditState;
  vaccination: VaccinationState;
  testing: TestingState;
}

const reducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  login: loginReducer,
  hosts: hostsReducer,
  invoices: invoicesReducer,
  codes: codesReducer,
  broadcast: broadcastReducer,
  audit: auditReducer,
  vaccination: vaccinationReducer,
  testing: testingReducer,
  common: commonReducer,
});

export const store = createStore(reducer, composeWithDevTools());
