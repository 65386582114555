import { CustomAction } from '../index';
import { BROADCAST_ACTION_TYPES } from '../actions-types';
import { CommonEvent } from '../../types/broadcast';
import { AdministeredDate, Batch, IdentityCode } from '../../types/vaccination';
import { TestingCenter, TestingDate, TestingIdentityCode, TestingLotNo, TestingType } from '../../types/testing';

export interface BroadcastState {
  events: CommonEvent[];
  batches: Batch[];
  codes: IdentityCode[];
  vaccinesDates: AdministeredDate[];
  testing: {
    centres: TestingCenter[];
    types: TestingType[];
    lots: TestingLotNo[];
    codes: TestingIdentityCode[];
    dates: TestingDate[];
  },
}

const defaultState: BroadcastState = {
  events: [],
  batches: [],
  codes: [],
  vaccinesDates: [],
  testing: {
    centres: [],
    types: [],
    lots: [],
    codes: [],
    dates: [],
  },
};

export default function broadcastReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case BROADCAST_ACTION_TYPES.SET_EVENTS: {
      return {
        ...state,
        events: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_VACCINES_BATCHES: {
      return {
        ...state,
        batches: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_VACCINES_CODES: {
      return {
        ...state,
        codes: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_VACCINES_DATES: {
      return {
        ...state,
        vaccinesDates: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CENTRES: {
      return {
        ...state,
        testing: {
          ...state.testing,
          centres: action.data,
        },
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_TYPES: {
      return {
        ...state,
        testing: {
          ...state.testing,
          types: action.data,
        },
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_LOTS: {
      return {
        ...state,
        testing: {
          ...state.testing,
          lots: action.data,
        },
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CODES: {
      return {
        ...state,
        testing: {
          ...state.testing,
          codes: action.data,
        },
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_DATES: {
      return {
        ...state,
        testing: {
          ...state.testing,
          dates: action.data,
        },
      };
    }
    default:
      return state;
  }
}
