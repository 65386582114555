import { useInfiniteQuery, useQuery } from 'react-query';
import { API_ENDPOINTS } from '../constants/api';
import { API } from '../services/axios';

const pageLimit = 10;

// Direct Hooks
export const viewNotification = async (id: string) => {
  try {
    const { data } = await API.patch(API_ENDPOINTS.NOTIFICATION_VIEW.replace('{id}', id));
    // const { data } = await Http.get(`${API_BASE_URL}/${API_ENDPOINTS.NOTIFICATIONS_LIST}?page=1&limit=5`);
    return data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const setNotificationReceiver = async (clientId: string) => {
  const userAgent = navigator.userAgent;
  try {
    const body = { reciever_id: clientId, client: userAgent };
    const { data } = await API.post(API_ENDPOINTS.NOTIFICATION_RECEIVER, body);
    return data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

//  React Query
const fetchNotificationsList = async (pageParam = 1) => {
  const { data } = await API.get(`${API_ENDPOINTS.NOTIFICATIONS_LIST}?page=${pageParam}&limit=${pageLimit}`);
  return data;
};

export const useNotificationsList = () => {
  return useInfiniteQuery('notifications', async ({ pageParam = 1 }) => fetchNotificationsList(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.total / pageLimit > allPages.length) {
        return allPages.length + 1;
      } else return undefined;
    },
  });
};

export const useNotificationsCount = () => {
  return useQuery('notifications-count', async () => fetchNotificationsList(1));
};
