import React from 'react';
import classNames from 'classnames';

import './FormGroup.scss';

interface Props {
  title?: string;
  fullWidth?: boolean;
}

export const FormGroup: React.FC<Props> = (props) => {
  const {title, children, fullWidth} = props;
  const wrapClass = classNames('form-group', {
    'form-group--full': fullWidth,
  });

  return (
    <div className={wrapClass}>
      {title && <div className="form-group__title">{title}</div>}
      <div className="form-group__fields">
        {children}
      </div>
    </div>
  );
};
