import React, { useMemo } from 'react';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { ROUTES } from '../../../configs/routes';
import { useUserPermissions } from '../../../hooks/user-hooks';
import { AdminPermissionsNames } from '../../../constants/users';

interface Props {
  title: string;
  content: React.ReactNode;
}

export const UsersHeader: React.FC<Props> = (props) => {
  const { hasPermissions, isAdmin } = useUserPermissions();
  const { title, content } = props;
  const hasUsersPermissions = hasPermissions(AdminPermissionsNames.Users);
  const hasVaccinationPermissions = hasPermissions(
    AdminPermissionsNames.Vaccination
  );
  const hasTestingPermissions = hasPermissions(
    AdminPermissionsNames.CovidTesting
  );

  const pageSections = useMemo(() => {
    const sections = [
      {
        route: ROUTES.USERS,
        name: 'View Current App Users',
      },
    ];

    if (isAdmin) {
      sections.push({
        route: ROUTES.FOR_VERIFICATION,
        name: 'For Admin Verification',
      });
    }

    if (isAdmin) {
      sections.push({
        route: ROUTES.MFP_USERS,
        name: 'View MFP Users',
      });
    }

    if (hasVaccinationPermissions) {
      sections.push({
        route: ROUTES.VACCINATION_USERS,
        name: 'View Vaccination Centre Users',
      });
    }

    if (hasTestingPermissions) {
      sections.push({
        route: ROUTES.TESTING_USERS,
        name: 'View Test Centre Users',
      });
    }

    return sections;
  }, [hasUsersPermissions, hasVaccinationPermissions, isAdmin]);

  return <PageHeader title={title} content={content} sections={pageSections} />;
};
