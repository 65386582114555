import { CustomAction } from '../index';
import { USERS_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import {
  AdminUser,
  AppUser,
  AppUserGuardian,
  AppUserTicket,
  VaccinationUser,
  VaccinationUserFull,
} from '../../types/users';
import {
  appUserDefaults,
  adminUserDefaults,
  vaccinationUserFullDefault,
  testingUserFullDefault,
  appUserGuardian,
} from '../../data/defaults';
import { TestingUser, TestingUserFull } from '../../types/testing';

export interface UsersState {
  adminUsers: CommonEntityRes<AdminUser>;
  adminUser: AdminUser;
  appUsers: CommonEntityRes<AppUser>;
  vaccinationUsers: CommonEntityRes<VaccinationUser>;
  verificationUsers: CommonEntityRes<AppUser>;
  testingUsers: CommonEntityRes<TestingUser>;
  appUserCounter: number;
  appUser: {
    data: AppUser;
    guardian: AppUserGuardian; 
    tickets: CommonEntityRes<AppUserTicket>;
    audits: CommonEntityRes<AppUser>;
    testing: CommonEntityRes<any>;
  };
  vaccinationUser: {
    data: VaccinationUserFull;
    batches: any[];
    inoculated: any[];
  };
  testingUser: {
    data: TestingUserFull;
    registered: any[];
    administered: any[];
  };
}

const defaultState: UsersState = {
  adminUsers: { data: [] },
  adminUser: adminUserDefaults,
  appUsers: { data: [], page: 1 },
  vaccinationUsers: { data: [], page: 1 },
  verificationUsers: { data: [], page: 1 },
  testingUsers: { data: [], page: 1 },
  appUserCounter: 0,
  appUser: {
    data: appUserDefaults,
    guardian: appUserGuardian,
    tickets: { data: [] },
    audits: { data: [] },
    testing: { data: [] },
  },
  vaccinationUser: {
    data: vaccinationUserFullDefault,
    batches: [],
    inoculated: [],
  },
  testingUser: {
    data: testingUserFullDefault,
    registered: [],
    administered: [],
  },
};

export default function usersReducer(
  state = defaultState,
  action: CustomAction
) {
  switch (action.type) {
    case USERS_ACTION_TYPES.SET_ADMIN_USERS: {
      return {
        ...state,
        adminUsers: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_ADMIN_USER: {
      return {
        ...state,
        adminUser: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USERS: {
      return {
        ...state,
        appUsers: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_TOTAL_APP_USERS: {
      return {
        ...state,
        appUserCounter: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USER_AUDIT: {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          audits: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USER_TICKETS: {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          tickets: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USER_TESTING: {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          testing: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.RESET_APP_USER: {
      return {
        ...state,
        appUser: {
          data: appUserDefaults,
          tickets: [],
          audits: [],
        },
      };
    }
    case USERS_ACTION_TYPES.SET_VERIFICATION_USERS: {
      return {
        ...state,
        verificationUsers: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_VACCINATION_USERS: {
      return {
        ...state,
        verificationUsers: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_TESTING_USERS: {
      return {
        ...state,
        testingUsers: action.data,
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USER: {
      const { userData } = action.data;

      return {
        ...state,
        appUser: {
          ...state.appUser,
          data: userData,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_APP_USER_GUARDIAN: {
      const { guardianData } = action.data;
      const { name } = guardianData;

      return {
        ...state,
        appUser: {
          ...state.appUser,
          guardian: {
            ...guardianData,
            // populates the first_name and last_name fields since guardian info only has name field
            first_name: name ? name.substr(0, name.lastIndexOf(' ')) : '',
            last_name: name ? name.split(' ').pop() : '',
          },
        },
      };
    }
    case USERS_ACTION_TYPES.SET_VACCINATION_USER: {
      return {
        ...state,
        vaccinationUser: {
          ...state.vaccinationUser,
          data: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_VACCINATION_BATCHES: {
      return {
        ...state,
        vaccinationUser: {
          ...state.vaccinationUser,
          batches: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_VACCINATION_INOCULATED: {
      return {
        ...state,
        vaccinationUser: {
          ...state.vaccinationUser,
          inoculated: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_TESTING_USER: {
      return {
        ...state,
        testingUser: {
          ...state.testingUser,
          data: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_TESTING_USER_REGISTERED: {
      return {
        ...state,
        testingUser: {
          ...state.testingUser,
          registered: action.data,
        },
      };
    }
    case USERS_ACTION_TYPES.SET_TESTING_USER_ADMINISTERED: {
      return {
        ...state,
        testingUser: {
          ...state.testingUser,
          administered: action.data,
        },
      };
    }
    default:
      return state;
  }
}
