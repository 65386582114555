import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Svg } from '../Svg/Svg';

import './NavMenuItem.scss';

interface Props {
  route?: string;
  icon: string;
  title: string;
  isActive: boolean;
  displayed?: boolean;
  onClick?: () => void;
}

export const NavMenuItem: React.FC<Props> = (props) => {
  const {route, icon, title, isActive, displayed = true, onClick} = props;
  const wrapClass = classNames('nav-menu-item', {'nav-menu-item--active': isActive});
  const content = (
    <>
      <Svg icon={icon} className="nav-menu-item__icon" />
      <div className="nav-menu-item__text">{title}</div>
    </>
  );

  const handleClick = () => {
    onClick && onClick();
  };

  if (!displayed) return null;

  return (
    <>
      {route
        ? (
          <Link to={route} className={wrapClass}>
            {content}
          </Link>
        )
        : (
          <button type="button" className={wrapClass} onClick={handleClick}>
            {content}
          </button>
        )}
    </>
  );
};
