import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ResetPasswordEntity, SignInEntity } from '../types/authorization';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { USER_TOKEN_NAMESPACE, USER_2FA_TOKEN_NAMESPACE } from '../constants/namespaces';
import { Http } from '../services/http';
import { LoginResponse } from '../types/user';
import { LocalStorage } from '../services/storage';
import { actionSetUser } from '../store/actions/user-actions';
import { ROUTES } from '../configs/routes';
import { userDefaults } from '../data/defaults';
import { ApplicationState } from '../store';
import { UserState } from '../store/reducers/user-reducer';
import { ADMIN_PERMISSIONS_ROUTES, AdminPermissionsNames, UserPermissionsLevels } from '../constants/users';
import { URLS } from '../constants/urls';
import { SOCKETS_API_PATH, SOCKETS_SERVICE_URL } from '../constants/sockets';
import { actionSetLogin } from '../store/actions/login-actions';
import { useInitializeFirebase } from './firebase-hooks';
import { actionSetFCMInstance } from '../store/actions/common-actions';

export function useUserLogin() {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const login = async (data: SignInEntity) => {
    try {
      const token = LocalStorage.get(USER_2FA_TOKEN_NAMESPACE);
      if (token) data.token = token;
    } catch (error) {
      console.log(error);
      alert(error.message);
    }

    data.client = navigator.userAgent;
    try {
      const url = `${SOCKETS_SERVICE_URL}/${SOCKETS_API_PATH.SIGN_IN}`;
      const res: LoginResponse = await Http.post(url, data);

      if (res && res.isAuthenticated) {
        const { accessToken, user } = res;
        authenticate(accessToken, user);
      } else if (res.isAuthenticated === false) {
        const { twoFactor, qrCode, expiration } = res;
        dispatch(
          actionSetLogin({
            email: data.login,
            qrCode,
            token: twoFactor,
            expiration,
          })
        );
        push(ROUTES.SIGN_IN_2FA);
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };

  const authenticate = async (accessToken, user) => {
    LocalStorage.set(USER_TOKEN_NAMESPACE, accessToken);
    dispatch(actionSetUser(user));
    useInitializeFirebase();
  };

  const logout = async () => {
    try {
      await LocalStorage.set(USER_TOKEN_NAMESPACE, '');
      dispatch(actionSetUser(userDefaults));
      dispatch(actionSetFCMInstance(null));
      push(ROUTES.SIGN_IN);
    } catch (e) {
      console.log(e);
    }
  };

  return { login, authenticate, logout };
}

export function useForgotPassword() {
  const { push } = useHistory();

  const sendEmail = async (email: string) => {
    try {
      const requestData = { email };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.FORGOT_PASSWORD}`;

      await Http.post(url, requestData);

      alert('Check your email');
    } catch (e) {
      alert(e.message);
    }
  };

  const resetPassword = async (data: ResetPasswordEntity) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.RESET_PASSWORD}`;

      await Http.post(url, data);

      push(ROUTES.SIGN_IN);
    } catch (e) {
      alert(e.message);
    }
  };

  return { sendEmail, resetPassword };
}

export function useUserDataRequest() {
  const dispatch = useDispatch();

  const loadUser = async () => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.ME}`;
      const res = await Http.get(url);

      dispatch(actionSetUser(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadUser };
}

export function useUserPermissions() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { userData } = userState;
  const { id: userId, role, permissions } = userData || {};
  const [isAdmin, setIsAdmin] = useState(undefined);

  useEffect(() => {
    if (userId && role.level === UserPermissionsLevels.Admin) {
      setIsAdmin(role.level === UserPermissionsLevels.Admin);
    }
  }, [userId]);

  const hasPermissions = (permissionId: AdminPermissionsNames) => {
    if (permissions && permissions.length) {
      const permission = permissions.find((item) => item.name === permissionId);

      return permission ? permission.value : false;
    }

    return true;
  };

  return { isAdmin, hasPermissions };
}

export function usePermissionsRedirect() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { userData } = userState;
  const { hasPermissions, isAdmin } = useUserPermissions();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const getAvailableRoute = () => {
    const firstAvailablePermission = userData.permissions.find((item) => item.value);

    if (firstAvailablePermission) {
      return ADMIN_PERMISSIONS_ROUTES[firstAvailablePermission.name];
    }

    return ROUTES.PLACEHOLDER;
  };

  const checkUserAccess = () => {
    const isUserCanUseUsers = hasPermissions(AdminPermissionsNames.Users);
    const isUserCanUseHosts = hasPermissions(AdminPermissionsNames.Hosts);
    const isUserCanUseAudit = hasPermissions(AdminPermissionsNames.Audit);
    const isUserCanUseInvoice = hasPermissions(AdminPermissionsNames.Invoice);
    const isUserCanUseBroadcast = hasPermissions(AdminPermissionsNames.BroadcastMessage);
    const isUserCanUseVaccination = hasPermissions(AdminPermissionsNames.Vaccination);
    const isUserCanUseTesting = hasPermissions(AdminPermissionsNames.CovidTesting);
    const isUserCanUseCodes = hasPermissions(AdminPermissionsNames.Codes);

    if (~pathname.indexOf(URLS.MFP_USER) && isAdmin !== undefined && !isAdmin) return false;
    if (~pathname.indexOf(URLS.USERS) && !isUserCanUseUsers) return false;
    if (~pathname.indexOf(URLS.AUDIT) && !isUserCanUseAudit) return false;
    if (~pathname.indexOf(URLS.VACCINATION) && !isUserCanUseVaccination) return false;
    if (~pathname.indexOf(URLS.INVOICE) && !isUserCanUseInvoice) return false;
    if (~pathname.indexOf(URLS.HOST) && !isUserCanUseHosts) return false;
    if (~pathname.indexOf(URLS.BROADCAST_MESSAGE) && !isUserCanUseBroadcast) return false;
    if (~pathname.indexOf(URLS.TESTING) && !isUserCanUseTesting) return false;
    if (~pathname.indexOf(URLS.CODES_CREATE) && !isUserCanUseCodes) return false;
    if (~pathname.indexOf(URLS.CODES) && !isUserCanUseCodes) return false;

    return true;
  };

  const userHasAccess = checkUserAccess();

  useEffect(() => {
    if (!userHasAccess) {
      push(getAvailableRoute());
    }
  }, [userHasAccess, userData?.id]);
}
