import { CustomAction } from '../index';
import { USERS_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import {
  AdminUser,
  AppUser,
  AppUserAuditEntity,
  AppUserGuardian,
  AppUserTicket,
  VaccinationUser,
  VaccinationUserFull,
} from '../../types/users';
import { Audit } from '../../types/audit';

export const actionSetAdminUsers = (
  data: CommonEntityRes<AdminUser>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_ADMIN_USERS,
    data,
  };
};

export const actionSetAdminUser = (data: AdminUser): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_ADMIN_USER,
    data,
  };
};

export const actionSetAppUsers = (
  data: CommonEntityRes<AppUser>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USERS,
    data,
  };
};

export const actionSetTotalAppUsers = (data: number): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_TOTAL_APP_USERS,
    data,
  };
};

export const actionSetAppUser = (userData: AppUser): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USER,
    data: {
      userData,
    },
  };
};

export const actionSetAppUserGuardian = (guardianData: AppUserGuardian): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USER_GUARDIAN,
    data: {
      guardianData,
    },
  };
};

export const actionSetAppUserAudit = (
  data: CommonEntityRes<Audit>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USER_AUDIT,
    data,
  };
};

export const actionSetAppUserTickets = (
  data: CommonEntityRes<AppUserTicket>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USER_TICKETS,
    data,
  };
};

export const actionSetAppUserTesting = (
  data: CommonEntityRes<any>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_APP_USER_TESTING,
    data,
  };
};

export const actionResetAppUser = (): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.RESET_APP_USER,
  };
};

export const actionSetVerificationUsers = (
  data: CommonEntityRes<AppUser>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_VERIFICATION_USERS,
    data,
  };
};

export const actionSetVaccinationUsers = (
  data: CommonEntityRes<VaccinationUser>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_VACCINATION_USERS,
    data,
  };
};

export const actionSetVaccinationUser = (
  data: VaccinationUserFull
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_VACCINATION_USER,
    data,
  };
};

export const actionSetVaccinationUserBatches = (data: any[]): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_VACCINATION_BATCHES,
    data,
  };
};

export const actionSetVaccinationUserInoculated = (
  data: any[]
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_VACCINATION_INOCULATED,
    data,
  };
};

export const actionSetTestingUsers = (
  data: CommonEntityRes<VaccinationUser>
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_TESTING_USERS,
    data,
  };
};

export const actionSetTestingUser = (
  data: VaccinationUserFull
): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_TESTING_USER,
    data,
  };
};

export const actionSetTestingUserRegistered = (data: any[]): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_TESTING_USER_REGISTERED,
    data,
  };
};

export const actionSetTestingUserAdministered = (data: any[]): CustomAction => {
  return {
    type: USERS_ACTION_TYPES.SET_TESTING_USER_ADMINISTERED,
    data,
  };
};
