import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { CommonEntityRes } from '../types/common';
import { Http } from '../services/http';
import { actionSetBand, actionSetBands, actionSetInvoices } from '../store/actions/invoices-actions';
import { Band, CreateBandData, CreateInvoiceData, Invoice, InvoiceSearchParams } from '../types/invoices';
import { CommonUtils } from '../utils/CommonUtils';
import { ApplicationState } from '../store';
import { InvoicesState } from '../store/reducers/invoices-reducer';
import { bandDefaults } from '../data/defaults';
import { notification } from '../services/notifications';

export function useInvoicesRequest() {
  const dispatch = useDispatch();

  const loadInvoices = async (page: number, params: InvoiceSearchParams) => {
    try {
      const { from, to, search = '' } = params;
      const convertedFrom = from ? moment(from).toISOString() : '';
      const convertedTo = to ? moment(to).toISOString() : '';
      const url = `${API_BASE_URL}/${API_ENDPOINTS.INVOICES}?page=${page}&limit=10&from=${convertedFrom}&to=${convertedTo}&search=${search}`;
      const res: CommonEntityRes<Invoice> = await Http.get(url);

      dispatch(actionSetInvoices(res));
    } catch (e) {
      console.log(e);
    }
  };

  const createInvoice = async (data: CreateInvoiceData) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.INVOICE_CREATE}`;
      await Http.post(url, data);

      alert('Invoice is created');
      return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  const loadBands = async () => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.BANDS}`;
      const res: Band[] = await Http.get(url);

      dispatch(actionSetBands(res));
    } catch (e) {
      console.log(e);
    }
  };

  const createBand = async (data: CreateBandData) => {
    try {
      const { name, venue_size, annual_charge, price_per_ticket } = data;
      const requestData = {
        name,
        venue_size: parseInt(venue_size),
        annual_charge: parseFloat(annual_charge),
        price_per_ticket: parseFloat(price_per_ticket),
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.BANDS}`;
      await Http.post(url, requestData);

      alert('Band is created');
      return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  const updateBand = async (data: CreateBandData, id: string) => {
    try {
      const { name, venue_size, annual_charge, price_per_ticket } = data;
      const requestData = {
        name,
        venue_size: parseInt(venue_size),
        annual_charge: parseFloat(annual_charge),
        price_per_ticket: parseFloat(price_per_ticket),
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.BANDS}/${id}`;
      await Http.put(url, requestData);

      dispatch(actionSetBand(bandDefaults));
      alert('Band is updated');
      return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  return { loadInvoices, createInvoice, loadBands, createBand, updateBand };
}

export function useInvoicesDownload() {
  const downloadInvoice = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.INVOICES}/${id}`;
      const res = await Http.get(url, { 'Content-Type': 'application/pdf' });
      const resBlob = await res.blob();
      const newBlob = new Blob([resBlob], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(newBlob);

      window.open(fileUrl, '_blank');
    } catch (e) {

    }
  };

  return { downloadInvoice };
}

export function useInvoicesExport() {
  const invoicesState = useSelector<ApplicationState, InvoicesState>(state => state.invoices);
  const { invoicesItems } = invoicesState;
  const { total } = invoicesItems;

  const exportInvoicesToCsv = async (searchParams: InvoiceSearchParams) => {
    const { from, to, search = '' } = searchParams;
    const convertedFrom = from ? moment(from).toISOString() : '';
    const convertedTo = to ? moment(to).toISOString() : '';
    const url = `${API_BASE_URL}/${API_ENDPOINTS.INVOICES}?page=${1}&limit=${total}&from=${convertedFrom}&to=${convertedTo}&search=${search}`;
    const foundItems: CommonEntityRes<Invoice> = await Http.get(url);
    const convertedItems = foundItems.data.map(item => {
      const { host, band, schedule, download_link, ...data } = item;

      return {
        ...data,
        host: host.name,
        band: band.name,
      };
    });

    CommonUtils.exportItemsToCSV(convertedItems, 'Invoices');
  };

  return { exportInvoicesToCsv };
}

export function useInvoicesTicketsExport() {
  const exportInvoicesTicketsToCsv = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.INVOICES}/${id}/${API_ENDPOINTS.TICKETS}`;
      const foundItems: any = await Http.get(url);

      if (foundItems && foundItems.length) {
        CommonUtils.exportItemsToCSV(foundItems, 'Schedule');
      } else {
        notification.showError('Schedule is empty');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { exportInvoicesTicketsToCsv };
}
