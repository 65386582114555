import React, { useState } from 'react';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { ROUTES } from '../../../configs/routes';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { InvoiceSearchParams } from '../../../types/invoices';

import './AuditHeader.scss';

interface Props {
  title: string;
  filterData?: InvoiceSearchParams;
  onExport?: () => void;
  onSearch?: (value: string) => void;
  onFilterChange?: (value: Date, name: string) => void;
}

export const AuditHeader: React.FC<Props> = (props) => {
  const {title, onExport, filterData, onSearch, onFilterChange} = props;
  const [searchStr, setSearchStr] = useState('');
  const { from, to } = filterData || {};

  const handleSearch = (searchValue: string) => {
    setSearchStr(searchValue);
    onSearch && onSearch(searchValue);
  };

  const pageSections = [
    {
      route: ROUTES.AUDIT_TRAIL,
      name: 'View Ticket Trail',
    },
    {
      route: ROUTES.AUDIT_TRACK,
      name: 'View Track and Trace',
    },
    {
      route: ROUTES.AUDIT_VACCINATION,
      name: 'View Vaccine Audit Trail',
    },
    {
      route: ROUTES.AUDIT_TESTING,
      name: 'View Testing Audit Trail',
    },
  ];

  const handleExport = () => {
    onExport && onExport();
  };

  return (
    <PageHeader
      title={title}
      content={(
        <div className="audit-header__actions">
          <div className="audit-header__filter">
            <Input placeholder="Search audit" value={searchStr} search onChange={handleSearch} />
            <Datepicker title="From" value={from} name="from" placeholder="From" onChange={onFilterChange} />
            <Datepicker title="To" value={to} name="to" placeholder="To" onChange={onFilterChange} />
          </div>
          <div className="audit-header__export">
            <div className="audit-header__export-title">Export Search to Report</div>
            <Button title="Export" onClick={handleExport} />
          </div>
        </div>
      )}
      sections={pageSections}
    />
  );
};
