import React from 'react';
import { ROUTES } from '../../../configs/routes';
import { PageHeader } from '../../_common/PageHeader/PageHeader';

interface Props {
  title: string;
  content?: React.ReactNode;
}

export const TestingHeader: React.FC<Props> = (props) => {
  const {title, content} = props;

  const pageSections = [
    {
      route: ROUTES.TESTING_CENTERS,
      name: 'View Current Test Centres',
    },
    {
      route: ROUTES.TESTING_CENTER_CREATE,
      name: 'Add a Test Centre',
    },
    {
      route: ROUTES.TESTING_REGISTERED,
      name: 'View Registered Tests Types',
    },
    {
      route: ROUTES.TESTING_ADMINISTERED,
      name: 'View Administered Tests Types',
    },
  ];

  return (
    <PageHeader
      title={title}
      content={content}
      sections={pageSections}
    />
  );
};
