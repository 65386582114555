import { CustomAction } from '../index';
import { VACCINATION_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import {
  AdministeredVaccine,
  RegisteredVaccine,
  VaccinationCenter,
} from '../../types/vaccination';
import { VaccinationUser } from '../../types/users';
import { vaccinationCenterDefault } from '../../data/defaults';

export interface VaccinationState {
  centers: CommonEntityRes<VaccinationCenter>;
  center: { data: VaccinationCenter; batches: any[]; inoculated: any[]; };
  registeredVaccines: CommonEntityRes<RegisteredVaccine>;
  administeredVaccines: CommonEntityRes<AdministeredVaccine>;
  users: VaccinationUser[];
}

const defaultState: VaccinationState = {
  centers: {data: []},
  center: {
    data: vaccinationCenterDefault,
    batches: [],
    inoculated: [],
  },
  registeredVaccines: { data: [] },
  administeredVaccines: { data: [] },
  users: [],
};

export default function vaccinationReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case VACCINATION_ACTION_TYPES.SET_VAC_CENTERS: {
      return {
        ...state,
        centers: action.data,
      };
    }
    case VACCINATION_ACTION_TYPES.SET_VAC_CENTER: {
      return {
        ...state,
        center: {
          ...state.center,
          data: action.data,
        },
      };
    }
    case VACCINATION_ACTION_TYPES.SET_VAC_CENTER_BATCHES: {
      return {
        ...state,
        center: {
          ...state.center,
          batches: action.data,
        },
      };
    }
    case VACCINATION_ACTION_TYPES.SET_VAC_CENTER_INOCULATED: {
      return {
        ...state,
        center: {
          ...state.center,
          inoculated: action.data,
        },
      };
    }
    case VACCINATION_ACTION_TYPES.SET_REGISTERED_ITEMS: {
      return {
        ...state,
        registeredVaccines: action.data,
      };
    }
    case VACCINATION_ACTION_TYPES.SET_ADMINISTERED_ITEMS: {
      return {
        ...state,
        administeredVaccines: action.data,
      };
    }
    case VACCINATION_ACTION_TYPES.SET_VACCINES_USERS: {
      return {
        ...state,
        users: action.data,
      };
    }
    default:
      return state;
  }
}