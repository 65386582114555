import { CustomAction } from '../index';
import { BROADCAST_ACTION_TYPES } from '../actions-types';
import { CommonEvent } from '../../types/broadcast';
import { AdministeredDate, Batch, IdentityCode } from '../../types/vaccination';
import { TestingCenter, TestingDate, TestingType } from '../../types/testing';

export const actionSetEvents = (data: CommonEvent[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_EVENTS,
    data,
  };
};

export const actionSetBatches = (data: Batch[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_VACCINES_BATCHES,
    data,
  };
};

export const actionSetVaccinationCodes = (data: IdentityCode[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_VACCINES_CODES,
    data,
  };
};

export const actionSetVaccinationDates = (data: AdministeredDate[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_VACCINES_DATES,
    data,
  };
};

export const actionSetBroadcastTestingCentres = (data: TestingCenter[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CENTRES,
    data,
  };
};

export const actionSetBroadcastTestingTypes = (data: TestingType[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_TYPES,
    data,
  };
};

export const actionSetBroadcastTestingLots = (data: TestingType[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_LOTS,
    data,
  };
};

export const actionSetBroadcastTestingCodes = (data: TestingType[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CODES,
    data,
  };
};

export const actionSetBroadcastTestingDates = (data: TestingDate[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_DATES,
    data,
  };
};
