import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { testingFormEntityDefaults } from '../../../data/defaults';
import { TestingUserFormEntity } from '../../../types/testing';

interface Props {
  isSaved?: boolean;
  onSubmit(data: TestingUserFormEntity): void;
}

export const TestingUserCreateForm: React.FC<Props> = (props) => {
  const {isSaved, onSubmit} = props;

  const [formData, setFormData] = useState<TestingUserFormEntity>(testingFormEntityDefaults);
  const {
    mfp_id,
    SubUserManagement,
    BroadcastMessage,
    RegisteredTestTypes,
    AdministeredTestTypes,
    CentreDetails
  } = formData;

  useEffect(() => {
    if (isSaved) {
      setFormData(testingFormEntityDefaults);
    }
  }, [isSaved]);

  const handleFieldChange = (value: string | boolean, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
            <Input title="MFP ID" value={mfp_id} name="mfp_id" placeholder="MFP ID" onChange={handleFieldChange} />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={RegisteredTestTypes}
              name="RegisteredTestTypes"
              text="Registered Test Types"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AdministeredTestTypes}
              name="AdministeredTestTypes"
              text="Administered Test Types"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={CentreDetails}
              name="CentreDetails"
              text="Centre Details"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Create" htmlType="submit" />
        )}
      />
    </Form>
  );
};
