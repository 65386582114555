import React, { useState } from 'react';
import { TestingHeader } from '../../../../components/testing/TestingHeader/TestingHeader';
import { TestingCenterForm } from '../../../../components/testing/TesingCenterForm/TestingCenterForm';
import { TestingDoctorForm } from '../../../../components/testing/TestingDoctorForm/TestingDoctorForm';
import { TestingCenterCreateFormEntity, TestingDoctorCreateFormEntity } from '../../../../types/testing';
import { useTestingCenterCreate, useTestingDoctorCreate } from '../../../../hooks/testing-hooks';

import './TestingCenterCreatePage.scss';

export const TestingCenterCreatePage: React.FC = () => {
  const {createDoctor, isSaved: isDoctorSaved} = useTestingDoctorCreate();
  const [centerData, setCenterData] = useState<TestingCenterCreateFormEntity>()

  const handleCenterUpdate = (data: TestingCenterCreateFormEntity) => {
    setCenterData(data);
  };

  const handleDoctorCreate = (data: TestingDoctorCreateFormEntity) => {
    createDoctor(data, centerData);
  };

  return (
    <div className="page-content tcc-page">
      <TestingHeader title="Add a New Test Centre" />
      <TestingCenterForm title="Add a New Test Centre" isSaved={isDoctorSaved} isNew onChange={handleCenterUpdate} />
      <TestingDoctorForm title="Key Doctor Information" isSaved={isDoctorSaved} isNew onSubmit={handleDoctorCreate} />
    </div>
  );
};
