import { useDispatch } from 'react-redux';
import { CommonEntityRes, CommonObject } from '../types/common';
import { API_BASE_URL, API_ENDPOINTS, API_TESTING_BASE_URL, API_VACCINES_BASE_URL } from '../constants/api';
import { Http } from '../services/http';
import {
  TestingCenter,
  TestingCenterCreateFormEntity,
  TestingCenterUser,
  TestingCenterUserUpdate,
  TestingDoctorCreateFormEntity,
  TestingUser,
  TestingUserFormEntity,
  TestingUserFull
} from '../types/testing';
import {
  actionSetTestingCenter,
  actionSetTestingCenterRegistered,
  actionSetTestingCenterAdministered,
  actionSetTestingCenters,
  actionSetTestingUsers,
  actionSetTestingAdministeredItems,
  actionSetTestingRegisteredItems,
} from '../store/actions/testing-actions';
import { notification } from '../services/notifications';
import { UtilsUser } from '../utils/UtilsUser';
import { useState } from 'react';
import { AdministeredVaccine, RegisteredVaccine } from '../types/vaccination';
import { CommonUtils } from '../utils/CommonUtils';
import { TestsType } from '../constants/testing';
import { AdminPermissionsNames } from '../constants/users';

export function useTestingCentersLoadingRequest() {
  const dispatch = useDispatch();

  const loadCenters = async (page?: number, searchParams?: CommonObject) => {
    try {
      const { search = '' } = searchParams || {};
      const limit = page ? 10 : 300;
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}?page=${page || ''}&limit=${limit}&search=${search}`;
      const res: CommonEntityRes<TestingCenter> = await Http.get(url);

      dispatch(actionSetTestingCenters(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadCenters };
}

export function useTestingCenterLoadingRequest() {
  const dispatch = useDispatch();

  const loadCenter = async (id: string) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${id}`;
      const res: TestingCenter = await Http.get(url);
      dispatch(actionSetTestingCenter(res));

      const registeredUrl = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTER_REGISTERED.replace('{mfp_id}', id)}`;
      const batchesRes = await Http.get(registeredUrl);
      dispatch(actionSetTestingCenterRegistered(batchesRes.data));

      const administeredUrl = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTER_ADMINISTERED.replace('{mfp_id}', id)}`;
      const inoculatedRes = await Http.get(administeredUrl);
      dispatch(actionSetTestingCenterAdministered(inoculatedRes.data));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadCenter };
}

export function useTestingCenterUpdateRequest() {
  const dispatch = useDispatch();

  const updateCenter = async (id: string, data: TestingCenterCreateFormEntity) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${id}`;
      const res: TestingCenter = await Http.put(url, data);

      notification.showSuccess('Center Is Updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return { updateCenter };
}

export function useTestingCenterUsersLoadRequest() {
  const dispatch = useDispatch();

  const loadUsers = async (centerId: string) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${centerId}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<TestingUser> = await Http.get(url);

      dispatch(actionSetTestingUsers(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadUsers };
}

export function useTestingCenterUserRequest() {
  const dispatch = useDispatch();
  const { loadUsers } = useTestingCenterUsersLoadRequest();

  const createUser = async (data: TestingUserFormEntity, centerId: string) => {
    try {
      const { mfp_id, ...permissions } = data;
      const convertedPermissions = UtilsUser.convertUserPermissions(permissions);
      const filteredPermissions = convertedPermissions.filter(item => {
        return item.name !== AdminPermissionsNames.AdministeredVaccines
          && item.name !== AdminPermissionsNames.RegisteredVaccines
          && item.name !== AdminPermissionsNames.GenerateQRVaccine;
      });
      const requestData: TestingCenterUser = {
        mfp_id,
        permissions: filteredPermissions,
      };

      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${centerId}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<TestingCenter> = await Http.post(url, requestData);

      loadUsers(centerId);
      notification.showSuccess('User is created');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return { createUser };
}

export function useTestingCenterUserUpdateRequest() {
  const updateUser = async (data: TestingUserFormEntity) => {
    try {
      const { mfp_id, ...permissions } = data;
      const convertedPermissions = UtilsUser.convertUserPermissions(permissions);
      const filteredPermissions = convertedPermissions.filter(item => {
        return item.name !== AdminPermissionsNames.AdministeredVaccines
          && item.name !== AdminPermissionsNames.RegisteredVaccines
          && item.name !== AdminPermissionsNames.GenerateQRVaccine;
      });
      const requestData: TestingCenterUserUpdate = {
        permissions: filteredPermissions,
      };

      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}/${mfp_id}`;
      await Http.patch(url, requestData);

      notification.showSuccess('User is updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return { updateUser };
}

export function useTestingCenterGetUserRequest() {
  const getUser = async (id: string) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}/${id}`;
      const res: TestingUserFull = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  return { getUser };
}

export function useTestingCenterCreate() {
  const [isSaved, setIsSaved] = useState(false);
  const [centerId, setCenterId] = useState('');
  // test center id - b68d3204-a609-4070-96b0-1513bdcf2675;

  const createCenter = async (data: TestingCenterCreateFormEntity) => {
    try {
      setIsSaved(false);
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}`;
      const centerData: TestingCenter = await Http.post(url, data);

      setCenterId(centerData.id);
      setIsSaved(true);
      return centerData.id;
      // notification.showSuccess('Center Is Created');
      return true;
    } catch (e) {
      console.log(e);
      setIsSaved(false);
      notification.showError(e.message);
      return '';
    }
  };

  return { createCenter, isSaved, centerId };
}

export function useTestingDoctorCreate() {
  const { createCenter } = useTestingCenterCreate();
  const [isSaved, setIsSaved] = useState(false);

  const uploadSignature = async (signUrl: string, file: File) => {
    const url = `${API_TESTING_BASE_URL}/${signUrl}`;
    const data = new FormData();
    data.append('file', file, file.name);

    await Http.postFile(url, data);
  };

  const createDoctor = async (data: TestingDoctorCreateFormEntity, centerData: TestingCenterCreateFormEntity) => {
    try {
      setIsSaved(false);

      const centerId: any = await createCenter(centerData);

      if (!centerId) return;

      const { signature, ...doctorData } = data;
      const handledPath = API_ENDPOINTS.TESTING_DOCTOR.replace('{id}', centerId);
      const handledSignPath = API_ENDPOINTS.TESTING_DOCTOR_SIGNATURE.replace('{id}', centerId);
      const url = `${API_TESTING_BASE_URL}/${handledPath}`;
      await Http.put(url, doctorData);

      if (signature) {
        await uploadSignature(handledSignPath, signature);
      }

      setIsSaved(true);
      notification.showSuccess('Success');
      return true;
    } catch (e) {
      console.log(e);
      // setIsSaved(false);
      notification.showError(e.message);
      return false;
    }
  };

  return { createDoctor, isSaved };
}

export function useAdministeredTestingItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject) => {
    try {
      const { search } = searchParams;
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_ADMINISTERED}?page=${page}&limit=10&search=${search}`;
      const res = await Http.get(url);

      dispatch(actionSetTestingAdministeredItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadItems };
}

export function useRegisteredTestingItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject) => {
    try {
      const { search } = searchParams;
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}?page=${page}&limit=10&search=${search}`;
      const res = await Http.get(url);

      dispatch(actionSetTestingRegisteredItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadItems };
}

export function useRegisteredTestingExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<any> = await Http.get(url);
      const excludeAndParsePropertiesInReport = foundItems.data.map((data) => {
        delete data.ftr;
        delete data.ftt;
        if(data.ai_result) {
          const parseAiResult = JSON.parse(data.ai_result);
          data.ai_result = parseAiResult.status;
        }
        return data;
      });

      CommonUtils.exportItemsToCSV(excludeAndParsePropertiesInReport, 'Registered Tests Types');
    } catch (e) {
      console.log(e);
    }
  };

  return { exportItemsToCsv };
}

export function useAdministeredTestingExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_ADMINISTERED}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<AdministeredVaccine> = await Http.get(url);
      const excludeAndParsePropertiesInReport = foundItems.data.map((data) => {
        delete data.ftr;
        delete data.ftt;
        if(data.ai_result) {
          const parseAiResult = JSON.parse(data.ai_result);
          data.ai_result = parseAiResult.status;
        }
        return data;
      });
      CommonUtils.exportItemsToCSV(excludeAndParsePropertiesInReport, 'Administered Tests Types');
    } catch (e) {
      console.log(e);
    }
  };

  return { exportItemsToCsv };
}

export function useTestsDownload() {
  const downloadTest = async (id: string, type: TestsType) => {
    try {
      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_TESTS}/${id}/${type}`;
      const res = await Http.get(url, { 'Content-Type': 'application/pdf' });
      const resBlob = await res.blob();
      const newBlob = new Blob([resBlob], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(newBlob);

      window.open(fileUrl, '_blank');
    } catch (e) {

    }
  };

  return { downloadTest };
}
