import React, { useState, useEffect } from 'react';

import { Checkbox } from '../../../_common/_controls/Checkbox/Checkbox';
import { Input } from '../../../_common/_controls/Input/Input';
import { Field } from '../../../_common/_controls/Field/Field';
import '../index.scss';

interface Props {
  form: any;
}

const CriteriaAgeRestrictionForm: React.FC<Props> = ({ form }) => {
  const [isAgeRequired, setIsAgeRequired] = useState<boolean>(false);
  const { control, getValues, setValue } = form;

  useEffect(() => {
    const isRequired = getValues('age_restriction.minimum') > 0;
    setIsAgeRequired(isRequired);
  }, []);

  const onAgeRequireChange = (val) => {
    setIsAgeRequired(val);
    // set input min age requirement to 0 if false
    const ageValue = val ? '' : 0;
    setValue('age_restriction.minimum', ageValue);
  };

  return (
    <div className="entry-form-criteria">
      <Checkbox
        checked={isAgeRequired}
        text="Require minimum age for entry"
        onChange={onAgeRequireChange}
      />
      <div className="divider" />
      <div className={isAgeRequired ? '' : 'entry-form-criteria__disabled'}>
        <Field
          control={control}
          name="age_restriction.minimum"
          component={Input}
          rules={{
            required: isAgeRequired,
            min: isAgeRequired ? 1 : 0, // Do not allow 0 value
            max: 100, //adjust if needed
          }}
          title="Minimum Age Requirement"
          htmlType="number"
          disabled={!isAgeRequired}
          endAdornment={<p>years of age or older</p>}
        />
      </div>
    </div>
  );
};

export default CriteriaAgeRestrictionForm;
