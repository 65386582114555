import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../constants/urls';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { Table } from '../../../../components/_common/Table/Table';
import { SelectMenu } from '../../../../components/_common/_controls/SelectMenu/SelectMenu';
import { useTestingUsersRequest } from '../../../../hooks/users-hooks';
import { CommonObject } from '../../../../types/common';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { useTestingCentersLoadingRequest } from '../../../../hooks/testing-hooks';
import { TestingState } from '../../../../store/reducers/testing-reducer';

export const TestingUsersPage = () => {
  const {loadCenters} = useTestingCentersLoadingRequest();
  const {loadTestingUsers} = useTestingUsersRequest();
  const [searchParams, setSearchParams] = useState({
    search: '',
    country: '',
    centre: '',
  });
  const {search, country, centre} = searchParams;
  const appUsersState = useSelector<ApplicationState, UsersState>((state) => state.users);
  const testingState = useSelector<ApplicationState, TestingState>((state) => state.testing);
  const {testingUsers} = appUsersState;
  const {data, limit, total, page} = testingUsers;
  const {centers} = testingState;
  const {data: centresList} = centers;

  useEffect(() => {
    loadCenters();
    loadTestingUsers(1, searchParams);
  }, []);

  const searchForUsers = useCallback(debounce(async (params: CommonObject) => {
    loadTestingUsers(1, params);
  }, 400), []);


  const handleSearchParamsChange = (value: string, name) => {
    const newParams = {...searchParams, [name]: value};
    setSearchParams(newParams);
    searchForUsers(newParams);

    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Test Centre',
        accessor: 'centre_name',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Last Active',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {last_active} = original;

          if (!last_active) {
            return '';
          }

          return moment(last_active).format('YYYY-MM-DD');
        },
      },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.TESTING_USER}/${id}`;

          return (
            <Link to={url}>View details</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadTestingUsers(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadTestingUsers(prevPage, searchParams);
  };

  return (
    <div>
      <UsersHeader
        title="MFP Users"
        content={(
          <div className="search-bar">
            {/*<SelectMenu*/}
            {/*  placeholder="Country"*/}
            {/*  name="country"*/}
            {/*  value={country}*/}
            {/*  options={[]}*/}
            {/*  onChange={handleSearchParamsChange}*/}
            {/*/>*/}
            <SelectMenu
              placeholder="Centre"
              name="centre"
              value={centre}
              options={centresList}
              labelKey="name"
              valueKey="id"
              isClearable
              onChange={handleSearchParamsChange}
            />
            <Input placeholder="Search users" value={search} search name="search" onChange={handleSearchParamsChange} />
          </div>
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
