import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { CODES_ACTION_TYPES } from '../actions-types';
import { Code } from '../../types/codes';

export const actionSetCodes = (data: CommonEntityRes<Code>): CustomAction => {
  return {
    type: CODES_ACTION_TYPES.SET_CODES,
    data,
  };
};

export const actionSetCode = (data: Code): CustomAction => {
  return {
    type: CODES_ACTION_TYPES.SET_CODE,
    data,
  };
};

export const actionResetCode = (): CustomAction => {
  return {
    type: CODES_ACTION_TYPES.RESET_CODE,
  };
};
