import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { ROUTES } from '../configs/routes';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { SOCKETS_API_PATH, SOCKETS_ENTRY, SOCKETS_SERVICE_URL } from '../constants/sockets';
import { Http } from '../services/http';
import { LocalStorage } from '../services/storage';
import { ApplicationState } from '../store';
import { actionSetLogin } from '../store/actions/login-actions';
import { actionSetUser } from '../store/actions/user-actions';
import { LoginState } from '../store/reducers/login-reducer';
import { LoginAuthenticated, LoginUnauthenticated } from '../types/user';
import { useUserLogin } from './user-hooks';

export function useTwoFactorHooks() {
  const dispatch = useDispatch();
  const loginState = useSelector<ApplicationState, LoginState>((state) => state.login);
  const { qrCode, token, email, expiration } = loginState;
  const { push } = useHistory();
  const { authenticate } = useUserLogin();
  const userAgent = navigator.userAgent;

  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    const connection = io(`${SOCKETS_SERVICE_URL}/${SOCKETS_ENTRY.ADMIN}`, { transports: ['websocket'] });

    connection.on('exception', (e) => console.log(e));
    connection.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    connection.onAny((e) => console.log(e));

    setSocket(connection);
    return () => {
      if (socket) socket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('authenticate', (e: LoginAuthenticated) => {
        const { accessToken, user } = e;
        authenticate(accessToken, user);
      });

      socket.on('regenerateToken', (token: LoginUnauthenticated) => {
        if (token) {
          console.log('REGENERATED:', token);
          const { twoFactor, qrCode, expiration } = token;
          dispatch(actionSetLogin({ token: twoFactor, qrCode, expiration }));
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      if (email && token && qrCode) {
        socket.emit('createRoom', { id: token, client: userAgent });
      } else {
        push(ROUTES.SIGN_IN);
      }
    }
  }, [socket, email, token, qrCode]);

  const getQR = () => {
    socket.emit('regenerateToken', { email, client: userAgent });
  };

  const sendCode = async (code: number, is_saved = false) => {
    try {
      const url = `${SOCKETS_SERVICE_URL}/${SOCKETS_API_PATH.VERIFY_CODE}`;
      const res: LoginAuthenticated = await Http.post(url, {
        id: token,
        code,
        client: userAgent,
        is_saved,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  return {
    email,
    qrCode,
    expiration,
    getQR,
    sendCode,
  };
}
