import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { hostFormEntityDefaults } from '../../../data/defaults';
import { HostUserFormEntity } from '../../../types/hosts';

interface Props {
  isSaved: boolean;
  isEdit: false;
  onSubmit(data: HostUserFormEntity): void;
}

export const HostUserCreateForm: React.FC<Props> = (props) => {
  const {isEdit, isSaved, onSubmit} = props;

  const [formData, setFormData] = useState(hostFormEntityDefaults);
  const {
    mfp_id,
    Tickets,
    Audit,
    SubUserManagement,
    BroadcastMessage,
    AccountDetails,
    Invoice,
  } = formData;
  const [dataError, setDataError] = useState({
    mfp_id: 'Required Field',
    mfpDirty: false,
  })
  useEffect(() => {
    if (isSaved) {
      setFormData(hostFormEntityDefaults);
    }
  }, [isSaved]);

  const handleFieldChange = (value: string | boolean | any, name: string) => {
    setFormData({...formData, [name]: value});
  };

  const handleFormSubmit = async () => {
    if(!isEdit && !formData.mfp_id) {
      setDataError((prevState) => ({ ...prevState, mfpDirty: true }));
      return;
    }
    setDataError((prevState) => ({ ...prevState, mfpDirty: false }));
    const response = await onSubmit(formData);

    if (response) {
      // clears the form data if form is not edit form
      if (!isEdit) setFormData(hostFormEntityDefaults);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
           <Input
              error={dataError.mfpDirty}
              errorMessage={dataError.mfpDirty ? dataError.mfp_id : ''}
              title="MFP ID"
              value={mfp_id || ''}
              name="mfp_id"
              placeholder="MFP ID"
              disabled={isEdit}
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={Tickets}
              name="Tickets"
              text="View Tickets"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AccountDetails}
              name="AccountDetails"
              text="View/Edit Hosts"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Invoice}
              name="Invoice"
              text="Invoice"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Audit}
              name="Audit"
              text="Audit Trail"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Create" htmlType="submit" />
        )}
      />
    </Form>
  );
};
