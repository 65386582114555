import React, { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageTitle } from '../../../../components/_common/PageTitle/PageTitle';
import { Tabs } from '../../../../components/_common/Tabs/Tabs';
import { Table } from '../../../../components/_common/Table/Table';
import { CollapsePanel } from '../../../../components/_common/CollapsePanel/CollapsePanel';
import { TestingCenterForm } from '../../../../components/testing/TesingCenterForm/TestingCenterForm';
import { DATE_FORMAT } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../../../components/_common/Svg/Svg';
import { TestingUserCreateForm } from '../../../../components/testing/TestingUserCreateForm/TestingUserCreateForm';
import { TestingUserEditForm } from '../../../../components/testing/TestingUserEditForm/TestingUserEditForm';
import {
  useTestingCenterLoadingRequest,
  useTestingCenterUpdateRequest,
  useTestingCenterUserRequest,
  useTestingCenterUsersLoadRequest,
  useTestingCenterUserUpdateRequest, useTestsDownload,
} from '../../../../hooks/testing-hooks';
import { ApplicationState } from '../../../../store';
import { TestingState } from '../../../../store/reducers/testing-reducer';
import { TestingCenterCreateFormEntity, TestingUserFormEntity } from '../../../../types/testing';
import { TestsType } from '../../../../constants/testing';

import './TesingCenterPage.scss';

export const TestingCenterPage: React.FC = () => {
  const params: any = useParams();
  const testingState = useSelector<ApplicationState, TestingState>(state => state.testing);
  const {loadCenter} = useTestingCenterLoadingRequest();
  const {updateCenter} = useTestingCenterUpdateRequest();
  const {createUser} = useTestingCenterUserRequest();
  const {loadUsers} = useTestingCenterUsersLoadRequest();
  const {updateUser} = useTestingCenterUserUpdateRequest();
  const { downloadTest } = useTestsDownload();
  const centerId = params.id;
  const {center} = testingState;
  const {data, registered, administered} = center;
  const {name} = data;

  useEffect(() => {
    loadCenter(centerId);
    loadUsers(centerId);
  }, []);

  const registeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date_tested} = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'AI_Result',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {ai_result} = original;

          const parseAIResult = JSON.parse(ai_result);
          console.log(parseAIResult);
          return parseAIResult ? parseAIResult?.status : null;
        },
      },
      {
        Header: 'PLN',
        accessor: 'pln'
      },
      {
        Header: 'Admin Review',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {admin_review} = original;

          return admin_review ? 'TRUE' : 'FALSE';
        },
      }
    ],
    [],
  );

  const administeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date_tested} = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'FtF',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { ftf, id } = original;

          const handleDownload = () => {
            downloadTest(id, TestsType.FTF);
          };

          if (ftf) {
            return (
              <button className="empty-button" type="button" onClick={handleDownload}>
                <Svg icon={SVG_ICONS.DOCUMENT} />
              </button>
            );
          }

          return <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />;
        }
      },
      {
        Header: 'AI_Result',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {ai_result} = original;

          const parseAIResult = JSON.parse(ai_result);
          console.log(parseAIResult);
          return parseAIResult ? parseAIResult?.status : null;
        },
      },
      {
        Header: 'PLN',
        accessor: 'pln'
      },
      {
        Header: 'Admin Review',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {admin_review} = original;

          return admin_review ? 'TRUE' : 'FALSE';
        },
      }
    ],
    [],
  );

  const handleCenterUpdate = (data: TestingCenterCreateFormEntity) => {
    updateCenter(centerId, data);
  };

  const handleUserCreate = (data: TestingUserFormEntity) => {
    createUser(data, centerId);
  };

  const handleUserUpdate = (data: TestingUserFormEntity) => {
    updateUser(data);
  };

  return (
    <div className="page-content vc-page">
      <PageTitle text={`Test Centre: ${name}`} />
      <TestingCenterForm data={data} onSubmit={handleCenterUpdate} />
      <CollapsePanel title="Testing">
        <Tabs titles={['Registered Tests', 'Administered Tests']}>
          <div>
            <Table
              data={registered}
              columns={registeredColumns}
            />
          </div>
          <div>
            <Table
              data={administered}
              columns={administeredColumns}
            />
          </div>
        </Tabs>
      </CollapsePanel>
      <CollapsePanel title="User Account Management">
        <Tabs titles={['Create a New User', 'Edit an Active User']}>
          <div>
            <TestingUserCreateForm onSubmit={handleUserCreate} />
          </div>
          <div>
            <TestingUserEditForm onSubmit={handleUserUpdate} />
          </div>
        </Tabs>
      </CollapsePanel>
    </div>
  )
};
