import { CustomAction } from '../index';
import { COMMON_ACTION_TYPES } from '../actions-types';
import { ApplicationEntity } from '../../types/common';
import { FirebaseApp } from 'firebase/app';

export interface CommonState {
  applicationsList: Array<ApplicationEntity>;
  fcmInstance: any;
}

const defaultState: CommonState = {
  applicationsList: null,
  fcmInstance: null,
};

export default function userReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case COMMON_ACTION_TYPES.SET_APPLICATIONS_LIST: {
      return {
        ...state,
        applicationsList: action.data,
      };
    }
    case COMMON_ACTION_TYPES.SET_FCM_INSTANCE: {
      return {
        ...state,
        fcmInstance: action.data,
      };
    }
    default:
      return state;
  }
}
