import React, { useEffect, useState } from 'react';
import Countdown, { CountdownRendererFn, CountdownRenderProps } from 'react-countdown';

import './Timer.scss';

interface Props {
  date: Date | string;
  render?: CountdownRendererFn,
  setComplete?: (val: boolean) => void;
}

export const Timer: React.FC<Props> = ({ date, render, setComplete }) => {
  const [count, setCount] = useState(date);
  const [reset, setReset] = useState('');

  useEffect(() => {
    setCount(date);
    setReset(date.toString());
  }, [date])

  return (
    <Countdown
      date={count}
      key={reset}
      renderer={render}
      onComplete={() => {
        setComplete(true);
      }}
    />
  );
};
