import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { INVOICES_ACTION_TYPES } from '../actions-types';
import { Band, Invoice } from '../../types/invoices';

export const actionSetInvoices = (data: CommonEntityRes<Invoice>): CustomAction => {
  return {
    type: INVOICES_ACTION_TYPES.SET_INVOICES,
    data,
  };
};

export const actionSetBands = (data: Band[]): CustomAction => {
  return {
    type: INVOICES_ACTION_TYPES.SET_BANDS,
    data,
  };
};

export const actionSetBand = (data: Band): CustomAction => {
  return {
    type: INVOICES_ACTION_TYPES.SET_BAND,
    data,
  };
};