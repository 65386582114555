import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SelectMenu } from '../../../_common/_controls/SelectMenu/SelectMenu';
import { CheckboxGroup } from '../../../_common/_controls/Checkbox/Checkbox';
import { Input } from '../../../_common/_controls/Input/Input';
import { Field } from '../../../_common/_controls/Field/Field';
import {
  TESTING_STATUS,
  TestingStatus,
  TESTING_TYPES,
  TestingTypes,
} from '../../../../constants/testing';
import '../index.scss';

interface Props {
  form: any;
}

const CriteriaTestingForm: React.FC<Props> = ({ form }) => {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const { control, setValue } = form;

  const testStatus = useWatch({
    control,
    name: 'testing_result.result',
  });

  useEffect(() => {
    if (testStatus === TESTING_STATUS.NOT_REQUIRED_VACCINATED) {
      setIsRequired(false);
      setValue('testing_result.type_accepted', TESTING_TYPES.ANY);
      setValue('testing_result.max_test_age', 0);
    } else {
      setIsRequired(true);
    }
  }, [testStatus]);

  const testOptions = [
    {
      label: TestingStatus[TESTING_STATUS.ALWAYS_REQUIRED],
      value: TESTING_STATUS.ALWAYS_REQUIRED,
    },
    {
      label: TestingStatus[TESTING_STATUS.REQUIRED_VACCINE_INCOMPLETE],
      value: TESTING_STATUS.REQUIRED_VACCINE_INCOMPLETE,
    },
    {
      label: TestingStatus[TESTING_STATUS.NOT_REQUIRED_VACCINATED],
      value: TESTING_STATUS.NOT_REQUIRED_VACCINATED,
    },
  ];

  const testTypes = [
    {
      label: TestingTypes[TESTING_TYPES.ANY],
      value: TESTING_TYPES.ANY,
    },
    {
      label: TestingTypes[TESTING_TYPES.PCR],
      value: TESTING_TYPES.PCR,
    },
    {
      label: TestingTypes[TESTING_TYPES.LFD],
      value: TESTING_TYPES.LFD,
    },
  ];

  return (
    <div className="entry-form-criteria">
      <Field
        control={control}
        name="testing_result.result"
        component={SelectMenu}
        options={testOptions}
        title="Negative Test Result"
        disableSearch
      />
      <div className="divider" />
      <div className={isRequired ? '' : 'entry-form-criteria__disabled'}>
        <Field
          control={control}
          name="testing_result.type_accepted"
          component={SelectMenu}
          options={testTypes}
          title="Test Type Accepted"
          labelKey="label"
          valueKey="value"
          rules={{ required: isRequired }}
          disabled={!isRequired}
        />
        <Field
          control={control}
          name="testing_result.max_test_age"
          component={Input}
          title="Maximum Test Age"
          htmlType="number"
          rules={{
            required: isRequired,
            min: isRequired ? 1 : 0, // Fix for min validation when NOT_REQUIRED
            max: 100, //adjust if needed
          }}
          endAdornment={<p>hours</p>}
          disabled={!isRequired}
        />
      </div>
    </div>
  );
};

export default CriteriaTestingForm;
