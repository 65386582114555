import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Button } from '../../_common/_controls/Button/Button';
import { Form } from '../../_common/Form/Form';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { TextArea } from '../../_common/_controls/TextArea/TextArea';
import { BroadcastCreate, CommonEvent } from '../../../types/broadcast';
import { broadcastCreateDefaults } from '../../../data/defaults';
import { ApplicationState } from '../../../store';
import { Autocomplete } from '../../_common/_controls/Autocomplete/Autocomplete';
import { useEventsRequest } from '../../../hooks/broadcast-hooks';
import { HostsState } from '../../../store/reducers/hosts-reducer';
import { BroadcastState } from '../../../store/reducers/broadcast-reducer';
import { CommonState } from '../../../store/reducers/common-reducer';

import './BroadcastForm.scss';
import { isEmpty } from 'lodash';
import { useCommonRequest } from '../../../hooks/common-hooks';
import HostSelectMenu from '../../hosts/HostSelectMenu/HostSelectMenu';

interface Props {
  isSaved: boolean;
  onSubmit: (data: BroadcastCreate) => void;
}

export const BroadcastForm: React.FC<Props> = (props) => {
  const { isSaved, onSubmit } = props;
  const { loadEvents } = useEventsRequest();
  const { getApplicationsList } = useCommonRequest();
  const hostsState = useSelector<ApplicationState, HostsState>((state) => state.hosts);
  const broadcastState = useSelector<ApplicationState, BroadcastState>((state) => state.broadcast);
  const { applicationsList } = useSelector<ApplicationState, CommonState>((state) => state.common);
  const [formData, setFormData] = useState<BroadcastCreate>(broadcastCreateDefaults);
  const [allHosts, setAllHosts] = useState(false);
  const [allMessages, setAllMessages] = useState(false);
  const [eventName, setEventName] = useState('');
  const [date, setDate] = useState('');

  const { section, message, host_id, event_id, application_id } = formData;
  const { hostsItems } = hostsState;
  const { events } = broadcastState;
  const {
    data: [],
  } = hostsItems || {};

  useEffect(() => {
    if (isEmpty(applicationsList)) getApplicationsList();
  }, []);

  useEffect(() => {
    if (isSaved) {
      setFormData(broadcastCreateDefaults);
      setDate('');
      setEventName('');
    }
  }, [isSaved]);

  const searchForEvents = useCallback(
    debounce(async (value: string) => {
      loadEvents(value);
    }, 400),
    []
  );
  const [dataError, setDataError] = useState({
    message: 'Required Field',
    messageDirty: false,
  });

  // Functions -----------------------------------------------------------------
  const onBlurArea = (name: string) => {
    switch (name) {
      case 'message':
        return formData.message.length === 0 ? setDataError({ ...dataError, messageDirty: true }) : setDataError({ ...dataError, messageDirty: false });
      default:
        return setDataError({ ...dataError, messageDirty: false });
    }
  };

  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleAllHostsChange = (value) => {
    setAllHosts(value);
  };
  const handleAllMessagesChange = (value) => {
    setAllMessages(value);
  };
  const handleFormSubmit = () => {
    const finalFormData = { ...formData };
    if (allHosts) {
      delete finalFormData.host_id;
    }
    if (allMessages) {
      delete finalFormData.section;
    }
    if (dataError.messageDirty === true || application_id === '') {
      alert('Please check all fields');
    } else {
      onSubmit(finalFormData);
    }
  };

  const handleEventSelect = (item: CommonEvent) => {
    handleFieldChange(item.event_id, 'event_id');
    setEventName(item.event_name);
    setDate(item.event_time);
  };
  const checkMessage = formData.message.length === 0 ? true : false;
  return (
    <Form className="broadcast-form" onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={
          <FormGroup title="Broadcast" fullWidth>
            <div className="broadcast-form__group">
              {/* <SelectMenu title="Host ID" value={host_id} name="host_id" labelKey="name" valueKey="id" disabled={allHosts} options={hostsItems.data} onChange={handleFieldChange} /> */}
              <HostSelectMenu 
                // initial_host_id={hostsState?.host?.data?.parent_host_id}
                disabled={allHosts}
                onChange={(val) => {
                  handleFieldChange(val, 'host_id');
                }}
              />
              <Checkbox checked={allHosts} text="All" onChange={handleAllHostsChange} />
            </div>
            <Autocomplete title="Event Name" placeholder="Event Name" items={events} nameKey="event_name" onChange={searchForEvents} onSelect={handleEventSelect} selectedValue={eventName} />
            <Input title="Event ID" value={event_id} name="event_id" disabled placeholder="Event ID" onChange={handleFieldChange} />
            <Datepicker title="Event Date/Time" placeholder="Event Date/Time" value={date} disabled onChange={handleFieldChange} />
            <div className="broadcast-form__group">
              <Input title="Section" value={section} name="section" disabled={allMessages} placeholder="Section" onChange={handleFieldChange} />
              <Checkbox checked={allMessages} text="Message To All" onChange={handleAllMessagesChange} />
            </div>
          </FormGroup>
        }
        rightContent={
          <FormGroup fullWidth>
            <SelectMenu title="Application" value={application_id} name="application_id" labelKey="name" valueKey="id" options={applicationsList || []} onChange={handleFieldChange} />
            <TextArea
              error={dataError.message && dataError.messageDirty ? true : false}
              onBlur={onBlurArea}
              title={dataError.message && dataError.messageDirty ? `Event Message - ${dataError.message}` : 'Event Message'}
              placeholder="Event Message"
              value={message}
              onChange={handleFieldChange}
              name="message"
            />
          </FormGroup>
        }
        footer={<Button disabled={checkMessage} title="Create" htmlType="submit" />}
      />
    </Form>
  );
};
