import classNames from 'classnames';
import React from 'react';
import { CountdownRenderProps } from "react-countdown";
import { Svg, SVG_ICONS } from '../Svg/Svg';

import './TimerRender.scss';
interface Props extends CountdownRenderProps {
    pendingText: string,
    doneText: string
}
const RenderCountdown: React.FC<Props>= ({ 
    formatted, 
    completed,
    pendingText = 'Remaining time:',
    doneText = 'Code Expired'
}) => {
    const { minutes, seconds } = formatted;
    const { CLOCK_DONE, CLOCK_PENDING } = SVG_ICONS;
    return (
        <div className={classNames(
            'timer-counter',
            completed ? 'done' : 'pending'
        )}>
            <Svg icon={completed ? CLOCK_DONE : CLOCK_PENDING} size={20} className='timer-counter__icon'/>
            <p>{completed ? doneText : `${pendingText} ${minutes}:${seconds}`}</p>
        </div>
    )
}

export default RenderCountdown;