import { Band, CreateBandData } from '../types/invoices';

export const UtilsBands = {
  convertBandToBandDetails: (data: Band): CreateBandData => {
    const {price_per_ticket, venue_size, name, annual_charge} = data;

    return {
      price_per_ticket: price_per_ticket.toString(),
      annual_charge: annual_charge.toString(),
      venue_size: venue_size.toString(),
      name,
    }
  },
};