import { CustomAction } from "..";
import { ITwoFactor } from "../../types/login";
import { LOGIN_ACTION_TYPES } from "../actions-types";

export interface LoginState extends ITwoFactor {};

const defaultState: LoginState = {
  expiration: new Date()
};

export default function loginReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case LOGIN_ACTION_TYPES.SET_LOGIN_2FA: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
