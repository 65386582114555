import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { User } from '../../types/user';

export const actionSetUser = (data: User): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER,
    data,
  };
};
