import React from 'react';

interface Props {
  text: string;
}

export const PageTitle: React.FC<Props> = (props) => {
  const {text} = props;

  return (
    <div className="page-title">{text}</div>
  );
};
