import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { UserInfoItem } from '../../../../components/users/UserInfoItem/UserInfoItem';
import { Tabs } from '../../../../components/_common/Tabs/Tabs';
import { Table } from '../../../../components/_common/Table/Table';
import { DATE_FORMAT } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../../../components/_common/Svg/Svg';
import { useTestingUserRequest } from '../../../../hooks/users-hooks';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { TestsType } from '../../../../constants/testing';
import { useTestsDownload } from '../../../../hooks/testing-hooks';

import './TestingUserPage.scss';

export const TestingUserPage: React.FC = () => {
  const params: any = useParams();
  const userId = params.id;
  const {loadUser} = useTestingUserRequest();
  const { downloadTest } = useTestsDownload();
  const usersState = useSelector<ApplicationState, UsersState>(state => state.users);
  const {testingUser} = usersState;
  const {data, registered, administered} = testingUser;
  const {email, name, phone} = data || {};

  useEffect(() => {
    loadUser(userId);
  }, [userId]);

  const registeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date_tested} = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
    ],
    [],
  );

  const administeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date_tested} = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'FtF',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { ftf, id } = original;

          const handleDownload = () => {
            downloadTest(id, TestsType.FTF);
          };

          if (ftf) {
            return (
              <button className="empty-button" type="button" onClick={handleDownload}>
                <Svg icon={SVG_ICONS.DOCUMENT} />
              </button>
            );
          }

          return <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />;
        }
      },
    ],
    [],
  );

  return (
    <div className="t-user">
      <div className="t-user__head">MatchFit ID: {userId}</div>
      <div className="t-user__common">
        <UserInfoItem title="Name" value={name} />
        <UserInfoItem title="Phone Number" value={phone} />
        <UserInfoItem title="Email Address" value={email} />
      </div>
      <div className="t-user__status">Testing Administered</div>
      <Tabs titles={['Registered Tests', 'Administered Tests']}>
        <div>
          <Table
            data={registered}
            columns={registeredColumns}
          />
        </div>
        <div>
          <Table
            data={administered}
            columns={administeredColumns}
          />
        </div>
      </Tabs>
    </div>
  );
};
