import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { CODES_ACTION_TYPES } from '../actions-types';
import { Code } from '../../types/codes';
import { codeDefaults } from '../../data/defaults';

export interface CodesState {
  codesItems: CommonEntityRes<Code>;
  activeCode: Code;
}

const defaultState: CodesState = {
  codesItems: {data: [], page: 1},
  activeCode: codeDefaults,
};

export default function codesReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case CODES_ACTION_TYPES.SET_CODES: {
      return {
        ...state,
        codesItems: action.data,
      };
    }
    case CODES_ACTION_TYPES.SET_CODE: {
      return {
        ...state,
        activeCode: action.data,
      };
    }
    case CODES_ACTION_TYPES.RESET_CODE: {
      return {
        ...state,
        activeCode: codeDefaults,
      };
    }
    default:
      return state;
  }
}
