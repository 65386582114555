import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { AuditHeader } from '../../../../components/audit/AuditHeader/AuditHeader';
import { useAuditVaccinesRequest, useVaccinesAuditExport } from '../../../../hooks/audit-hooks';
import { ApplicationState } from '../../../../store';
import { AuditState } from '../../../../store/reducers/audit-reducer';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/common';

export const AuditVaccinePage: React.FC = () => {
  const {loadVaccinesItems} = useAuditVaccinesRequest();
  const {exportAuditItemsToCsv} = useVaccinesAuditExport();
  const auditState = useSelector<ApplicationState, AuditState>(state => state.audit);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {vaccinesItems} = auditState;
  const {data, page, limit, total} = vaccinesItems || {};

  useEffect(() => {
    loadVaccinesItems(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (value: string) => {
    const newParams = {search: value};

    loadVaccinesItems(1, newParams);
    setSearchParams(newParams);
  }, 400), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Centre ID',
        accessor: 'centre_id',
      },
      {
        Header: 'Centre Name',
        accessor: 'centre_name',
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Batch ID',
        accessor: 'batch_id',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Time',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(TIME_FORMAT);
        },
      },
      {
        Header: 'Scan Check',
        accessor: 'scan_check',
      },
      {
        Header: 'App Check',
        accessor: 'app_check',
      },
      {
        Header: 'Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {mfp_id} = original;
          const url = `${URLS.USER}/${mfp_id}`;

          return (
            <Link to={url}>View</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    loadVaccinesItems(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    loadVaccinesItems(prevPage, searchParams);
  };

  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const handleFilterChange = (value: Date, name: string) => {
    const newParams = {...searchParams, [name]: value};

    setSearchParams(newParams);
    loadVaccinesItems(1, newParams);
  };

  const exportItems = () => {
    exportAuditItemsToCsv(searchParams.search, total);
  };

  return (
    <div className="audit">
      <AuditHeader
        title="View Vaccine Audit Trail"
        filterData={searchParams}
        onExport={exportItems}
        onSearch={handleSearch}
        onFilterChange={handleFilterChange}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
