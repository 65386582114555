import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../../../components/_common/PageHeader/PageHeader';
import { ROUTES } from '../../../../configs/routes';
import { CodeForm } from '../../../../components/codes/CodeForm/CodeForm';
import { CodeDetails } from '../../../../types/codes';
import { useHostsRequest } from '../../../../hooks/hosts-hooks';
import { useCodesRequest } from '../../../../hooks/codes-hooks';
import { URLS } from '../../../../constants/urls';
import { ApplicationState } from '../../../../store';
import { CodesState } from '../../../../store/reducers/codes-reducer';
import { actionResetCode } from '../../../../store/actions/codes-actions';
import { CodesHeader } from '../../../../components/codes/CodesHeader/CodesHeader';

export const CodePage: React.FC = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const codesState = useSelector<ApplicationState, CodesState>(state => state.codes);
  const {loadHosts} = useHostsRequest();
  const {createCode, updateCode} = useCodesRequest();
  const {activeCode} = codesState;
  const activeCodeId = activeCode.id;

  useEffect(() => {
    loadHosts();
  }, []);

  useEffect(() => {
    if (!params.id) {
      dispatch(actionResetCode());
    }
  }, [params.id]);

  const handleSave = (data: CodeDetails) => {
    if (activeCodeId) {
      updateCode(data, activeCodeId);
      return;
    }

    createCode(data);
  };

  return (
    <div>
      <CodesHeader />
      <div className="page-content">
        <CodeForm isNew={!activeCodeId} data={activeCode} onSubmit={handleSave} />
      </div>
    </div>
  );
};
