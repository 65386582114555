import { CustomAction } from '../index';
import { AUDIT_ACTION_TYPES } from '../actions-types';
import { Audit } from '../../types/audit';
import { CommonEntityRes } from '../../types/common';

export const actionSetAuditTrailItems = (data: CommonEntityRes<Audit>): CustomAction => {
  return {
    type: AUDIT_ACTION_TYPES.SET_TRAIL_ITEMS,
    data,
  };
};

export const actionSetAuditTrackItems = (data: CommonEntityRes<Audit>): CustomAction => {
  return {
    type: AUDIT_ACTION_TYPES.SET_TRACK_ITEMS,
    data,
  };
};

export const actionSetVaccinesAuditItems = (data: CommonEntityRes<Audit>): CustomAction => {
  return {
    type: AUDIT_ACTION_TYPES.SET_VACCINES_AUDIT_ITEMS,
    data,
  };
};

export const actionSetTestingAuditItems = (data: CommonEntityRes<Audit>): CustomAction => {
  return {
    type: AUDIT_ACTION_TYPES.SET_TESTING_AUDIT_ITEMS,
    data,
  };
};
