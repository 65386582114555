import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MfpUserForm } from '../../../../components/users/MfpUserForm/MfpUserForm';
import { useUsersRequest } from '../../../../hooks/users-hooks';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { actionSetAdminUser } from '../../../../store/actions/users-actions';
import { adminUserDefaults } from '../../../../data/defaults';
import { AdminFormEntity } from '../../../../types/users';

import './MfpUserPage.scss';

export const MfpUserPage: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const {adminUser} = useSelector<ApplicationState, UsersState>((state) => state.users);
  const {loadAdminUser, createAdminUser, updateAdminUser} = useUsersRequest();
  const userId = params.id;
  const isEdit = !!userId;

  useEffect(() => {
    if (userId) {
      loadAdminUser(userId);
    }

    return () => {
      dispatch(actionSetAdminUser(adminUserDefaults));
    };
  }, [userId]);

  const handleSubmit = async (data: AdminFormEntity) => {
    const isUserCreated = isEdit
      ? await updateAdminUser(userId, data, true)
      : await createAdminUser(data, true);

    return isUserCreated;
  };

  return (
    <div className="mfp-user">
      <MfpUserForm data={adminUser} isEdit={isEdit} onSubmit={handleSubmit} />
    </div>
  );
};
