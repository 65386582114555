import { CustomAction } from '../index';
import { TESTING_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import { testingCenterDefault } from '../../data/defaults';
import { TestingCenter, TestingUser } from '../../types/testing';

export interface TestingState {
  centers: CommonEntityRes<TestingCenter>;
  center: { data: TestingCenter; registered: any[]; administered: any[]; };
  registered: CommonEntityRes<any>;
  administered: CommonEntityRes<any>;
  users: TestingUser[];
}

const defaultState: TestingState = {
  centers: { data: [] },
  center: {
    data: testingCenterDefault,
    registered: [],
    administered: [],
  },
  registered: { data: [] },
  administered: { data: [] },
  users: [],
};

export default function testingReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case TESTING_ACTION_TYPES.SET_TESTING_CENTERS: {
      return {
        ...state,
        centers: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_CENTER: {
      return {
        ...state,
        center: {
          ...state.center,
          data: action.data,
        },
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_CENTER_REGISTERED: {
      return {
        ...state,
        center: {
          ...state.center,
          registered: action.data,
        },
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_CENTER_ADMINISTERED: {
      return {
        ...state,
        center: {
          ...state.center,
          administered: action.data,
        },
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_REGISTERED_ITEMS: {
      return {
        ...state,
        registered: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_ADMINISTERED_ITEMS: {
      return {
        ...state,
        administered: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_USERS: {
      return {
        ...state,
        users: action.data,
      };
    }
    default:
      return state;
  }
}