export enum TestsType {
  FTT = 'ftt',
  FTR = 'ftr',
  FTF = 'ftf',
}

export enum TESTING_STATUS {
  ALWAYS_REQUIRED = 'ALWAYS_REQUIRED',
  REQUIRED_VACCINE_INCOMPLETE = 'REQUIRED_VACCINE_INCOMPLETE',
  NOT_REQUIRED_VACCINATED = 'NOT_REQUIRED_VACCINATED',
}

export enum TESTING_TYPES {
  ANY = 'ANY',
  PCR = 'PCR',
  LFD = 'LFD',
}

export const TestingStatus = {
  [TESTING_STATUS.ALWAYS_REQUIRED]: 'Always required',
  [TESTING_STATUS.REQUIRED_VACCINE_INCOMPLETE]:
    'Required only when Vaccination Status is insufficient',
  [TESTING_STATUS.NOT_REQUIRED_VACCINATED]: 'Not required',
};

export const TestingTypes = {
  [TESTING_TYPES.ANY]: 'Any',
  [TESTING_TYPES.PCR]: 'Polymerase Chain Reaction (PCR)',
  [TESTING_TYPES.LFD]: 'Lateral Flow Device (LFD)',
};

export enum ENTRY_TYPES {
  UNIQUE_TICKET_REQUIRED = 'UNIQUE_TICKET_REQUIRED',
  UNIQUE_TICKET_GUEST_LIST = 'UNIQUE_TICKET_GUEST_LIST',
  GUEST_LIST = 'GUEST_LIST',
  SQRC = 'SQRC',
}

export enum ENTRY_TIMING {
  ALL_DAY = 'ALL_DAY',
  SPECIFIC = 'SPECIFIC',
}

export const EntryTypes = {
  [ENTRY_TYPES.UNIQUE_TICKET_REQUIRED]: 'A unique ticket is required',
  [ENTRY_TYPES.UNIQUE_TICKET_GUEST_LIST]:
    'A unique ticket is required or guest entry',
  [ENTRY_TYPES.GUEST_LIST]: 'Guest entry only',
  [ENTRY_TYPES.SQRC]: "Entry to all SQRC's",
};

export const EntryTiming = {
  [ENTRY_TIMING.ALL_DAY]: 'All day',
  [ENTRY_TIMING.SPECIFIC]: 'Specific',
};
