import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { AdminFormEntity, AdminUser } from '../../../types/users';
import { adminUserFormEntityDefaults } from '../../../data/defaults';
import { UtilsUser } from '../../../utils/UtilsUser';

interface Props {
  data: AdminUser;
  isEdit: boolean;
  onSubmit(data: AdminFormEntity): void;
}

export const MfpUserForm: React.FC<Props> = (props) => {
  const {isEdit, data, onSubmit} = props;
  const [formData, setFormData] = useState(adminUserFormEntityDefaults);
  const {
    mfp_id,
    Users,
    Audit,
    Hosts,
    Codes,
    Invoice,
    Vaccination,
    BroadcastMessage,
    CovidTesting,
  } = formData;
  const titleLeft = isEdit ? 'Edit User' : 'Add User';
  const titleRight = isEdit ? 'Edit User Permissions' : 'User Permissions';
  const buttonTitle = isEdit ? 'Save' : 'Create';

  const [dataError, setDataError] = useState({
    mfp_id: 'Required Field',
    mfpDirty: false,
  })

  useEffect(() => {
    const { permissions, mfp_id } = data;
    const permissionsValues = UtilsUser.getUserPermissionsValues(permissions);
    setFormData({
      ...formData,
      mfp_id,
      ...permissionsValues,
    })
  }, [data.id]);

  const handleFieldChange = (value: string | boolean | any, name: string) => {
    setFormData({...formData, [name]: value});
  };
  const handleFormSubmit = async () => {
    if(!isEdit && !formData.mfp_id) {
      setDataError((prevState) => ({ ...prevState, mfpDirty: true }));
      return;
    }
    setDataError((prevState) => ({ ...prevState, mfpDirty: false }));
    const response = await onSubmit(formData);

    if (response) {
      // clears the form data if form is not edit form
      if (!isEdit) setFormData(adminUserFormEntityDefaults);
    }
  };
  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={
          <FormGroup title={titleLeft} fullWidth>
            <Input
              error={dataError.mfpDirty}
              errorMessage={dataError.mfpDirty ? dataError.mfp_id : ''}
              title="MFP ID"
              value={mfp_id || ''}
              name="mfp_id"
              placeholder="MFP ID"
              disabled={isEdit}
              onChange={handleFieldChange}
            />
          </FormGroup>
        }
        rightContent={
          <FormGroup title={titleRight} fullWidth>
            <Checkbox
              checked={Users}
              name="Users"
              text="View/Edit Users"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Hosts}
              name="Hosts"
              text="View/Edit Hosts"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Vaccination}
              name="Vaccination"
              text="Vaccination"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={CovidTesting}
              name="CovidTesting"
              text="Covid Testing"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Invoice}
              name="Invoice"
              text="View Invoices"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Audit}
              name="Audit"
              text="View Audit Trail"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Codes}
              name="Codes"
              text="Create User Codes"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
          </FormGroup>
        }
        footer={<Button title={buttonTitle} htmlType="submit" />}
      />
    </Form>
  );
};
