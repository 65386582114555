import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PageTitle } from '../../../../components/_common/PageTitle/PageTitle';
import { VaccinationCenterForm } from '../../../../components/vaccination/VaccinationCenterForm/VaccinationCenterForm';
import { Tabs } from '../../../../components/_common/Tabs/Tabs';
import { Table } from '../../../../components/_common/Table/Table';
import { VaccineUserCreateForm } from '../../../../components/vaccination/VaccineUserCreateForm/VaccineUserCreateForm';
import { VaccineUserEditForm } from '../../../../components/vaccination/VaccineUserEditForm/VaccineUserEditForm';
import { CollapsePanel } from '../../../../components/_common/CollapsePanel/CollapsePanel';
import {
  useVacCenterLoadingRequest,
  useVacCenterUpdateRequest,
  useVacCenterUserRequest,
  useVacCenterUsersLoadRequest,
  useVacCenterUserUpdateRequest,
} from '../../../../hooks/vaccination-hooks';
import { VaccinationCenterCreateFormEntity, VaccineUserFormEntity } from '../../../../types/vaccination';
import { ApplicationState } from '../../../../store';
import { VaccinationState } from '../../../../store/reducers/vaccination-reducer';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/common';

import './VaccinationCenterPage.scss';

export const VaccinationCenterPage: React.FC = () => {
  const params: any = useParams();
  const {loadCenter} = useVacCenterLoadingRequest();
  const {createUser} = useVacCenterUserRequest();
  const {loadUsers} = useVacCenterUsersLoadRequest();
  const {updateUser} = useVacCenterUserUpdateRequest();
  const {updateCenter} = useVacCenterUpdateRequest();
  const vaccinationState = useSelector<ApplicationState, VaccinationState>(state => state.vaccination);
  const centerId = params.id;
  const {center} = vaccinationState;
  const {data, batches, inoculated} = center;
  const {name} = data;

  useEffect(() => {
    loadCenter(centerId);
    loadUsers(centerId);
  }, []);

  const batchesColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
    ],
    [],
  );

  const usersColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Date Vaccine',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Time Inoculated',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {inoculation_time} = original;

          return moment(inoculation_time).format(TIME_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
    ],
    [],
  );

  const handleCenterUpdate = (data: VaccinationCenterCreateFormEntity) => {
    updateCenter(centerId, data);
  };

  const handleUserUpdate = (data: any) => {
    updateUser(data);
  };

  const handleUserCreate = (data: VaccineUserFormEntity) => {
    createUser(data, centerId);
  };

  return (
    <div className="page-content vc-page">
      <PageTitle text={`Vaccine Centre: ${name}`} />
      <VaccinationCenterForm data={data} onSubmit={handleCenterUpdate} />
      <CollapsePanel title="Vaccination">
        <Tabs titles={['Active Batches', 'User\'s Inoculated']}>
          <div>
            <Table
              data={batches}
              columns={batchesColumns}
            />
          </div>
          <div>
            <Table
              data={inoculated}
              columns={usersColumns}
            />
          </div>
        </Tabs>
      </CollapsePanel>
      <CollapsePanel title="User Account Management">
        <Tabs titles={['Create a New User', 'Edit an Active User']}>
          <div>
            <VaccineUserCreateForm onSubmit={handleUserCreate} />
          </div>
          <div>
            <VaccineUserEditForm onSubmit={handleUserUpdate} />
          </div>
        </Tabs>
      </CollapsePanel>
    </div>
  )
};
