export const USER_ACTION_TYPES = {
  SET_USER: 'SET_USER',
};

export const COMMON_ACTION_TYPES = {
  SET_APPLICATIONS_LIST: 'SET_APPLICATIONS_LIST',
  SET_FCM_INSTANCE: 'SET_FCM_INSTANCE',
};

export const USERS_ACTION_TYPES = {
  SET_ADMIN_USERS: 'SET_ADMIN_USERS',
  SET_ADMIN_USER: 'SET_ADMIN_USER',
  SET_APP_USERS: 'SET_APP_USERS',
  SET_TOTAL_APP_USERS: 'SET_TOTAL_APP_USERS',
  SET_APP_USER: 'SET_APP_USER',
  SET_APP_USER_GUARDIAN: 'SET_APP_USER_GUARDIAN',
  SET_APP_USER_AUDIT: 'SET_APP_USER_AUDIT',
  SET_APP_USER_TICKETS: 'SET_APP_USER_TICKETS',
  SET_APP_USER_TESTING: 'SET_APP_USER_TESTING',
  RESET_APP_USER: 'RESET_APP_USER',
  SET_VERIFICATION_USERS: 'SET_VERIFICATION_USERS',
  SET_VACCINATION_USERS: 'SET_VACCINATION_USERS',
  SET_VACCINATION_USER: 'SET_VACCINATION_USER',
  SET_VACCINATION_BATCHES: 'SET_VACCINATION_BATCHES',
  SET_VACCINATION_INOCULATED: 'SET_VACCINATION_INOCULATED',
  SET_TESTING_USERS: 'SET_TESTING_USERS',
  SET_TESTING_USER: 'SET_TESTING_USER',
  SET_TESTING_USER_REGISTERED: 'SET_TESTING_USER_REGISTERED',
  SET_TESTING_USER_ADMINISTERED: 'SET_TESTING_USER_ADMINISTERED',
};

export const HOSTS_ACTION_TYPES = {
  SET_HOSTS: 'SET_HOSTS',
  SET_HOST: 'SET_HOST',
  RESET_HOST: 'RESET_HOST',
  SET_HOST_TICKETS: 'SET_HOST_TICKETS',
  SET_HOST_AUDIT: 'SET_HOST_AUDIT',
  SET_HOST_USERS: 'SET_HOST_USERS',
  SET_HOST_INVOICES: 'SET_HOST_INVOICES',
};

export const INVOICES_ACTION_TYPES = {
  SET_INVOICES: 'SET_INVOICES',
  SET_BANDS: 'SET_BANDS',
  SET_BAND: 'SET_BAND',
};

export const CODES_ACTION_TYPES = {
  SET_CODES: 'SET_CODES',
  SET_CODE: 'SET_CODE',
  RESET_CODE: 'RESET_CODE',
};

export const BROADCAST_ACTION_TYPES = {
  SET_EVENTS: 'SET_EVENTS',
  SET_VACCINES_BATCHES: 'SET_VACCINES_BATCHES',
  SET_VACCINES_CODES: 'SET_VACCINES_CODES',
  SET_VACCINES_DATES: 'SET_VACCINES_DATES',
  SET_BROADCAST_TESTING_CENTRES: 'SET_BROADCAST_TESTING_CENTRES',
  SET_BROADCAST_TESTING_TYPES: 'SET_BROADCAST_TESTING_TYPES',
  SET_BROADCAST_TESTING_LOTS: 'SET_BROADCAST_TESTING_LOTS',
  SET_BROADCAST_TESTING_CODES: 'SET_BROADCAST_TESTING_CODES',
  SET_BROADCAST_TESTING_DATES: 'SET_BROADCAST_TESTING_DATES',
};

export const AUDIT_ACTION_TYPES = {
  SET_TRACK_ITEMS: 'SET_TRACK_ITEMS',
  SET_TRAIL_ITEMS: 'SET_TRAIL_ITEMS',
  SET_VACCINES_AUDIT_ITEMS: 'SET_VACCINES_AUDIT_ITEMS',
  SET_TESTING_AUDIT_ITEMS: 'SET_TESTING_AUDIT_ITEMS',
};

export const VACCINATION_ACTION_TYPES = {
  SET_VAC_CENTERS: 'SET_VAC_CENTERS',
  SET_VAC_CENTER: 'SET_VAC_CENTER',
  SET_VAC_CENTER_BATCHES: 'SET_VAC_CENTER_BATCHES',
  SET_VAC_CENTER_INOCULATED: 'SET_VAC_CENTER_INOCULATED',
  SET_REGISTERED_ITEMS: 'SET_REGISTERED_ITEMS',
  SET_ADMINISTERED_ITEMS: 'SET_ADMINISTERED_ITEMS',
  SET_VACCINES_USERS: 'SET_VACCINES_USERS',
};

export const TESTING_ACTION_TYPES = {
  SET_TESTING_CENTERS: 'SET_TESTING_CENTERS',
  SET_TESTING_CENTER: 'SET_TESTING_CENTER',
  SET_TESTING_CENTER_REGISTERED: 'SET_TESTING_CENTER_REGISTERED',
  SET_TESTING_CENTER_ADMINISTERED: 'SET_TESTING_CENTER_ADMINISTERED',
  SET_TESTING_USERS: 'SET_TESTING_USERS',
  SET_TESTING_REGISTERED_ITEMS: 'SET_TESTING_REGISTERED_ITEMS',
  SET_TESTING_ADMINISTERED_ITEMS: 'SET_TESTING_ADMINISTERED_ITEMS',
};

export const LOGIN_ACTION_TYPES = {
  SET_LOGIN_2FA: 'SET_LOGIN_2FA',
};
