import { useDispatch } from 'react-redux';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { Code, CodeDetails } from '../types/codes';
import { CommonEntityRes } from '../types/common';
import { actionSetCodes } from '../store/actions/codes-actions';

export function useCodesRequest() {
  const dispatch = useDispatch();

  const loadCodes = async (page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.CODES}?page=${page}&limit=10&`;
      const res: CommonEntityRes<Code> = await Http.get(url);

      dispatch(actionSetCodes(res));
    } catch (e) {
      console.log(e);
    }
  };

  const createCode = async (data: CodeDetails) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.CODES}`;
      const res: any = await Http.post(url, data);

      alert('User Code is Created')
    } catch (e) {
      console.log(e);
      console.log(e.message);
    }
  };

  const updateCode = async (data: CodeDetails, id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.CODES}/${id}`;
      const res: any = await Http.put(url, data);

      alert('User Code is Updated')
    } catch (e) {
      console.log(e);
      console.log(e.message);
    }
  };

  return {loadCodes, createCode, updateCode};
}
