import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks/hooks';
import { useHostsSearch } from '../../../hooks/hosts-hooks';
import Select from 'react-select';
import classNames from 'classnames';
import { Host } from '../../../types/hosts';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';


import './HostSelectMenu.scss';
import _ from 'lodash';
type Props = {
    title?: string;
    disabled?: boolean;
    initial_host_id?: string,
    onChange: (value: string | null) => void,
}
const HostSelectMenu: React.FC<Props> = (props) => {
    const { 
        title = 'Parent Host Title',
        disabled = false,
        initial_host_id, 
        onChange } = props;

    const [inputText, setInputText] = useState('');
    const [query, setQuery] = useState('');
    useEffect(() => {
        setQuery(inputText);
    }, [inputText]);
    
    const debounceQuery = useDebounce(query, 500);
    const { data, isLoading, isFetching, isError, error, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useHostsSearch(debounceQuery);
    const fetching = isLoading || isFetching || isFetchingNextPage;
    const [options, setOptions] = useState<Host[]>([]);
    React.useEffect(() => {
        if (data?.pages) {
            const newOptions = _.flatten(data.pages?.map(res => res.data));
            setOptions([...newOptions])
        }
    }, [data?.pages])
    
    const [selected, setSelected] = useState<Host | null>(null);
    const select = (id: string) => options.findIndex(d => d.id === id);
    const selectHost = async (hostId?: string) => {
        if (!Boolean(hostId)) {
            setSelected(null);
            setInputText('');
            setQuery(null)
            onChange(null);
            await refetch({ refetchPage: (index) => index === 0 });
        }

        const idx = select(hostId);
        if (idx > -1) {
            const host = options[idx];
            setSelected(host);
            onChange(host.id);
        }
    }

    useEffect(() => {
        if (initial_host_id) {
            setQuery(initial_host_id);
        }
    }, [initial_host_id])
    useEffect(() => {
        if (initial_host_id
            && options?.length === 1 
            && select(initial_host_id) > -1 
        ){
            selectHost(initial_host_id);
        }
    }, [options, initial_host_id])

    const wrapperClass = classNames('select-menu host-select-menu', {
        'host-select-menu__error': !!error,
    });
    const checkError = isError ? {color: 'red'} : {color: 'black'};

    const onSelect = (host: Host) => {
        selectHost(host.id);
    }

    const scrollDown = async () => {
        hasNextPage && await fetchNextPage()
    }

    useEffect(() => {
        if (disabled) {
            selectHost(null);
        }
    }, [disabled])
    return (
        <div className={wrapperClass}>
            <div style={checkError} className="host-select-menu__title">{title}</div>
            <div className="host-select-menu__wrap">
            <Select
                name="parent_host_id"
                isDisabled={disabled}
                className="host-select-menu__field"
                classNamePrefix="host-select-menu"
                value={selected}
                inputValue={inputText}
                options={options}
                getOptionValue={o => o.id}
                getOptionLabel={o => o.name}
                isLoading={fetching}
                onInputChange={(val, event) => {
                    if (event.action !== "input-blur" && event.action !== "menu-close") {
                        setInputText(val);
                    }
                }}
                onChange={onSelect}
                onMenuScrollToBottom={scrollDown}
            />
            {selected && !fetching && (
                <button
                    type="button"
                    className="host-select-menu__clear-btn"
                    onClick={() => selectHost(null)}
                >
                    <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />
                </button>
            )}
             </div>
        </div>
    )
}

export default HostSelectMenu;