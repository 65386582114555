import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { HOSTS_ACTION_TYPES } from '../actions-types';
import { Host, HostUser } from '../../types/hosts';
import { AppUserTicket } from '../../types/users';
import { Audit } from '../../types/audit';
import { Invoice } from '../../types/invoices';

export const actionSetHosts = (data: CommonEntityRes<Host>): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOSTS,
    data,
  };
};

export const actionSetHost = (hostData: Host): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOST,
    data: {
      hostData,
    },
  };
};

export const actionResetHost = (): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.RESET_HOST,
  };
};

export const actionSetHostTickets = (data: CommonEntityRes<AppUserTicket>): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOST_TICKETS,
    data,
  };
};

export const actionSetHostAudit = (data: CommonEntityRes<Audit>): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOST_AUDIT,
    data,
  };
};

export const actionSetHostUsers = (data: HostUser[]): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOST_USERS,
    data,
  };
};

export const actionSetHostInvoices = (data: Invoice[]): CustomAction => {
  return {
    type: HOSTS_ACTION_TYPES.SET_HOST_INVOICES,
    data,
  };
};