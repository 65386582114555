import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { Table } from '../../../../components/_common/Table/Table';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { URLS } from '../../../../constants/urls';
import { useUsersRequest } from '../../../../hooks/users-hooks';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { CommonObject } from '../../../../types/common';
import { DATE_FORMAT } from '../../../../constants/common';
import { ItemsCounter } from '../../../../components/_common/ItemsCounter/ItemsCounter';

import './UsersPage.scss';

export const UsersPage: React.FC = () => {
  const {loadAppUsers, loadAppUsersCount} = useUsersRequest();
  const appUsersState = useSelector<ApplicationState, UsersState>((state) => state.users);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {appUserCounter, appUsers} = appUsersState;
  const {data, limit, total, page} = appUsers;
  const {search} = searchParams;

  useEffect(() => {
    loadAppUsersCount();
    loadAppUsers(1, searchParams);
  }, []);

  const searchForUsers = useCallback(debounce(async (params: CommonObject) => {
    loadAppUsers(1, params);
  }, 400), []);


  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForUsers(newParams);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Match Fit ID',
        accessor: 'id',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Last Active',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {last_active_at} = original;

          return moment(last_active_at).format(DATE_FORMAT);
        },
      },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.USER}/${id}`;

          return (
            <Link to={url}>View details</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadAppUsers(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadAppUsers(prevPage, searchParams);
  };

  return (
    <div className="users">
      <UsersHeader
        title="Users"
        content={(
          <div className="users__header">
            <ItemsCounter title="Live Users Counter:" count={appUserCounter} />
            <Input placeholder="Search users" value={search} search onChange={handleSearch} />
          </div>
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
