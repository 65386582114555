import React from 'react';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import './Tabs.scss';

interface Props {
  titles: string[];
}

export const Tabs: React.FC<Props> = (props) => {
  const {titles, children} = props;

  const renderTabs = () => {
    return titles.map((item, index) => {
      return (
        <Tab key={index}>{item}</Tab>
      );
    });
  };

  const renderTabsPanels = () => {
    if (Array.isArray(children)) {
      return children.map((item, index) => {
        return (
          <TabPanel key={index}>{item}</TabPanel>
        );
      });
    }

    return (
      <TabPanel>{children}</TabPanel>
    );
  };

  return (
    <ReactTabs>
      <TabList>
        {renderTabs()}
      </TabList>
      {renderTabsPanels()}
    </ReactTabs>
  );
};
