import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty, isNumber } from 'lodash';

import {
  TempVaccineDoses,
  VaccineDoses,
  VaccineStatus,
} from '../../../constants/vaccines';
import { useEntryCriteriaRequests } from '../../../hooks/hosts-hooks';
import { HostEntryCriteria } from '../../../types/hosts';
import { Button } from '../../_common/_controls/Button/Button';

import EntryCriteriaModal from '../EditEntryCriteriaModal/EditEntryCriteriaModal';

import './EntryCriteria.scss';
import { TestingStatus, TestingTypes } from '../../../constants/testing';
import {
  EntryTiming,
  EntryTypes,
  ENTRY_TIMING,
  ENTRY_TYPES,
} from '../../../constants/entrycriteria';

interface Props {
  hostId: string;
}

export const EntryCriteria: React.FC<Props> = (props) => {
  const { hostId } = props;
  const [entryCriteria, setEntryCriteria] = useState<any>({});
  const [showEntryCriteriaModal, setShowEntryCriteriaModal] = useState(false);

  const { loadEntryCriteria, updateEntryCriteria } = useEntryCriteriaRequests();

  const getEntryCriteria = async () => {
    const res = await loadEntryCriteria(hostId);

    if (res?.error || res?.statusCode) {
      setEntryCriteria({});
    } else {
      // TODO: TEMP parse dose count(type: number) to ENUM due to API response/request disparity
      if (res?.vaccination_status?.dose_count !== undefined) {
        res.vaccination_status.dose_count =
          TempVaccineDoses[res.vaccination_status.dose_count];
      }
      // TODO: Temporary handling for null entry timing initial data
      if (res?.entry_timing) {
        if (res.entry_timing.type_of_entry === null) res.entry_timing.type_of_entry = ENTRY_TYPES.SQRC;
        if (res.entry_timing.timing_of_entry === null) res.entry_timing.timing_of_entry = ENTRY_TIMING.ALL_DAY;
      }
      setEntryCriteria(res);
    }
  };

  useEffect(() => {
    getEntryCriteria();
  }, [hostId]);

  const onSaveEntryCriteria = async (data: HostEntryCriteria) => {
    const res = await updateEntryCriteria(hostId, data);
    if (res) {
      getEntryCriteria();
      setShowEntryCriteriaModal(false);
      alert('Host Entry Criteria saved successfully');
    }
  };

  const getCriteriaLabels = (entryCriteria: any) => {
    if (isEmpty(entryCriteria)) return [];

    // Prepare fallback values
    const {
      age_restriction,
      vaccination_status,
      testing_result,
      entry_timing,
    } = entryCriteria;
    const { minimum } = age_restriction;
    const { status, dose_count, min_vaccine_age, max_vaccine_age } =
      vaccination_status;
    const { result, type_accepted, max_test_age } = testing_result;

    const criteriaData = [
      {
        section: 'AGE RESTRICTION',
        sectionLabels: [
          {
            label: 'Minimum Age Requirement',
            value: !minimum ? 'None' : `${minimum} years old`,
          },
        ],
      },
      {
        section: 'COVID-19 VACCINATION STATUS',
        columnClass: 'mid-column',
        sectionLabels: [
          {
            label: 'Vaccination Status',
            value: status ? VaccineStatus[status] : 'N/A',
          },
          {
            label: 'Number of Doses Required',
            value: dose_count ? VaccineDoses[dose_count] : 'N/A',
          },
          {
            label: 'Minimum Vaccination Age',
            value:
              min_vaccine_age === 0 || !isNumber(min_vaccine_age)
                ? 'N/A'
                : `${min_vaccine_age} weeks`,
          },
          {
            label: 'Maximum Vaccination Age',
            value:
              max_vaccine_age === 0 || !isNumber(max_vaccine_age)
                ? 'N/A'
                : `${max_vaccine_age} months`,
          },
        ],
      },
      {
        section: 'COVID-19 TESTING RESULT',
        sectionLabels: [
          {
            label: 'Negative Test Result',
            value: result ? TestingStatus[result] : 'N/A',
          },
          {
            label: 'Test Type Accepted',
            value: type_accepted ? TestingTypes[type_accepted] : 'N/A',
          },
          {
            label: 'Minimum Testing Age',
            value:
              max_test_age === 0 || !isNumber(max_test_age)
                ? 'N/A'
                : `${max_test_age} hours`,
          },
        ],
      },
    ];
    if (entry_timing && entry_timing?.type_of_entry) {
      const {
        type_of_entry,
        timing_of_entry,
        before_start_time,
        after_start_time,
      } = entry_timing;

      criteriaData.push({
        section: 'ENTRY AND TIMING',
        sectionLabels: [
          {
            label: 'Type of Entry',
            value: type_of_entry ? EntryTypes[type_of_entry] : 'N/A',
          },
          {
            label: 'Timing of Entry',
            value: timing_of_entry ? EntryTiming[timing_of_entry] : 'N/A',
          },
          {
            label: 'Before Event Start Time',
            value:
              before_start_time === 0 || !isNumber(before_start_time)
                ? 'N/A'
                : `${before_start_time} minutes`,
          },
          {
            label: 'After Event Start Time',
            value:
              after_start_time === 0 || !isNumber(after_start_time)
                ? 'N/A'
                : `${after_start_time} minutes`,
          },
        ],
      });
    }
    return criteriaData;
  };

  return (
    <div className="entry-criteria">
      <div className="entry-criteria__header">
        <div className="title">Entry Criteria</div>
        <div className="edit-button">
          <Button
            title="Edit Criteria"
            type="empty"
            onClick={() => setShowEntryCriteriaModal(true)}
          />
        </div>
      </div>
      <div className="entry-criteria__content">
        {getCriteriaLabels(entryCriteria).map((section, i, obj) => (
          <Fragment key={i}>
            <div className="section-column">
              <div className="column-header">{section.section}</div>
              {section.sectionLabels.map((sectionLabels, x) => (
                <div className="result-item" key={x}>
                  <span>{sectionLabels.label}</span>
                  <p>{sectionLabels.value}</p>
                </div>
              ))}
            </div>
            {i !== obj.length && <div className="divider" />}
          </Fragment>
        ))}
      </div>
      <EntryCriteriaModal
        visible={showEntryCriteriaModal}
        onClose={() => setShowEntryCriteriaModal(false)}
        onSubmit={(data) => onSaveEntryCriteria(data)}
        initialValues={entryCriteria}
      />
    </div>
  );
};
