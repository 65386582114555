import React, { useEffect } from 'react';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';

import './PasswordValidator.scss';

interface Props {
  value: string;
  onChange(valid: boolean): void;
}

export const PasswordValidator: React.FC<Props> = (props) => {
  const {value, onChange} = props;
  const lengthIsValid = value.length >= 8;
  const hasUpperCase = value.length && value.toLowerCase() !== value;
  const hasNumber = /\d/.test(value);
  const hasSpecialSymbol = /[*!%&@#$]/.test(value);
  const passwordIsValid = lengthIsValid && hasUpperCase && hasNumber && hasSpecialSymbol;

  useEffect(() => {
    onChange(passwordIsValid);
  }, [passwordIsValid]);

  return (
    <div className="password-validator">
      <div className="password-validator__text">Your Password Must:</div>
      <Checkbox checked={lengthIsValid} text="Use at least 8 characters" minified disabled />
      <Checkbox checked={hasUpperCase} text="Use a capital letter" minified disabled />
      <Checkbox checked={hasNumber} text="Use a number" minified disabled />
      <Checkbox checked={hasSpecialSymbol} text="Use a special character: ! % & @ # $ *" minified disabled />
    </div>
  );
};
