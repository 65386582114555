import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { ApplicationState } from '../../../../store';
import { CommonObject } from '../../../../types/common';
import { TestingHeader } from '../../../../components/testing/TestingHeader/TestingHeader';
import { TestingState } from '../../../../store/reducers/testing-reducer';
import { useTestingCentersLoadingRequest } from '../../../../hooks/testing-hooks';

export const TestingCentersPage: React.FC = () => {
  const testingState = useSelector<ApplicationState, TestingState>(state => state.testing);
  const {loadCenters} = useTestingCentersLoadingRequest();
  const [searchParams, setSearchParams] = useState({search: ''});
  const {search} = searchParams;
  const {centers} = testingState;
  const {data, page, limit, total} = centers;

  useEffect(() => {
    loadCenters(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (params: CommonObject) => {
    loadCenters(1, params);
  }, 400), []);

  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Test Centre',
        accessor: 'name',
      },
      {
        Header: 'Centre ID',
        accessor: 'id',
      },
      {
        Header: 'Centre Manager',
        accessor: 'key_contact',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.TESTING}${URLS.CENTER}/${id}`;

          return (
            <Link to={url}>View</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadCenters(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadCenters(prevPage, searchParams);
  };

  return (
    <div>
      <TestingHeader
        title="View Current Test Centres"
        content={(
          <Input placeholder="Search centers" value={search} search onChange={handleSearch} />
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
