import React from 'react';
import classNames from 'classnames';

import './TextArea.scss';

interface Props {
  onBlur?: any;
  error?: boolean;
  value: string;
  name?: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string, name: string) => void;
}

export const TextArea: React.FC<Props> = (props) => {
  const {title, value = '', name = '', placeholder, onBlur, error, disabled, onChange} = props;
  const wrapperClass = classNames('textarea', {
    'textarea--disabled': disabled,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.value, name);
    }
  };
  const checkError = error ? {color: 'red'} : {color: 'black'}
  return (
    <div className={wrapperClass}>
      {title && <div style={checkError} className="textarea__title">{title}</div>}
      <textarea
        onBlur={onBlur ? (e)=>{onBlur(e.target.name)} : ()=>{}}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  );
};
