import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { HostUserEditFormEntity } from '../../../types/hosts';
import { hostUserEditFormEntityDefaults } from '../../../data/defaults';
import { ApplicationState } from '../../../store';
import { HostsState } from '../../../store/reducers/hosts-reducer';
import { UtilsUser } from '../../../utils/UtilsUser';

interface Props {
  onSubmit(data: HostUserEditFormEntity): void;
}

export const HostUserEditForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const {host} = useSelector<ApplicationState, HostsState>((state) => state.hosts);
  const {users} = host;
  const [formData, setFormData] = useState<HostUserEditFormEntity>(hostUserEditFormEntityDefaults);
  const {
    id,
    Tickets,
    Audit,
    SubUserManagement,
    BroadcastMessage,
    AccountDetails,
    Invoice,
  } = formData;

  useEffect(() => {
    if (id) {
      const userData = users.find(item => item.id === id);
      const permissionsValues = UtilsUser.getUserPermissionsValues(userData.permissions);

      setFormData({
        ...formData,
        ...permissionsValues,
      });
    }
  }, [id]);

  const handleFieldChange = (value: string | boolean, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
            <SelectMenu
              title="Active User"
              value={id}
              name="id"
              options={users}
              valueKey="id"
              labelKey="name"
              placeholder="Active User"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={Tickets}
              name="Tickets"
              text="View Tickets"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AccountDetails}
              name="AccountDetails"
              text="View/Edit Hosts"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Invoice}
              name="Invoice"
              text="Invoice"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={Audit}
              name="Audit"
              text="Audit Trail"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Save" htmlType="submit" />
        )}
      />
    </Form>
  );
};
