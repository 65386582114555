import React from 'react';
import { isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';

// wrapper component to integrate with react-hook-form
interface Props {
  control: any;
  name: string;
  rules?: any;
  component: any;
  htmlType?: 'text' | 'number';
}

export const Field: React.FC<Props> = ({ control, name, rules, component, htmlType, ...restField }) => {
  const Component = component;
  const isNumber = htmlType === 'number';

  return (
    <Controller
      control={control}
      name={name}
      rules={{ ...rules, pattern: isNumber ? /^[0-9]*$/g : ''}}
      render={({
        field: { onChange, value, name },
        fieldState: { error },
      }) => {
        return (
          <Component
            error={error}
            errorMessage={error?.message}
            value={value}
            onChange={(e) => {
              // normal handling for text input
              if (!isNumber) onChange(e);
              // for number input
              else {
                // don't allow non-numeric input
                const clean = e.replace(/\D+/g, '');
                
                // don't parse int when empty
                if (!isEmpty(clean)) onChange(parseInt(clean));
                else onChange(clean);
              }
            }}
            name={name}
            {...restField}
          />
        );
      }}
    />
  );
}