import React from 'react';
import { FormCard } from '../../../components/_common/FormCard/FormCard';
import { ForgotPasswordForm } from '../../../components/authorization/ForgotPasswordForm/ForgotPasswordForm';
import { useForgotPassword } from '../../../hooks/user-hooks';
import { ResetPasswordEntity } from '../../../types/authorization';

import './ForgotPasswordPage.scss';

export const ForgotPasswordPage: React.FC = () => {
  const {sendEmail, resetPassword} = useForgotPassword();

  const handleEmailSend = (email: string) => {
    sendEmail(email);
  };

  const handlePasswordReset = (data: ResetPasswordEntity) => {
    resetPassword(data);
  };

  return (
    <div>
      <FormCard title="Forgot password" className="forgot-card">
        <ForgotPasswordForm onPasswordSubmit={handlePasswordReset} onEmailSubmit={handleEmailSend} />
      </FormCard>
    </div>
  );
};
