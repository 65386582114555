import React, { useState } from 'react';
import { ROUTES } from '../../../configs/routes';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';

import './InvoiceHeader.scss';
import { InvoiceSearchParams } from '../../../types/invoices';

interface Props {
  title: string;
  hideActions?: boolean;
  filterData?: InvoiceSearchParams;
  onFilterChange?: (value: Date, name: string) => void;
  onSearchChange?: (value: string) => void;
  onExport?: () => void;
}

export const InvoiceHeader: React.FC<Props> = (props) => {
  const { title, hideActions, filterData, onExport, onSearchChange, onFilterChange } = props;
  const [searchStr, setSearchStr] = useState('');
  const { from, to } = filterData || {};

  const pageSections = [
    {
      route: ROUTES.INVOICE,
      name: 'View Host Invoices',
    },
    {
      route: ROUTES.INVOICE_CREATE,
      name: 'Create an Invoice and Support Schedule',
    },
    {
      route: ROUTES.INVOICE_BAND,
      name: 'Add/Edit a New Band',
    },
  ];

  const handleSearch = (searchValue: string) => {
    setSearchStr(searchValue);
    onSearchChange(searchValue);
  };

  const handleExport = () => {
    onExport && onExport();
  };

  return (
    <PageHeader
      title={title}
      content={(
        <>
          {!hideActions && (
            <div className="invoice-header__actions">
              <Input placeholder="Search invoice" value={searchStr} search onChange={handleSearch} />
              <div className="invoice-header__filter">
                <Datepicker title="From" value={from} name="from" placeholder="From" onChange={onFilterChange} />
                <Datepicker title="To" value={to} name="to" placeholder="To" onChange={onFilterChange} />
              </div>
              <div className="invoice-header__export">
                <div className="invoice-header__export-title">Export Search to Daybook</div>
                <Button title="Export" onClick={handleExport} />
              </div>
            </div>
          )}
        </>
      )}
      sections={pageSections}
    />
  );
};
