import React from 'react';

import './ItemsCounter.scss';

interface Props {
  title: string;
  count: number;
}

export const ItemsCounter: React.FC<Props> = (props) => {
  const {title, count} = props;

  return (
    <div className="items-counter">
      <div className="items-counter__title">{title}</div>
      <div className="items-counter__value">{count}</div>
    </div>
  );
};
