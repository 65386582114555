import React, { useMemo } from 'react';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { ROUTES } from '../../../configs/routes';
import { URLS } from '../../../constants/urls';
import { AdminPermissionsNames } from '../../../constants/users';
import { useUserPermissions } from '../../../hooks/user-hooks';

export const CodesHeader: React.FC = () => {
  const {hasPermissions} = useUserPermissions();
  const hasCodesCreatePermissions = hasPermissions(AdminPermissionsNames.Users);

  const pageSections = useMemo(() => {
    const sections = [
      {
        route: ROUTES.CODES,
        name: 'View Active Codes',
      },
      {
        route: URLS.CODES_CREATE,
        name: 'Create New Codes',
      },
    ];

    if (!hasCodesCreatePermissions) {
      sections.splice(1, 1);
    }

    return sections;
  }, [hasCodesCreatePermissions]);

  return (
    <PageHeader title="User Codes" sections={pageSections} />
  );
};
