import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { Svg, SVG_ICONS } from '../Svg/Svg';

import './Table.scss';

interface Props {
  data: any[];
  columns: any[];
  title?: string;
  page?: number;
  total?: number;
  limit?: number;
  onNext?: () => void;
  onPrev?: () => void;
}

export const Table: React.FC<Props> = (props) => {
  const {columns, data = [], page, limit, total, onNext, onPrev, title} = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  const itemsCount = data.length;
  const pagesCount = useMemo(() => {
    if (itemsCount && total) {
      return Math.ceil(total / limit);
    }

    return 1;
  }, [limit, total]);

  const handleNext = () => {
    if (page < pagesCount) {
      onNext();
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      onPrev();
    }
  };

  const renderHead = () => {
    if (!headerGroups.length) return null;
    return headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ))}
      </tr>
    ));
  };

  const renderRows = () => {
    if (!rows.length) return null;

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ))}
        </tr>
      );
    });
  };

  return (
    <div>
      {title && <div className="table-title">{title}</div>}
      <div className="table-actions">
        {!!page && (
          <div className="table-actions__pagination">
            <div className="table-actions__item">
              <div className="table-actions__item-name">Page:</div>
              <div className="table-actions__page">
                <div className="table-actions__page-current">{page}</div>
                <div className="table-actions__page-total">/{pagesCount}</div>
              </div>
            </div>
            <button className="table-actions__pag-btn" type="button" onClick={handlePrev}>
              <Svg icon={SVG_ICONS.CHEVRON_LEFT} />
            </button>
            <button className="table-actions__pag-btn" type="button" onClick={handleNext}>
              <Svg icon={SVG_ICONS.CHEVRON_RIGHT} />
            </button>
          </div>
        )}
      </div>
      <table {...getTableProps()} className="table">
        <thead>
        {renderHead()}
        </thead>
        <tbody {...getTableBodyProps()}>
        {renderRows()}
        </tbody>
      </table>
    </div>
  );
};
