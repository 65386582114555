import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../../../store';
import { URLS } from '../../../../constants/urls';
import { Table } from '../../../../components/_common/Table/Table';
import { useCodesRequest } from '../../../../hooks/codes-hooks';
import { CodesState } from '../../../../store/reducers/codes-reducer';
import { actionSetCode } from '../../../../store/actions/codes-actions';
import { DATE_FORMAT } from '../../../../constants/common';
import { CodesHeader } from '../../../../components/codes/CodesHeader/CodesHeader';

export const CodesPage: React.FC = () => {
  const dispatch = useDispatch();
  const {loadCodes} = useCodesRequest();
  const [page, setPage] = useState(1);
  const codesState = useSelector<ApplicationState, CodesState>(state => state.codes);
  const {codesItems} = codesState;
  const {data, total, limit} = codesItems;

  useEffect(() => {
    loadCodes(page);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Voucher Name',
        accessor: 'voucher_name',
      },
      {
        Header: 'Voucher Code',
        accessor: 'voucher_code',
      },
      {
        Header: 'Host Assigned',
        accessor: 'assigned_to_host_id',
      },
      {
        Header: 'Expiry Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {expiration_date} = original;

          return expiration_date ? moment(expiration_date).format(DATE_FORMAT) : 'One Time Usage';
        },
      },
      {
        Header: 'No. Users',
        accessor: 'users_count',
      },
      {
        Header: 'Max Codes',
        accessor: 'max_codes',
      },
      {
        Header: 'Edit',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.CODES_CREATE}/${id}`;

          const handleClick = () => {
            dispatch(actionSetCode(original));
          };

          return (
            <Link to={url} onClick={handleClick}>Edit</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadCodes(nextPage);
    setPage(nextPage);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadCodes(prevPage);
    setPage(prevPage);
  };

  return (
    <div>
      <CodesHeader />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
