import React, { useState } from 'react';
import { ForgotPasswordEntity, ResetPasswordEntity } from '../../../types/authorization';
import { forgotPasswordDefaults } from '../../../data/defaults';
import { Form } from '../../_common/Form/Form';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { UtilsValidation } from '../../../utils/UtilsValidation';

import './ForgotPasswordForm.scss';
import { PasswordValidator } from '../PasswordValidator/PasswordValidator';

interface Props {
  onEmailSubmit(email: string): void;
  onPasswordSubmit(data: ResetPasswordEntity): void;
}

export const ForgotPasswordForm: React.FC<Props> = (props) => {
  const {onEmailSubmit, onPasswordSubmit} = props;
  const [formData, setFormData] = useState<ForgotPasswordEntity>(forgotPasswordDefaults);
  const [passwordData, setPasswordData] = useState({password: '', passwordRepeat: ''});
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const {email} = formData;
  const {password, passwordRepeat} = passwordData;
  const token = UtilsNavigation.getUrlGetParamByName('token');
  const actionText = token ? 'Reset password' : 'Submit email';

  const handleFieldChange = (value: string, name: string) => {
    setFormData({...formData, [name]: value});
  };

  const handlePasswordChange = (value: string, name: string) => {
    setPasswordData({...passwordData, [name]: value});
  };

  const handlePasswordValidationChange = (state: boolean) => {
    setPasswordIsValid(state);
  };

  const handleFormSubmit = () => {
    if (token) {
      if (!password || !passwordIsValid) {
        alert('Password is incorrect');
        return;
      }

      password === passwordRepeat
        ? onPasswordSubmit({token, password})
        : alert('Repeat password value is not the same');
    } else {
      UtilsValidation.isEmailValid(email) ? onEmailSubmit(email) : alert('Email is invalid');
    }
  };

  return (
    <Form className="fp-form" onSubmit={handleFormSubmit}>
      {!token
        ? (
          <Input title="Email" placeholder="Enter your email" value={email} name="email" onChange={handleFieldChange} />
        )
        : (
          <>
            <PasswordValidator value={password} onChange={handlePasswordValidationChange} />
            <Input
              title="New Password"
              placeholder="Enter new password"
              value={password}
              name="password"
              password
              onChange={handlePasswordChange}
            />
            <Input
              title="Password Repeat"
              placeholder="Repeat new password"
              value={passwordRepeat}
              name="passwordRepeat"
              password
              onChange={handlePasswordChange}
            />
          </>
        )}
      <Button title={actionText} htmlType="submit" />
    </Form>
  )
};
