import { CommonEntityRes } from '../../types/common';
import { CustomAction } from '../index';
import { VACCINATION_ACTION_TYPES } from '../actions-types';
import {
  AdministeredVaccine,
  RegisteredVaccine,
  VaccinationCenter,
} from '../../types/vaccination';
import { VaccinationUser } from '../../types/users';

export const actionSetVacCenters = (data: CommonEntityRes<VaccinationCenter>): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_VAC_CENTERS,
    data,
  };
};

export const actionSetVacCenter = (data: VaccinationCenter): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_VAC_CENTER,
    data,
  };
};

export const actionSetVacCenterBatches = (data: VaccinationCenter): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_VAC_CENTER_BATCHES,
    data,
  };
};

export const actionSetVacCenterInoculated = (data: VaccinationCenter): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_VAC_CENTER_INOCULATED,
    data,
  };
};

export const actionSetRegisteredItems = (data: CommonEntityRes<RegisteredVaccine>): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_REGISTERED_ITEMS,
    data,
  };
};

export const actionSetAdministeredItems = (data: CommonEntityRes<AdministeredVaccine>): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_ADMINISTERED_ITEMS,
    data,
  };
};

export const actionSetVaccineUsers = (data: VaccinationUser[]): CustomAction => {
  return {
    type: VACCINATION_ACTION_TYPES.SET_VACCINES_USERS,
    data,
  };
};
