import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { Table } from '../../../../components/_common/Table/Table';
import { InvoiceHeader } from '../../../../components/invoice/InvoiceHeader/InvoiceHeader';
import {
  useInvoicesDownload,
  useInvoicesExport,
  useInvoicesRequest,
  useInvoicesTicketsExport,
} from '../../../../hooks/invoices-hooks';
import { ApplicationState } from '../../../../store';
import { InvoicesState } from '../../../../store/reducers/invoices-reducer';
import { DATE_FORMAT } from '../../../../constants/common';
import { InvoiceSearchParams } from '../../../../types/invoices';
import { CommonObject } from '../../../../types/common';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import { CommonUtils } from '../../../../utils/CommonUtils';

export const InvoicesPage: React.FC = () => {
  const { loadInvoices } = useInvoicesRequest();
  const { downloadInvoice } = useInvoicesDownload();
  const { exportInvoicesToCsv } = useInvoicesExport();
  const { exportInvoicesTicketsToCsv } = useInvoicesTicketsExport();
  const invoicesState = useSelector<ApplicationState, InvoicesState>(state => state.invoices);
  const [searchParams, setSearchParams] = useState<InvoiceSearchParams>({
    search: '',
    from: '',
    to: '',
  });
  const { invoicesItems } = invoicesState;
  const { data, limit, total, page } = invoicesItems;

  useEffect(() => {
    loadInvoices(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (params: CommonObject) => {
    loadInvoices(1, params);
  }, 400), []);

  const handleSearch = (searchValue: string) => {
    const newParams = { ...searchParams, search: searchValue };
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Host Name',
        accessor: 'host.name',
      },
      {
        Header: 'Host ID',
        accessor: 'host_id',
      },
      {
        Header: 'Band',
        accessor: 'band.name',
      },
      {
        Header: 'Tickets Issued',
        accessor: 'tickets_issued',
      },
      {
        Header: 'Invoice Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { invoice_date } = original;

          return moment(invoice_date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoice_number',
      },
      {
        Header: 'Net Value',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { net_value } = original;

          return CommonUtils.formatInvoiceNumber(net_value);
        },
      },
      {
        Header: 'Net VAT',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { net_vat } = original;

          return CommonUtils.formatInvoiceNumber(net_vat);
        },
      },
      {
        Header: 'Gross Value',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { gross_value } = original;

          return CommonUtils.formatInvoiceNumber(gross_value);
        },
      },
      {
        Header: 'View Invoice',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { id } = original;

          const handleClick = () => {
            downloadInvoice(id);
          };

          return (
            <Button title="View" onClick={handleClick}>View</Button>
          );
        },
      },
      {
        Header: 'View Schedule',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { id } = original;

          const handleClick = () => {
            exportInvoicesTicketsToCsv(id);
          };

          return (
            <Button title="View" type="empty" onClick={handleClick} />
          );
        },
      },
    ],
    [],
  );

  const handleFilterChange = (value: Date, name: string) => {
    const newParams = { ...searchParams, [name]: value };

    setSearchParams(newParams);
    loadInvoices(1, newParams);
  };

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadInvoices(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadInvoices(prevPage, searchParams);
  };

  const handleItemsExport = () => {
    exportInvoicesToCsv(searchParams);
  };

  return (
    <div>
      <InvoiceHeader
        title="View Host Invoices"
        filterData={searchParams}
        onFilterChange={handleFilterChange}
        onSearchChange={handleSearch}
        onExport={handleItemsExport}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
