import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import { Table } from '../../../../components/_common/Table/Table';
import { DATE_FORMAT } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../../../components/_common/Svg/Svg';
import { TestingHeader } from '../../../../components/testing/TestingHeader/TestingHeader';
import {
  useAdministeredTestingExport,
  useAdministeredTestingItemsRequest,
  useTestsDownload,
} from '../../../../hooks/testing-hooks';
import { ApplicationState } from '../../../../store';
import { TestingState } from '../../../../store/reducers/testing-reducer';
import { CommonObject } from '../../../../types/common';
import { TestsType } from '../../../../constants/testing';

export const TestingAdministeredPage: React.FC = () => {
  const { loadItems } = useAdministeredTestingItemsRequest();
  const { exportItemsToCsv } = useAdministeredTestingExport();
  const { downloadTest } = useTestsDownload();
  const testingState = useSelector<ApplicationState, TestingState>(state => state.testing);
  const [searchParams, setSearchParams] = useState({ search: '' });
  const { search } = searchParams;
  const { administered } = testingState;
  const { page = 1, total = 0, limit, data } = administered;

  useEffect(() => {
    loadItems(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (params: CommonObject) => {
    loadItems(1, params);
  }, 400), []);

  const handleSearch = (searchValue: string) => {
    const newParams = { ...searchParams, search: searchValue };
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const administeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date_tested } = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'AI_Result',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {ai_result} = original;

          const parseAIResult = JSON.parse(ai_result);
          console.log(parseAIResult);
          return parseAIResult ? parseAIResult?.status : null;
        },
      },
      {
        Header: 'PLN',
        accessor: 'pln'
      },
      {
        Header: 'Admin Review',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {admin_review} = original;

          return admin_review ? 'TRUE' : 'FALSE';
        },
      },
      {
        Header: 'FtF',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { ftf, id } = original;

          const handleDownload = () => {
            downloadTest(id, TestsType.FTF);
          };

          if (ftf) {
            return (
              <button className="empty-button" type="button" onClick={handleDownload}>
                <Svg icon={SVG_ICONS.DOCUMENT} />
              </button>
            );
          }

          return <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />;
        }
      },
    ],
    [],
  );

  const handleExport = () => {
    exportItemsToCsv(searchParams.search, total);
  };

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadItems(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadItems(prevPage, searchParams);
  };

  return (
    <div>
      <TestingHeader
        title="View Administered Tests Types"
        content={(
          <div className="export-wrap">
            <Input placeholder="Search items" value={search} search onChange={handleSearch} />
            <div className="export-wrap__actions">
              <div className="audit-header__export-title">Export Search to Report</div>
              <Button title="Export" onClick={handleExport} />
            </div>
          </div>
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={administeredColumns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
