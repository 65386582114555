import { ExportToCsv } from 'export-to-csv';
import moment, { Moment } from 'moment';

export const CommonUtils = {
  exportItemsToCSV: (data: any[], title: string) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: title,
      useTextFile: false,
      useBom: true,
      filename: title.toLowerCase(),
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  },
  formatInvoiceNumber: (str: string) => {
    const dotIndex = str.length - 2;

    return `£${str.substr(0, dotIndex)}.${str.substr(dotIndex)}`;
  },
  getImageUrlFromBase64(base64: string): string {
    if (!base64) return '';

    return `data:image/png;base64, ${base64}`;
  },
  getPercentageFromDec(value: any): number {
    if (value) {
      if (typeof value === 'string') value = parseFloat(value);
      return Math.round(value * 100);
    }
    return 0;
  },
  computeAge: (date: string): number => {
    const age = Math.floor(+new Date() - new Date(date).getTime()) / 3.15576e10;

    return age;
  },
  getReviewLabel(duplicates: string | null): string {
    let hasDuplicates = false;
    if (duplicates) {
      try {
        const parsedDuplicates = JSON.parse(duplicates);
        if (parsedDuplicates[0] && parsedDuplicates[0].duplicateStatus) {
          hasDuplicates = true;
        }
      } catch (e) {
        hasDuplicates = false;
      }
    }
    if (hasDuplicates) return 'Duplicate';
    else return 'Face Match';
  },
  getNotificationDateLabel: (date: Moment) => {
    // set date label (e.g. February 16th, 2022)
    let dateLabel = date.format('MMMM Do, YYYY');

    // handle yesterday/today
    if (date.isSame(moment(), 'date')) dateLabel = 'Today';
    else if (date.isSame(moment().subtract(1, 'days'), 'date')) dateLabel = 'Yesterday';

    return dateLabel;
  },
};
