import { CustomAction } from '../index';
import { AUDIT_ACTION_TYPES } from '../actions-types';
import { Audit } from '../../types/audit';
import { CommonEntityRes } from '../../types/common';

export interface AuditState {
  trailItems: CommonEntityRes<Audit>;
  trackItems: CommonEntityRes<Audit>;
  vaccinesItems: CommonEntityRes<Audit>;
  testingItems: CommonEntityRes<Audit>;
}

const defaultState: AuditState = {
  trailItems: {data: []},
  trackItems: {data: []},
  vaccinesItems: {data: []},
  testingItems: {data: []},
};

export default function auditReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case AUDIT_ACTION_TYPES.SET_TRACK_ITEMS: {
      return {
        ...state,
        trackItems: action.data,
      };
    }
    case AUDIT_ACTION_TYPES.SET_TRAIL_ITEMS: {
      return {
        ...state,
        trailItems: action.data,
      };
    }
    case AUDIT_ACTION_TYPES.SET_VACCINES_AUDIT_ITEMS: {
      return {
        ...state,
        vaccinesItems: action.data,
      };
    }
    case AUDIT_ACTION_TYPES.SET_TESTING_AUDIT_ITEMS: {
      return {
        ...state,
        vaccinesItems: action.data,
        testingItems: action.data,
      };
    }
    default:
      return state;
  }
}
