import React, { useEffect, useState } from 'react';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Button } from '../../_common/_controls/Button/Button';
import { Form } from '../../_common/Form/Form';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { TextArea } from '../../_common/_controls/TextArea/TextArea';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { VaccinationState } from '../../../store/reducers/vaccination-reducer';
import { VaccinationBroadcastFormEntity } from '../../../types/broadcast';
import { vaccineBroadcastFormEntityDefaults } from '../../../data/defaults';
import { useBatchesRequest, useBroadcastVaccinesDatesRequest, useVaccinesCodesRequest } from '../../../hooks/broadcast-hooks';
import { BroadcastState } from '../../../store/reducers/broadcast-reducer';
import { CommonState } from '../../../store/reducers/common-reducer';
import { isEmpty } from 'lodash';
import { useCommonRequest } from '../../../hooks/common-hooks';

interface Props {
  isSaved: boolean;
  onSubmit: (data: any) => void;
}

export const VaccineBroadcastForm: React.FC<Props> = (props) => {
  const { isSaved, onSubmit } = props;
  const { loadBatches } = useBatchesRequest();
  const { loadCodes } = useVaccinesCodesRequest();
  const { loadDates } = useBroadcastVaccinesDatesRequest();
  const { getApplicationsList } = useCommonRequest();
  const vaccinationState = useSelector<ApplicationState, VaccinationState>((state) => state.vaccination);
  const broadcastState = useSelector<ApplicationState, BroadcastState>((state) => state.broadcast);
  const { applicationsList } = useSelector<ApplicationState, CommonState>((state) => state.common);

  const { batches, codes, vaccinesDates } = broadcastState;
  const { centers } = vaccinationState;
  const data = centers?.data || [];
  const [formData, setFormData] = useState<VaccinationBroadcastFormEntity>(vaccineBroadcastFormEntityDefaults);
  const { centre_id, batch_id, identity_code, date, all_centres, message, application_id } = formData;

  useEffect(() => {
    if (isEmpty(applicationsList)) getApplicationsList();
  }, []);

  useEffect(() => {
    setFormData(vaccineBroadcastFormEntityDefaults);
  }, [isSaved]);

  useEffect(() => {
    if (centre_id) {
      loadBatches(centre_id);

      setFormData({
        ...formData,
        batch_id: '',
        date: '',
        identity_code: '',
      });
    }
  }, [centre_id]);

  useEffect(() => {
    if (batch_id) {
      loadCodes(centre_id, batch_id);

      setFormData({
        ...formData,
        date: '',
        identity_code: '',
      });
    }
  }, [centre_id, batch_id]);

  useEffect(() => {
    if (identity_code) {
      loadDates(centre_id, batch_id, identity_code);
    }
  }, [centre_id, batch_id, identity_code]);
  const [dataError, setDataError] = useState({
    message: 'Required Field',
    messageDirty: false,
  });

  const onBlurArea = (name: string) => {
    switch (name) {
      case 'message':
        return formData.message.length === 0 ? setDataError({ ...dataError, messageDirty: true }) : setDataError({ ...dataError, messageDirty: false });
      default:
        return setDataError({ ...dataError, messageDirty: false });
    }
  };
  const handleFieldChange = (value: any, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    if (dataError.messageDirty === true || application_id === '') {
      alert('Please check all fields');
    } else {
      onSubmit(formData);
    }
  };
  const checkMessage = formData.message.length === 0 ? true : false;
  return (
    <Form className="broadcast-form" onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={
          <FormGroup title="Broadcast" fullWidth>
            <div className="broadcast-form__group">
              <SelectMenu title="Centre ID" value={centre_id} name="centre_id" valueKey="id" labelKey="name" options={data} onChange={handleFieldChange} />
              <Checkbox checked={all_centres} name="all_centres" text="All" onChange={handleFieldChange} />
            </div>
            <SelectMenu title="Batch ID" value={batch_id} name="batch_id" valueKey="batch_id" labelKey="batch_id" options={batches} onChange={handleFieldChange} />
            <SelectMenu title="Identity Code" value={identity_code} name="identity_code" valueKey="identity_code" labelKey="identity_code" options={codes} onChange={handleFieldChange} />
            <SelectMenu title="Date Administered" value={date} name="date" valueKey="date" labelKey="label" options={vaccinesDates} onChange={handleFieldChange} />
          </FormGroup>
        }
        rightContent={
          <FormGroup fullWidth>
            <SelectMenu title="Application" value={application_id} name="application_id" labelKey="name" valueKey="id" options={applicationsList} onChange={handleFieldChange} />
            <TextArea
              error={dataError.message && dataError.messageDirty ? true : false}
              onBlur={onBlurArea}
              title={dataError.message && dataError.messageDirty ? `Message-${dataError.message}` : 'Message'}
              placeholder="Message"
              value={message}
              onChange={handleFieldChange}
              name="message"
            />
          </FormGroup>
        }
        footer={<Button disabled={checkMessage} title="Create" htmlType="submit" />}
      />
    </Form>
  );
};
