import React from 'react';
import Collapsible from 'react-collapsible';
import { Svg, SVG_ICONS } from '../Svg/Svg';

import './CollapsePanel.scss';

interface Props {
  title: string;
}

export const CollapsePanel: React.FC<Props> = (props) => {
  const {title, children} = props;

  return (
    <div className="collapse-pane">
      <Collapsible
        trigger={(
          <div className="collapse-pane__head">
            {title}
            <div className="collapse-pane__icon">
              <Svg icon={SVG_ICONS.CHEVRON_DOWN} />
            </div>
          </div>
        )}
      >
        {children}
      </Collapsible>
    </div>
  );
};
