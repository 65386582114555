import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Key, useEffect, useRef } from 'react';
import { Bars } from 'react-loader-spinner';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../configs/routes';
import { NotificationTypes } from '../../../constants/common';

import { URLS } from '../../../constants/urls';
import { AdminPermissionsNames } from '../../../constants/users';
import { useNotificationsCount, useNotificationsList, viewNotification } from '../../../hooks/notification-hooks';
import { useUserPermissions } from '../../../hooks/user-hooks';
import { NotificationEntity } from '../../../types/common';
import { CommonUtils } from '../../../utils/CommonUtils';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';
import './NotificationsPanel.scss';

interface NotificationProps {
  notification: NotificationEntity;
  unread?: boolean;
  hidePanel: () => void;
  refetch: () => void;
  withDivider?: boolean;
}

interface NotificationsPanelProps {
  hidePanel: () => void;
}

export const NotificationsPanel: React.FC<NotificationsPanelProps> = ({ hidePanel }) => {
  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useNotificationsList();
  const { refetch: refetchCount } = useNotificationsCount();

  // To close notification panel when clicking outside
  const ref = useRef();
  useOnClickOutside(ref, () => hidePanel());

  // Combine page results into single results list
  let resultsList =
    !data || _.isEmpty(data?.pages)
      ? []
      : _.reduce(data.pages, (prev: any, curr) => (_.isEmpty(prev) ? curr.results : prev.concat(curr.results)), []);

  const getContent = () => {
    // function to format date for grouping(by date)
    const dateName = (item) => moment(item.created_at, 'YYYY-MM-DD').format('YYYY-MMM-DD');

    // Group notifications by day
    // NOTE: Uses lodash chaining
    const groupedNotifications = _(resultsList)
      .orderBy((i) => i.created_at, 'desc')
      .groupBy(dateName)
      .toArray();

    const triggerRefetch = () => {
      refetch();
      refetchCount();
    };

    return (
      <div className="body">
        {groupedNotifications
          .map((date, groupIndex: Key) => {
            // Get date for group
            const currDate = moment(date[0].created_at, 'YYYY-MM-DD');
            const dateLabel = CommonUtils.getNotificationDateLabel(currDate);
            return (
              <section
                key={groupIndex}
                className={classNames('list-section', {
                  'list-section--divider': groupIndex < groupedNotifications.size() - 1,
                })}
              >
                <div className="list-section-header">{dateLabel}</div>
                {date.map((notif: NotificationEntity, notifIndex: Key) => (
                  <Notification
                    key={notifIndex}
                    notification={notif}
                    unread={!notif.is_read}
                    refetch={triggerRefetch}
                    hidePanel={hidePanel}
                    withDivider={notifIndex < date.length - 1}
                  />
                ))}
              </section>
            );
          })
          .value()}
        <button className="loadmore" onClick={() => fetchNextPage()} disabled={!hasNextPage || isFetchingNextPage}>
          {isFetchingNextPage ? 'Loading...' : hasNextPage ? 'Load more' : 'Nothing more to load'}
        </button>
      </div>
    );
  };

  return (
    <div ref={ref} className="notifications-panel">
      <div className="notifications-panel__arrow" />
      <div className="notifications-panel__container">
        <div className="header">
          <Svg icon={SVG_ICONS.BELL_FILL} />
          <h5>NOTIFICATIONS</h5>
        </div>
        {isLoading || isError ? (
          <div className="loading__container">
            <Bars height="30" width="30" color="#463da3" ariaLabel="loading" />
          </div>
        ) : (
          getContent()
        )}
      </div>
    </div>
  );
};

const Notification: React.FC<NotificationProps> = ({ notification, unread = false, hidePanel, withDivider, refetch }) => {
  const mfpId = notification?.property?.mfp_id || '';
  const { hasPermissions } = useUserPermissions();
  const history = useHistory();

  const handleView = async () => {
    hidePanel();
    if (hasPermissions(AdminPermissionsNames.Users)) {
      history.push(`${URLS.USER}/${mfpId}`);
    } else {
      toast.warn('Restricted access.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const res = await viewNotification(notification.id);
    if (res) refetch();
  };

  return (
    <button
      className={classNames('notification', { unread: unread, 'notification--divider': withDivider })}
      onClick={handleView}
    >
      <div className="notification-icon">
        <Svg icon={unread ? SVG_ICONS.NOTIFICATION_ACTIVE : SVG_ICONS.NOTIFICATION} size={25} />
      </div>
      <div className="notification-content">
        <h4>
          {notification.notification_type === NotificationTypes.USER_REVIEW
            ? 'User Account Application for Review'
            : 'Lateral Flow Test'}
        </h4>
        <p>{notification.message}</p>
      </div>
      <div className="notification-time">{moment(notification.created_at).format('h:mm')}</div>
    </button>
  );
};

// Hook
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
