import React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import './Svg.scss';

import User from '../../../assets/images/svg/user.svg';
import Users from '../../../assets/images/svg/users.svg';
import ChevronRight from '../../../assets/images/svg/chevron-right.svg';
import ChevronLeft from '../../../assets/images/svg/chevron-left.svg';
import ChevronDown from '../../../assets/images/svg/chevron-down.svg';
import Comment from '../../../assets/images/svg/comment.svg';
import Book from '../../../assets/images/svg/book.svg';
import Ticket from '../../../assets/images/svg/ticket.svg';
import Cloud from '../../../assets/images/svg/cloud.svg';
import Globe from '../../../assets/images/svg/globe.svg';
import Logout from '../../../assets/images/svg/logout.svg';
import CheckCircle from '../../../assets/images/svg/check-circle.svg';
import Document from '../../../assets/images/svg/text-document.svg';
import CloseCircle from '../../../assets/images/svg/close-circle.svg';
import Close from '../../../assets/images/svg/close.svg';
import Test from '../../../assets/images/svg/test.svg';
import ZoomIn from '../../../assets/images/svg/zoom-in.svg';
import Edit from '../../../assets/images/svg/edit.svg';
import ClockPending from '../../../assets/images/svg/clock-pending.svg';
import ClockDone from '../../../assets/images/svg/clock-done.svg';
import Bell from '../../../assets/images/svg/bell.svg';
import BellFill from '../../../assets/images/svg/bell-fill.svg';
import Notification from '../../../assets/images/svg/notification.svg';
import NotificationActive from '../../../assets/images/svg/notification-active.svg';
import NotificationWhite from '../../../assets/images/svg/notification-white.svg';

export const SVG_ICONS = {
  USER: User,
  USERS: Users,
  CHEVRON_RIGHT: ChevronRight,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_DOWN: ChevronDown,
  COMMENT: Comment,
  BOOK: Book,
  TICKET: Ticket,
  CLOUD: Cloud,
  GLOBE: Globe,
  LOGOUT: Logout,
  CHECK_CIRCLE: CheckCircle,
  DOCUMENT: Document,
  CLOSE_CIRCLE: CloseCircle,
  TEST: Test,
  ZOOM_IN: ZoomIn,
  EDIT: Edit,
  CLOSE: Close,
  CLOCK_PENDING: ClockPending,
  CLOCK_DONE: ClockDone,
  BELL: Bell,
  BELL_FILL: BellFill,
  NOTIFICATION: Notification,
  NOTIFICATION_ACTIVE: NotificationActive,
  NOTIFICATION_WHITE: NotificationWhite,
};

interface Props {
  icon: string;
  className?: string;
  size?: number;
}

export const Svg: React.FC<Props> = (props) => {
  const { icon, className = '', size = 20 } = props;
  const wrapClass = classNames('svg', { [className]: !!className });
  const style = {
    width: size,
    height: size,
  };

  return (
    <ReactSVG src={icon} wrapper="span" style={style} className={wrapClass} />
  );
};
