import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../../components/_common/PageHeader/PageHeader';
import { ROUTES } from '../../../../configs/routes';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { useUsersRequest } from '../../../../hooks/users-hooks';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { DATE_FORMAT } from '../../../../constants/common';
import { CommonUtils } from '../../../../utils/CommonUtils';

export const VerificationUsersPage: React.FC = () => {
  const { loadAppVerificationUsers } = useUsersRequest();
  const { verificationUsers } = useSelector<ApplicationState, UsersState>(
    (state) => state.users
  );
  const { data, total, limit } = verificationUsers;
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadAppVerificationUsers(page);
  }, []);

  const pageSections = [
    {
      route: ROUTES.USERS,
      name: 'View Current App Users',
    },
    {
      route: ROUTES.MFP_USERS,
      name: 'View MFP Users',
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Registraion Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { created_at } = original;
          return moment(created_at).format('YYYY-MM-DD');
        },
      },
      {
        Header: 'Review Type',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { duplicates } = original;
          return CommonUtils.getReviewLabel(duplicates); // TODO: Display review type
        },
      },
      // {
      //   Header: "FMC Score",
      //   accessor: "fmc_score",
      // },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { id } = original;
          const url = `${URLS.USER}/${id}`;

          return <Link to={url}>View details</Link>;
        },
      },
    ],
    []
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadAppVerificationUsers(nextPage);
    setPage(nextPage);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadAppVerificationUsers(prevPage);
    setPage(prevPage);
  };

  return (
    <div>
      <UsersHeader title="For Admin Verification" content={<div />} />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
