import { URLS } from '../constants/urls';

export const ROUTES = {
  ROOT: URLS.ROOT,
  SIGN_IN: URLS.SIGN_IN,
  SIGN_IN_2FA: URLS.SIGN_IN_2FA,
  FORGOT_PASSWORD: URLS.FORGOT_PASSWORD,
  HOSTS: URLS.HOSTS,
  PLACEHOLDER: URLS.PLACEHOLDER,
  HOST_CREATE: URLS.HOST_CREATE,
  CODES: URLS.CODES,
  CODES_CREATE: `${URLS.CODES_CREATE}/${URLS.PARAMS.ID_OPTIONAL}`,
  INVOICE: URLS.INVOICE,
  INVOICE_CREATE: `${URLS.INVOICE}${URLS.CREATE}`,
  INVOICE_BAND: `${URLS.INVOICE}${URLS.BAND}`,
  HOST: `${URLS.HOST}/${URLS.PARAMS.ID}`,
  USERS: URLS.USERS,
  FOR_VERIFICATION: URLS.FOR_VERIFICATION,
  VACCINATION_USERS: URLS.VACCINATION_USERS,
  VACCINATION_USER: `${URLS.VACCINATION_USER}/${URLS.PARAMS.ID}`,
  TESTING_USERS: URLS.TESTING_USERS,
  TESTING_USER: `${URLS.TESTING_USER}/${URLS.PARAMS.ID}`,
  BROADCAST_MESSAGE: URLS.BROADCAST_MESSAGE,
  USER: `${URLS.USER}/${URLS.PARAMS.ID}`,
  MFP_USERS: URLS.MFP_USERS,
  MFP_USER: `${URLS.MFP_USER}/${URLS.PARAMS.ID_OPTIONAL}`,
  AUDIT_TRAIL: `${URLS.AUDIT}${URLS.TRAIL}`,
  AUDIT_TRACK: `${URLS.AUDIT}${URLS.TRACK}`,
  AUDIT_VACCINATION: `${URLS.AUDIT}${URLS.VACCINE}`,
  AUDIT_TESTING: `${URLS.AUDIT}${URLS.TESTING}`,
  VACCINATION_CENTERS: `${URLS.VACCINATION}${URLS.CENTERS}`,
  VACCINATION_CENTER: `${URLS.VACCINATION}${URLS.CENTER}/${URLS.PARAMS.ID}`,
  VACCINATION_CENTER_CREATE: `${URLS.VACCINATION}${URLS.CREATE}`,
  VACCINATION_BATCHES: `${URLS.VACCINATION}${URLS.BATCHES}`,
  VACCINATION_ADMINISTERED: `${URLS.VACCINATION}${URLS.ADMINISTERED}`,
  TESTING_CENTERS: `${URLS.TESTING}${URLS.CENTERS}`,
  TESTING_CENTER: `${URLS.TESTING}${URLS.CENTER}/${URLS.PARAMS.ID}`,
  TESTING_CENTER_CREATE: `${URLS.TESTING}${URLS.CREATE}`,
  TESTING_REGISTERED: `${URLS.TESTING}${URLS.REGISTERED}`,
  TESTING_ADMINISTERED: `${URLS.TESTING}${URLS.ADMINISTERED}`,
};
