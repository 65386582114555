import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import './SelectMenu.scss';

interface Props {
  onBlur?: any;
  error?: boolean;
  errorMessage?: string;
  value: string | boolean;
  options: any[];
  title?: string;
  name?: string;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  onChange(value: string, name?: string): void;
  menuPlacement?: 'bottom' | 'top';
  disableSearch?: boolean;
}

export const SelectMenu: React.FC<Props> = (props) => {
  const { title, value, name, placeholder, onBlur,
    error, options, disabled, isClearable, labelKey = 'label', valueKey = 'value', onChange, menuPlacement = 'bottom', errorMessage = '', disableSearch = false } = props;
  const selectedOption = options.find((option) => value === option[valueKey]) || '';

  const wrapperClass = classNames('select-menu', {
    'select-menu__error': !!errorMessage,
  })

  const handleChange = (option: any) => {
    const newValue = option ? option[valueKey] : '';

    onChange(newValue, name);
  };
  const checkError = error ? {color: 'red'} : {color: 'black'}
  return (
    <div className={wrapperClass}>
      {title && <div style={checkError} className="select-menu__title">{title}</div>}
      <Select
        onBlur={onBlur}
        className="select-menu__field"
        classNamePrefix="select-menu"
        value={selectedOption}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        isClearable={isClearable}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
        menuPlacement={menuPlacement}
        isSearchable={!disableSearch}
      />
      { errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};
