import React from 'react';
import moment from 'moment';
import { Table } from '../../_common/Table/Table';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/common';
import { useTestsDownload } from '../../../hooks/testing-hooks';
import { TestsType } from '../../../constants/testing';
import { CommonEntityRes } from '../../../types/common';

import './UserTestingDetails.scss';

interface Props {
  data: CommonEntityRes<any>
}

export const UserTestingDetails: React.FC<Props> = (props) => {
  const { downloadTest } = useTestsDownload();
  const { data } = props;
  const { data: items } = data || {};

  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date_tested } = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Time Tested',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date_tested } = original;

          return moment(date_tested).format(TIME_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'FtF',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { ftf, id } = original;

          const handleDownload = () => {
            downloadTest(id, TestsType.FTF);
          };

          if (ftf) {
            return (
              <button className="empty-button" type="button" onClick={handleDownload}>
                <Svg icon={SVG_ICONS.DOCUMENT} />
              </button>
            );
          }

          return <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />;
        }
      },
    ],
    [],
  );

  return (
    <div className="ut-details">
      <h2 className="ut-details__title">Testing</h2>
      <div className="ut-details__table">
        <Table data={items} columns={columns} />
      </div>
    </div>
  );
};
