export enum VACCINE_STATUS {
  REQUIRED = 'REQUIRED',
  NOT_REQUIRED_WITH_TEST_RESULT = 'NOT_REQUIRED_WITH_TEST_RESULT',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export const VaccineStatus = {
  [VACCINE_STATUS.REQUIRED]: 'Always required',
  [VACCINE_STATUS.NOT_REQUIRED_WITH_TEST_RESULT]:
    'Not required when Test Result is satisfactory',
  [VACCINE_STATUS.NOT_REQUIRED]: 'Not required',
};

export enum VACCINE_DOSES {
  NOT_REQUIRED = 'NOT_REQUIRED',
  PARTIAL = 'PARTIAL',
  COMPLETED = 'COMPLETED',
  BOOSTER = 'BOOSTER',
}

export const TempVaccineDoses = {
  [0]: VACCINE_DOSES.NOT_REQUIRED,
  [1]: VACCINE_DOSES.PARTIAL,
  [2]: VACCINE_DOSES.COMPLETED,
  [3]: VACCINE_DOSES.BOOSTER,
};

export const VaccineDoses = {
  [VACCINE_DOSES.NOT_REQUIRED]: 'Any',
  [VACCINE_DOSES.PARTIAL]: 'Single',
  [VACCINE_DOSES.COMPLETED]: 'Double',
  [VACCINE_DOSES.BOOSTER]: 'Booster',
};
