import { CustomAction } from "..";
import { ITwoFactor } from "../../types/login";
import { LOGIN_ACTION_TYPES } from "../actions-types";

export const actionSetLogin = (data: ITwoFactor): CustomAction => {
  return {
    type: LOGIN_ACTION_TYPES.SET_LOGIN_2FA,
    data,
  };
};
