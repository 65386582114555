import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NotificationSettingItems, NOTIF_SETTING_TYPES } from '../../../constants/users';
import { useAppUserNotificationSettings } from '../../../hooks/users-hooks';
import { ApplicationState } from '../../../store';
import { CommonState } from '../../../store/reducers/common-reducer';
import { AppUserAppNotificationSettings } from '../../../types/users';
import { UtilsUser } from '../../../utils/UtilsUser';

import { Form } from '../../_common/Form/Form';
import { Button } from '../../_common/_controls/Button/Button';
import { CheckboxGroup, RadioGroup } from '../../_common/_controls/Checkbox/Checkbox';
import { Field } from '../../_common/_controls/Field/Field';
import './UserNotificationSettings.scss';

interface Props {
  id: string;
}

export const UserNotificationSettings: React.FC<Props> = (props) => {
  // Base Data
  const { id } = props;
  const { applicationsList } = useSelector<ApplicationState, CommonState>((state) => state.common);
  const [notificationSettings, setNotificationSettings] = useState<Array<AppUserAppNotificationSettings>>(null);

  // hooks
  const { getUserNotificationSettings, updateUserNotificationSettings } = useAppUserNotificationSettings();

  // Form Handling
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, reset, control, setValue } = useForm({
    defaultValues: useMemo(() => {
      return UtilsUser.createUserNotificationSettingsForm(notificationSettings);
    }, [notificationSettings]),
  });

  useEffect(() => {
    reset(UtilsUser.createUserNotificationSettingsForm(notificationSettings));
  }, [notificationSettings]);

  const notificationSettingOptions = [
    {
      label: NotificationSettingItems[NOTIF_SETTING_TYPES.NONE],
      value: NOTIF_SETTING_TYPES.NONE,
    },
    {
      label: NotificationSettingItems[NOTIF_SETTING_TYPES.CUSTOM],
      value: NOTIF_SETTING_TYPES.CUSTOM,
    },
    {
      label: NotificationSettingItems[NOTIF_SETTING_TYPES.ALL_APPLICATION],
      value: NOTIF_SETTING_TYPES.ALL_APPLICATION,
    },
  ];

  const notifSettingValue = useWatch({
    control,
    name: 'notification_setting',
  });

  useEffect(() => {
    switch (notifSettingValue) {
      case NOTIF_SETTING_TYPES.CUSTOM: {
        setIsRequired(true);
        break;
      }
      case NOTIF_SETTING_TYPES.ALL_APPLICATION: {
        setIsRequired(false);
        setValue(
          'applications',
          notificationSettings.map((x) => x.application_id)
        );
        break;
      }
      case NOTIF_SETTING_TYPES.NONE: {
        setIsRequired(false);
        setValue('applications', []);
        break;
      }
    }
  }, [notifSettingValue]);

  // Effects
  useEffect(() => {
    if (id) fetchUserNotificationSettings();
  }, [id]);

  // Methods/Functions
  const fetchUserNotificationSettings = async () => {
    const userNotifRes = await getUserNotificationSettings(id);

    if (userNotifRes && !isEmpty(userNotifRes)) {
      userNotifRes.filter((app) => !isEmpty(app));

      setNotificationSettings(userNotifRes);
    } else {
      setNotificationSettings(null);
    }
  };

  const createApplicationOptions = () => {
    if (!applicationsList || isEmpty(applicationsList)) return [];
    return applicationsList.map((app) => {
      return {
        label: app.name,
        value: app.id,
      };
    });
  };

  const onSaveSettings = async (formValues) => {
    const body = UtilsUser.createUserNotficationSettingsRequestBody(formValues.applications, notificationSettings);
    const res = await updateUserNotificationSettings(id, body);

    if (res) {
      fetchUserNotificationSettings();
      toast.success('Notification is successfully updated.', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="notification-settings">
      <h2 className="notification-settings__title">Notifications</h2>
      <div className="notification-settings__header">{/* <div className="title">Notified Application</div> */}</div>
      <div className="notification-settings__content">
        <Form onSubmit={handleSubmit(onSaveSettings)}>
          <div className="column-header">MOBILE APPLICATIONS</div>
          <div className="singular-col">
            <div className="main-column">
              <Field control={control} name="notification_setting" component={RadioGroup} options={notificationSettingOptions} labelKey="label" valueKey="value" />
            </div>
            <div className={classNames('sub-column', { disabled: !isRequired })}>
              <Field control={control} name="applications" component={CheckboxGroup} options={createApplicationOptions()} labelKey="label" valueKey="value" rules={{ required: isRequired }} disabled={!isRequired} />
            </div>
          </div>
        </Form>
      </div>
      <div className="notification-settings__save">
        <Button variant="solid" type="purple" title="Save" onClick={handleSubmit(onSaveSettings)} />
      </div>
      <ToastContainer theme="colored" position="bottom-left" autoClose={false} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};
