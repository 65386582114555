import React, { useState } from 'react';
import { VaccinationCenterForm } from '../../../../components/vaccination/VaccinationCenterForm/VaccinationCenterForm';
import { VaccinationHeader } from '../../../../components/vaccination/VaccinationHeader/VaccinationHeader';
import { useVacCenterCreate } from '../../../../hooks/vaccination-hooks';
import { VaccinationCenterCreateFormEntity } from '../../../../types/vaccination';

export const VaccinationCenterCreatePage: React.FC = () => {
  const {createCenter} = useVacCenterCreate();
  const [isSaved, setIsSaved] = useState(false);

  const handleCenterCreate = async (data: VaccinationCenterCreateFormEntity) => {
    setIsSaved(false);

    const isCenterSaved = await createCenter(data);

    if (isCenterSaved) {
      setIsSaved(true);
    }
  };

  return (
    <div className="page-content">
      <VaccinationHeader title="Add a Vaccination Centre" />
      <VaccinationCenterForm isNew isSaved={isSaved} onSubmit={handleCenterCreate} />
    </div>
  );
};
