import React from 'react';
import logo from '../../assets/images/svg/logo.svg';
import { Svg, SVG_ICONS } from '../_common/Svg/Svg';
import { NavMenuItem } from '../_common/NavMenuItem/NavMenuItem';
import { useUserLogin } from '../../hooks/user-hooks';

import './Header.scss';
import { Notifications } from '../Notifications/Notifications';

export const Header: React.FC = () => {
  const { logout } = useUserLogin();

  const handleLogout = () => {
    logout();
  };

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__logo">
          <div className="header__logo-icon">
            <img src={logo} alt="logo" />
          </div>
          <div className="header__logo-text">Match Fit Pass - Admin Dashboard</div>
        </div>
        <div className="header__actions">
          <Notifications />
          <NavMenuItem icon={SVG_ICONS.LOGOUT} title="Logout" isActive onClick={handleLogout} />
        </div>
      </div>
    </header>
  );
};
