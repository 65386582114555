import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { userDefaults } from '../../data/defaults';
import { User } from '../../types/user';

export interface UserState {
  userData: User;
}

const defaultState: UserState = {
  userData: userDefaults,
};

export default function userReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER: {
      return {
        ...state,
        userData: action.data,
      };
    }
    default:
      return state;
  }
}
