import React, { useState } from 'react';

import './FilePicker.scss';

interface Props {
  title: string;
  name?: string;
  onChange(file: File, name: string): void;
}

export const FilePicker: React.FC<Props> = (props) => {
  const {title, name, onChange} = props;
  const [fileName, setFileName] = useState('');
  const actionText = fileName ? 'Change' : 'Upload';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    setFileName(file.name);
    onChange(file, name);
  };

  return (
    <div className="file-picker">
      {title && <div className="file-picker__title">{title}</div>}
      <div className="file-picker__wrap">
        <label className="file-picker__btn">
          <span>{actionText}</span>
          <input type="file" onChange={handleChange} />
        </label>
        <div className="file-picker__name">{fileName}</div>
      </div>
    </div>
  );
};
