import { ForgotPasswordEntity, SignInEntity } from '../types/authorization';
import { PaymentEntity } from '../types/payment';
import { Host, HostUserEditFormEntity, HostUserFormEntity } from '../types/hosts';
import { AdminFormEntity, AdminUser, AppUser, AppUserGuardian, VaccinationUser, VaccinationUserFull } from '../types/users';
import { VaccinationCenter, VaccinationCenterCreateFormEntity, VaccinationCenterUser, VaccineUserFormEntity } from '../types/vaccination';
import { Band, CreateBandData, CreateInvoiceData } from '../types/invoices';
import { Code, CodeDetails } from '../types/codes';
import { BroadcastCreate, TestingBroadcastFormEntity, VaccinationBroadcastFormEntity } from '../types/broadcast';
import { User } from '../types/user';
import { TestingCenter, TestingCenterCreateFormEntity, TestingDoctorCreateFormEntity, TestingUserFormEntity, TestingUserFull } from '../types/testing';

export const userDefaults: User = {
  id: '',
  name: '',
  created_at: '',
  updated_at: '',
  phone: '',
  host: null,
  permissions: [],
  role: {
    level: 0,
    created_at: '',
    id: '',
    name: '',
    updated_at: '',
  },
};

export const hostDefaults: Host = {
  id: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  email: '',
  phone: '',
  key_contact: '',
  invoice_date: '',
  band_id: '',
  postcode: '',
};

export const signInDefaults: SignInEntity = {
  login: '',
  password: '',
};

export const paymentDefaults: PaymentEntity = {
  cardNumber: '',
  expiryDate: '',
  cvcCode: '',
};

export const appUserDefaults: AppUser = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  created_at: '',
  data_sharing_agreed: false,
  dob: '',
  email: '',
  guardian_info_id: '',
  id: '',
  name: '',
  phone: '',
  postcode: '',
  registration_status: '',
  mobile_registration_status: '',
  updated_at: '',
  last_active_at: '',
  vaccination: {
    batch_number: '',
    centre: {
      id: '',
      name: '',
    },
    date_inoculated: '',
    identity_code: '',
    mfp_user: false,
    vaccine_centre: false,
    vaccine_check: false,
  },
  used_code: {
    app_user_id: '',
    code: '',
    created_at: '',
    updated_at: '',
    id: '',
  },
  journey_id: '',
  fmc_score: 0,
};

export const appUserGuardian: AppUserGuardian = {
  first_name: '',
  last_name: '',
  name: '',
  dob: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  postcode: '',
  state: '',
};

export const adminUserDefaults: AdminUser = {
  created_at: '',
  email: '',
  id: '',
  name: '',
  password: '',
  phone: '',
  role: {
    created_at: '',
    id: '',
    level: 0,
    name: '',
    updated_at: '',
  },
  role_id: '',
  updated_at: '',
  permissions: [],
  mfp_id: '',
};

export const adminUserFormEntityDefaults: AdminFormEntity = {
  mfp_id: '',
  Users: false,
  Invoice: false,
  Audit: false,
  Hosts: false,
  Codes: false,
  Vaccination: false,
  BroadcastMessage: false,
  CovidTesting: false,
};

export const hostFormEntityDefaults: HostUserFormEntity = {
  mfp_id: '',
  Tickets: false,
  Invoice: false,
  Audit: false,
  SubUserManagement: false,
  BroadcastMessage: false,
  AccountDetails: false,
};

export const hostUserEditFormEntityDefaults: HostUserEditFormEntity = {
  id: '',
  Tickets: false,
  AccountDetails: false,
  Invoice: false,
  Audit: false,
  BroadcastMessage: false,
  SubUserManagement: false,
};

export const invoiceCreateDefaults: CreateInvoiceData = {
  host_id: '',
  start: '',
  end: '',
};

export const bandCreateDefaults: CreateBandData = {
  annual_charge: '',
  name: '',
  price_per_ticket: '',
  venue_size: '',
};

export const bandDefaults: Band = {
  annual_charge: 0,
  created_at: '',
  id: '',
  name: '',
  price_per_ticket: 0,
  updated_at: '',
  venue_size: 0,
};

export const codeCreateDefaults: CodeDetails = {
  assigned_to_host_id: '',
  voucher_code: '',
  voucher_name: '',
  expiration_date: '',
  one_time_usage: false,
  max_codes: 0,
};

export const codeDefaults: Code = {
  allHosts: false,
  assigned_to_host: hostDefaults,
  created_at: '',
  assigned_to_host_id: '',
  expiration_date: '',
  id: '',
  one_time_usage: false,
  updated_at: '',
  users_count: 0,
  max_codes: 0,
  voucher_code: '',
  voucher_name: '',
};

export const broadcastCreateDefaults: BroadcastCreate = {
  event_id: '',
  host_id: '',
  section: '',
  message: '',
  application_id: '',
};

export const forgotPasswordDefaults: ForgotPasswordEntity = {
  email: '',
};

export const vaccineFormEntityDefaults: VaccineUserFormEntity = {
  mfp_id: '',
  BroadcastMessage: false,
  SubUserManagement: false,
  RegisteredVaccines: false,
  AdministeredVaccines: false,
  CentreDetails: false,
  GenerateQRVaccine: false,
};

export const vaccineBroadcastFormEntityDefaults: VaccinationBroadcastFormEntity = {
  centre_id: '',
  all_centres: false,
  batch_id: '',
  date: '',
  identity_code: '',
  message: '',
  application_id: '',
};

export const testingBroadcastFormEntityDefaults: TestingBroadcastFormEntity = {
  centre_id: '',
  all_centres: false,
  lot_no: '',
  type: '',
  date: '',
  identity_code: '',
  message: '',
  application_id: '',
};

export const vaccinationCenterUser: VaccinationCenterUser = {
  mfp_id: '',
  permissions: [],
};

export const vaccinationCenterCreateDefaults: VaccinationCenterCreateFormEntity = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
};

export const vaccinationUserDefault: VaccinationUser = {
  phone: '',
  name: '',
  id: '',
  email: '',
  centre_name: '',
};

export const vaccinationCenterDefault: VaccinationCenter = {
  address1: '',
  address2: '',
  city: '',
  created_at: '',
  email: '',
  id: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  updated_at: '',
};

export const vaccinationUserFullDefault: VaccinationUserFull = {
  phone: '',
  name: '',
  id: '',
  email: '',
  created_at: '',
  host: hostDefaults,
  mfp_id: '',
  permissions: [],
  updated_at: '',
};

export const testingUserFullDefault: TestingUserFull = {
  phone: '',
  name: '',
  id: '',
  email: '',
  created_at: '',
  host: hostDefaults,
  mfp_id: '',
  permissions: [],
  updated_at: '',
};

export const testingCenterCreateDefaults: TestingCenterCreateFormEntity = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  is_pln_required: false,
  parent_host_id: '',
  is_lft_ai_used: false,
};

export const testingDoctorCreateDefaults: TestingDoctorCreateFormEntity = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  signature: undefined,
};

export const testingFormEntityDefaults: TestingUserFormEntity = {
  mfp_id: '',
  BroadcastMessage: false,
  SubUserManagement: false,
  RegisteredTestTypes: false,
  AdministeredTestTypes: false,
  CentreDetails: false,
};

export const testingCenterDefault: TestingCenter = {
  address1: '',
  address2: '',
  city: '',
  created_at: '',
  email: '',
  id: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  updated_at: '',
  is_pln_required: false,
  parent_host_id: '',
  is_lft_ai_used: false,
};
