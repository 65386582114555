import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { AuditHeader } from '../../../../components/audit/AuditHeader/AuditHeader';
import { useAuditExport, useAuditTrailRequest } from '../../../../hooks/audit-hooks';
import { ApplicationState } from '../../../../store';
import { AuditState } from '../../../../store/reducers/audit-reducer';
import { DATE_FORMAT } from '../../../../constants/common';

export const AuditTrailPage: React.FC = () => {
  const {loadTrailItems} = useAuditTrailRequest();
  const {exportAuditItemsToCsv} = useAuditExport();
  const auditState = useSelector<ApplicationState, AuditState>(state => state.audit);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {trailItems} = auditState;
  const {data, page, limit, total} = trailItems;

  useEffect(() => {
    loadTrailItems(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (value: string) => {
    const newParams = {search: value};

    loadTrailItems(1, newParams);
    setSearchParams(newParams);
  }, 400), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ticket ID',
        accessor: 'ticket_id',
      },
      {
        Header: 'Name',
        accessor: 'host_name',
      },
      {
        Header: 'Phone Number',
        accessor: 'host_phone',
      },
      {
        Header: 'Email Address',
        accessor: 'host_email',
      },
      {
        Header: 'Row + Seat',
        accessor: 'seat_number',
      },
      {
        Header: 'Section',
        accessor: 'block_number',
      },
      {
        Header: 'Event',
        accessor: 'event_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Response',
        accessor: 'response',
      },
      {
        Header: 'Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {user_id} = original;
          const url = `${URLS.USER}/${user_id}`;

          return (
            <Link to={url}>View</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    loadTrailItems(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    loadTrailItems(prevPage, searchParams);
  };

  const exportItems = () => {
    exportAuditItemsToCsv(searchParams.search, total);
  };

  return (
    <div className="audit">
      <AuditHeader title="View Ticket Trail" onExport={exportItems} onSearch={searchForItems} />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
