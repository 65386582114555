import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import './Autocomplete.scss';

interface Props {
  title?: string;
  placeholder?: string;
  nameKey: string;
  items: any[];
  onChange(value: string): void;
  onSelect(item: any): void;
  selectedValue: string;
}

export const Autocomplete: React.FC<Props> = (props) => {
  const {
    items,
    title,
    nameKey,
    placeholder,
    onSelect,
    onChange,
    selectedValue,
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setSuggestions(getSuggestions(value));
  }, [value, items.length]);

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : items.filter(item =>
      item[nameKey].toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  const handleInputChange = (e, { newValue }) => {
    onChange(newValue);
    setValue(newValue);
  };

  const inputProps = {
    placeholder: placeholder,
    value: value,
    onChange: handleInputChange
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSelect = (e, data) => {
    onSelect(data.suggestion);
  };

  const getSuggestionValue = suggestion => suggestion[nameKey];

  const renderSuggestion = suggestion => (
    <div>
      {suggestion[nameKey]}
    </div>
  );

  return (
    <div className="autocomplete">
      {title && <div className="autocomplete__title">{title}</div>}
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={handleSelect}
      />
    </div>
  );
};
