import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { ROUTES } from './configs/routes';
import { auth, notAuth } from './services/auth';

import { Layout } from './components/Layout/Layout';
import { SignInPage } from './pages/nonauth/SignInPage/SignInPage';
import { UsersPage } from './pages/auth/users/UsersPage/UsersPage';
import { UserPage } from './pages/auth/users/UserPage/UserPage';
import { VerificationUsersPage } from './pages/auth/users/VerificationUsersPage/VerificationUsersPage';
import { MfpUsersPage } from './pages/auth/users/MfpUsersPage/MfpUsersPage';
import { MfpUserPage } from './pages/auth/users/MfpUserPage/MfpUserPage';
import { HostsPage } from './pages/auth/hosts/HostsPage/HostsPage';
import { HostCreatePage } from './pages/auth/hosts/HostCreatePage/HostCreatePage';
import { HostPage } from './pages/auth/hosts/HostPage/HostPage';
import { AuditTrailPage } from './pages/auth/audit/AuditTrailPage/AuditTrailPage';
import { AuditTrackPage } from './pages/auth/audit/AuditTrackPage/AuditTrackPage';
import { BroadcastMessagePage } from './pages/auth/BroadcastMessagePage/BroadcastMessagePage';
import { CodesPage } from './pages/auth/codes/CodesPage/CodesPage';
import { CodePage } from './pages/auth/codes/CodePage/CodePage';
import { InvoicesPage } from './pages/auth/invoice/InvoicesPage/InvoicesPage';
import { InvoiceCreatePage } from './pages/auth/invoice/InvoiceCreatePage/InvoiceCreatePage';
import { InvoiceBandPage } from './pages/auth/invoice/InvoiceBandPage/InvoiceBandPage';
import { VaccinationUsersPage } from './pages/auth/users/VaccinationUsersPage/VaccinationUsersPage';
import { VaccinationUserPage } from './pages/auth/users/VaccinationUserPage/VaccinationUserPage';
import { VaccinationCentersPage } from './pages/auth/vaccination/VaccinationCentersPage/VaccinationCentersPage';
import { VaccinationCenterPage } from './pages/auth/vaccination/VaccinationCenterPage/VaccinationCenterPage';
import { VaccinationCenterCreatePage } from './pages/auth/vaccination/VaccinationCenterCreatePage/VaccinationCenterCreatePage';
import { VaccinationBatchesPage } from './pages/auth/vaccination/VaccinationBatchesPage/VaccinationBatchesPage';
import { VaccinationAdministeredPage } from './pages/auth/vaccination/VaccinationAdministeredPage/VaccinationAdministeredPage';
import { AuditVaccinePage } from './pages/auth/audit/AuditVaccinePage/AuditVaccinePage';
import { ForgotPasswordPage } from './pages/nonauth/ForgotPasswordPage/ForgotPasswordPage';
import { TestingUsersPage } from './pages/auth/users/TestingUsersPage/TestingUsersPage';
import { TestingUserPage } from './pages/auth/users/TestingUserPage/TestingUserPage';
import { AuditTestingPage } from './pages/auth/audit/AuditTestingPage/AuditTestingPage';
import { TestingCentersPage } from './pages/auth/testing/TestingCentersPage/TestingCentersPage';
import { TestingCenterPage } from './pages/auth/testing/TestingCenterPage/TestingCenterPage';
import { TestingCenterCreatePage } from './pages/auth/testing/TestingCenterCreatePage/TestingCenterCreatePage';
import { TestingRegisteredPage } from './pages/auth/testing/TestingRegisteredPage/TestingRegisteredPage';
import { TestingAdministeredPage } from './pages/auth/testing/TestingAdministeredPage/TestingAdministeredPage';
import { PlaceholderPage } from './pages/auth/PlaceholderPage/PlaceholderPage';
import TwoFactorAuthPage from './pages/nonauth/TwoFactorAuthPage/TwoFactorAuthPage';

const AppRouter = () => (
    <Router>
        <Layout>
            <Switch>
                <Route
                    exact
                    path={ROUTES.SIGN_IN}
                    component={notAuth(SignInPage)}
                />
                <Route
                    exact
                    path={ROUTES.SIGN_IN_2FA}
                    component={notAuth(TwoFactorAuthPage)}
                />
                <Route
                    exact
                    path={ROUTES.FORGOT_PASSWORD}
                    component={notAuth(ForgotPasswordPage)}
                />
                <Route exact path={ROUTES.USERS} component={auth(UsersPage)} />
                <Route exact path={ROUTES.USER} component={auth(UserPage)} />
                <Route
                    exact
                    path={ROUTES.MFP_USERS}
                    component={auth(MfpUsersPage)}
                />
                <Route
                    exact
                    path={ROUTES.MFP_USER}
                    component={auth(MfpUserPage)}
                />
                <Route
                    exact
                    path={ROUTES.FOR_VERIFICATION}
                    component={auth(VerificationUsersPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_USERS}
                    component={auth(VaccinationUsersPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_USER}
                    component={auth(VaccinationUserPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_USERS}
                    component={auth(TestingUsersPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_USER}
                    component={auth(TestingUserPage)}
                />
                <Route exact path={ROUTES.HOSTS} component={auth(HostsPage)} />
                <Route
                    exact
                    path={ROUTES.HOST_CREATE}
                    component={auth(HostCreatePage)}
                />
                <Route exact path={ROUTES.HOST} component={auth(HostPage)} />
                <Route
                    exact
                    path={ROUTES.AUDIT_TRAIL}
                    component={auth(AuditTrailPage)}
                />
                <Route
                    exact
                    path={ROUTES.AUDIT_TRACK}
                    component={auth(AuditTrackPage)}
                />
                <Route
                    exact
                    path={ROUTES.AUDIT_VACCINATION}
                    component={auth(AuditVaccinePage)}
                />
                <Route
                    exact
                    path={ROUTES.AUDIT_TESTING}
                    component={auth(AuditTestingPage)}
                />
                <Route
                    exact
                    path={ROUTES.BROADCAST_MESSAGE}
                    component={auth(BroadcastMessagePage)}
                />
                <Route exact path={ROUTES.CODES} component={auth(CodesPage)} />
                <Route
                    exact
                    path={ROUTES.CODES_CREATE}
                    component={auth(CodePage)}
                />
                <Route
                    exact
                    path={ROUTES.INVOICE}
                    component={auth(InvoicesPage)}
                />
                <Route
                    exact
                    path={ROUTES.INVOICE_CREATE}
                    component={auth(InvoiceCreatePage)}
                />
                <Route
                    exact
                    path={ROUTES.INVOICE_BAND}
                    component={auth(InvoiceBandPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_CENTERS}
                    component={auth(VaccinationCentersPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_CENTER}
                    component={auth(VaccinationCenterPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_CENTER_CREATE}
                    component={auth(VaccinationCenterCreatePage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_BATCHES}
                    component={auth(VaccinationBatchesPage)}
                />
                <Route
                    exact
                    path={ROUTES.VACCINATION_ADMINISTERED}
                    component={auth(VaccinationAdministeredPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_CENTERS}
                    component={auth(TestingCentersPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_CENTER}
                    component={auth(TestingCenterPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_CENTER_CREATE}
                    component={auth(TestingCenterCreatePage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_REGISTERED}
                    component={auth(TestingRegisteredPage)}
                />
                <Route
                    exact
                    path={ROUTES.TESTING_ADMINISTERED}
                    component={auth(TestingAdministeredPage)}
                />
                <Route
                    exact
                    path={ROUTES.PLACEHOLDER}
                    component={auth(PlaceholderPage)}
                />
                <Redirect from={ROUTES.ROOT} to={ROUTES.SIGN_IN} />
            </Switch>
        </Layout>
    </Router>
);

export default AppRouter;
