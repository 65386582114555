import React from 'react';
import ReactModal from 'react-modal';

import './Modal.scss';

interface Props {
  isOpen: boolean;
  onClose(): void;
  noPadding?: boolean;
}

ReactModal.setAppElement('#root');

export const Modal: React.FC<Props> = (props) => {
  const { children, isOpen, onClose, noPadding } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          padding: noPadding ? '0' : '24px 40px',
        },
      }}
    >
      <div>{children}</div>
    </ReactModal>
  );
};
