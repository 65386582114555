import { ROUTES } from '../configs/routes';

export enum AdminPermissionsNames {
  Users = 'Users',
  Hosts = 'Hosts',
  Invoice = 'Invoice',
  Audit = 'Audit Trail',
  Codes = 'User Codes',
  BroadcastMessage = 'Broadcast Message',
  Vaccination = 'Vaccination',
  Tickets = 'View Tickets',
  AccountDetails = 'Account Details',
  CovidTesting = 'Covid Testing',
  SubUserManagement = 'Sub-User Management',
  CentreDetails = 'Centre Details',
  AdministeredVaccines = 'Administered Vaccines',
  RegisteredVaccines = 'Registered Vaccines',
  RegisteredTestTypes = 'Registered Test Types',
  AdministeredTestTypes = 'Administered Test Types',
  GenerateQRVaccine = 'Generate QR Vaccine',
}

export enum OVERRIDE_STATUS {
  DISABLE = 'DISABLED',
  OVERRIDE = 'OVERRIDDEN',
  REJECT = 'REJECTED',
}

export enum RegistrationStatus {
  PHONE_VERIFICATION = 'PHONE_VERIFICATON',
  ADMIN_REVIEW = 'ADMIN_REVIEW',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
}

export const ADMIN_PERMISSIONS_MAP = {
  [AdminPermissionsNames.Users]: 'Users',
  [AdminPermissionsNames.Hosts]: 'Hosts',
  [AdminPermissionsNames.Invoice]: 'Invoice',
  [AdminPermissionsNames.Audit]: 'Audit',
  [AdminPermissionsNames.Codes]: 'Codes',
  [AdminPermissionsNames.BroadcastMessage]: 'BroadcastMessage',
  [AdminPermissionsNames.Vaccination]: 'Vaccination',
  [AdminPermissionsNames.Tickets]: 'Tickets',
  [AdminPermissionsNames.AccountDetails]: 'AccountDetails',
  [AdminPermissionsNames.SubUserManagement]: 'SubUserManagement',
  [AdminPermissionsNames.CentreDetails]: 'CentreDetails',
  [AdminPermissionsNames.RegisteredVaccines]: 'RegisteredVaccines',
  [AdminPermissionsNames.AdministeredVaccines]: 'AdministeredVaccines',
  [AdminPermissionsNames.RegisteredTestTypes]: 'RegisteredTestTypes',
  [AdminPermissionsNames.AdministeredTestTypes]: 'AdministeredTestTypes',
  [AdminPermissionsNames.CovidTesting]: 'CovidTesting',
  [AdminPermissionsNames.GenerateQRVaccine]: 'GenerateQRVaccine',
};

export const ADMIN_PERMISSIONS_ROUTES: any = {
  [AdminPermissionsNames.BroadcastMessage]: ROUTES.BROADCAST_MESSAGE,
  [AdminPermissionsNames.Users]: ROUTES.USERS,
  [AdminPermissionsNames.Hosts]: ROUTES.HOSTS,
  [AdminPermissionsNames.Audit]: ROUTES.AUDIT_TRAIL,
  [AdminPermissionsNames.Invoice]: ROUTES.INVOICE,
  [AdminPermissionsNames.Codes]: ROUTES.CODES,
  [AdminPermissionsNames.Vaccination]: ROUTES.VACCINATION_CENTERS,
  [AdminPermissionsNames.CovidTesting]: ROUTES.TESTING_CENTERS,
};

export enum UserPermissionsLevels {
  Admin = 2,
}

export enum NOTIF_SETTING_TYPES {
  NONE = 'NONE',
  ALL_APPLICATION = 'ALL_APPLICATION',
  CUSTOM = 'CUSTOM',
}

export const NotificationSettingItems = {
  [NOTIF_SETTING_TYPES.NONE]: 'None',
  [NOTIF_SETTING_TYPES.ALL_APPLICATION]: 'All Applications',
  [NOTIF_SETTING_TYPES.CUSTOM]: 'Custom Applications',
};
