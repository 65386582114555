import React, { useState } from 'react';
import classNames from 'classnames';
import { InputTypes } from '../../../../constants/common';
import { Icon } from '../../Icon/Icon';

import './Input.scss';

interface Props {
  onBlur?: any;
  error?: boolean;
  errorMessage?: string;
  value: string | number;
  name?: string;
  title?: string;
  placeholder?: string;
  reset?: boolean;
  completed?: boolean;
  password?: boolean;
  disabled?: boolean;
  search?: boolean;
  htmlType?: 'text' | 'number';
  onChange?: (value?: string, name?: string) => void;
  endAdornment?: any;
}

export const Input: React.FC<Props> = (props) => {
  const {title, value = '', name = '', placeholder, reset,
    completed, password, disabled, search, htmlType, onChange, onBlur, error, errorMessage, endAdornment} = props;
  const showResetBtn = reset && !!value;
  const type = password ? InputTypes.Password : InputTypes.Text;
  const [localType, setLocalType] = useState(type);
  const wrapperClass = classNames('input', {
    'input--reset': reset,
    'input--completed': completed,
    'input--disabled': disabled,
    'input--search': search,
    'input--error': !!errorMessage,
    'input--end-adornment': endAdornment,
  });
  const passwordBtnClass = classNames('input__password-btn', {
    'input__password-btn--active': localType === InputTypes.Text,
  });
  const fieldType = htmlType || localType || 'text';

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.value, name);
    }
  };

  const handleReset = () => {
    if (onChange) {
      onChange('', name);
    }
  };

  const togglePasswordShow = () => {
    if (localType === 'password') {
      setLocalType(InputTypes.Text);
    } else {
      setLocalType(InputTypes.Password);
    }
  };
  const checkError = error ? {color: 'red'} : {color: 'black'}
  return (
    <div className={wrapperClass}>
      {title && (
        <div style={checkError} className="input__title">
          {title}
        </div>
      )}

      {search && <Icon name="search" className="input__icon-search" />}
      <div className={`${endAdornment ? 'input__with-adornment' : ''}`}>
        <input
          onBlur={
            onBlur
              ? (e) => {
                  onBlur(e.target.name);
                }
              : () => {}
          }
          type={fieldType}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={completed}
          onChange={handleChange}
        />
        {endAdornment && (
          <div className="input__end-adornment">{endAdornment}</div>
        )}
      </div>

      {errorMessage && <p>{errorMessage}</p>}
      {showResetBtn && (
        <button
          className="input__reset-btn"
          type="button"
          onClick={handleReset}
        />
      )}
      {password && (
        <button
          className={passwordBtnClass}
          type="button"
          onClick={togglePasswordShow}
        />
      )}
      {completed && <span className="input__check" />}
    </div>
  );
};
