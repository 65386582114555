import React from 'react';

import './FormFooter.scss';

export const FormFooter: React.FC = (props) => {
  const {children} = props;

  return (
    <div className="form-footer">
      {children}
    </div>
  );
};
