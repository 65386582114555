import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { CommonEntityRes, CommonObject } from '../types/common';
import { API_BASE_URL, API_ENDPOINTS, API_TESTING_BASE_URL, API_VACCINES_BASE_URL } from '../constants/api';
import { Audit } from '../types/audit';
import { Http } from '../services/http';
import {
  actionSetAuditTrackItems,
  actionSetAuditTrailItems,
  actionSetTestingAuditItems,
  actionSetVaccinesAuditItems,
} from '../store/actions/audit-actions';
import { CommonUtils } from '../utils/CommonUtils';
import moment from 'moment';

export function useAuditTrackRequest() {
  const dispatch = useDispatch();

  const loadTrackItems = async (page, params?: CommonObject) => {
    try {
      const {search = '', from, to} = params;
      const convertedFrom = from ? moment(from).toISOString() : '';
      const convertedTo = to ? moment(to).toISOString() : '';
      const requestParams = queryString.stringify({
        page,
        limit: 10,
        from: convertedFrom,
        to: convertedTo,
        search
      });
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${API_ENDPOINTS.TRACKING}?${requestParams}`;
      const res: CommonEntityRes<Audit> = await Http.get(url);

      dispatch(actionSetAuditTrackItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadTrackItems};
}

export function useAuditTrailRequest() {
  const dispatch = useDispatch();

  const loadTrailItems = async (page, params?: CommonObject) => {
    try {
      const {search = ''} = params;
      const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${API_ENDPOINTS.TRACKING}?page=${page}&limit=10&search=${search}`;
      const res: CommonEntityRes<Audit> = await Http.get(url);

      dispatch(actionSetAuditTrailItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadTrailItems};
}

export function useAuditExport() {
  const exportAuditItemsToCsv = async (searchStr: string, itemsCount: number) => {
    const url = `${API_BASE_URL}/${API_ENDPOINTS.APP_USERS}/${API_ENDPOINTS.TRACKING}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
    const foundItems: CommonEntityRes<Audit> = await Http.get(url);
    CommonUtils.exportItemsToCSV(foundItems.data, 'Audit Trail');
  };

  return {exportAuditItemsToCsv};
}

export function useAuditVaccinesRequest() {
  const dispatch = useDispatch();

  const loadVaccinesItems = async (page, params?: CommonObject) => {
    try {
      const {search = '', from, to} = params;
      const convertedFrom = from ? moment(from).toISOString() : '';
      const convertedTo = to ? moment(to).toISOString() : '';
      const requestParams = queryString.stringify({
        page,
        limit: 10,
        from: convertedFrom,
        to: convertedTo,
        search
      });

      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_AUDIT}?${requestParams}`;
      const res: CommonEntityRes<Audit> = await Http.get(url);
      dispatch(actionSetVaccinesAuditItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadVaccinesItems};
}

export function useVaccinesAuditExport() {
  const exportAuditItemsToCsv = async (searchStr: string, itemsCount: number) => {
    const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_AUDIT}`;
    const foundItems: CommonEntityRes<Audit> = await Http.get(url);

    CommonUtils.exportItemsToCSV(foundItems.data, 'Audit Trail');
  };

  return {exportAuditItemsToCsv};
}

export function useAuditTestingRequest() {
  const dispatch = useDispatch();

  const loadTestingItems = async (page, params?: CommonObject) => {
    try {
      const {search = '', from, to} = params;
      const convertedFrom = from ? moment(from).toISOString() : '';
      const convertedTo = to ? moment(to).toISOString() : '';
      const requestParams = queryString.stringify({
        page,
        limit: 10,
        from: convertedFrom,
        to: convertedTo,
        search
      });

      const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_AUDIT}?${requestParams}`;
      const res: CommonEntityRes<Audit> = await Http.get(url);
      dispatch(actionSetTestingAuditItems(res));
    } catch (e) {
      console.log(e);
    }
  };
  return {loadTestingItems};
}

export function useTestingAuditExport() {
  const exportAuditItemsToCsv = async (searchStr: string, itemsCount: number) => {
    const url = `${API_TESTING_BASE_URL}/${API_ENDPOINTS.TESTING_AUDIT}`;
    const foundItems: CommonEntityRes<Audit> = await Http.get(url);

    const handledItems = foundItems.data.map(item => {
      const {date, ...rest} = item;

      return {'test date/time': date, ...rest};
    });

    CommonUtils.exportItemsToCSV(handledItems, 'Audit Trail');
  };
  return {exportAuditItemsToCsv};
}
