import React from 'react';

import './Icon.scss';

interface Props {
  name: string;
  width?: number;
  height?: number;
  className?: string;
}

export const Icon: React.FC<Props> = (props) => {
  const {name, width = 20, height = 20, className = ''} = props;
  const iconClass = `icon icon--${name} ${className}`;
  const style = {
    width,
    height,
  };

  return (
    <i style={style} className={iconClass} />
  );
};
