import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Host } from '../../../types/hosts';
import { hostDefaults } from '../../../data/defaults';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { Button } from '../../_common/_controls/Button/Button';
import { FormFooter } from '../../_common/Form/FormFooter/FormFooter';
import { useInvoicesRequest } from '../../../hooks/invoices-hooks';
import { ApplicationState } from '../../../store';
import { InvoicesState } from '../../../store/reducers/invoices-reducer';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';

import './HostForm.scss';
import { useHostsRequest } from '../../../hooks/hosts-hooks';
import { HostsState } from '../../../store/reducers/hosts-reducer';
import HostSelectMenu from '../HostSelectMenu/HostSelectMenu';

interface Props {
  data: Host;
  isEdit?: boolean;
  isLoading?: boolean;
  onSubmit(data: Host): void;
}

export const HostForm: React.FC<Props> = (props) => {
  const { onSubmit, isEdit, isLoading, data } = props;
  const { loadBands } = useInvoicesRequest();
  const { loadHosts } = useHostsRequest();
  const invoicesState = useSelector<ApplicationState, InvoicesState>(
    (state) => state.invoices
  );
  const hostsState = useSelector<ApplicationState, HostsState>(
    (state) => state.hosts
  );

  const [formData, setFormData] = useState<Host>(hostDefaults);
  const {
    name,
    address1,
    key_contact,
    address2,
    phone,
    city,
    email,
    postcode,
    band_id,
    invoice_date,
    parent_host_id,
  } = formData;
  const buttonTitle = isEdit ? 'Update' : 'Create';
  const { bandsItems } = invoicesState;

  useEffect(() => {
    setFormData(data);
  }, [data.id]);

  useEffect(() => {
    loadBands();
    loadHosts();
  }, []);

  const [dataError, setDataError] = useState({
    name: 'Required Field',
    nameDirty: false,
    contact: 'Required Field',
    contactDirty: false,
    phone: 'Required Field',
    phoneDirty: false,
    email: 'Required Field',
    emailDirty: false,
    address: 'Required Field',
    addressDirty: false,
    city: 'Required Field',
    cityDirty: false,
    postCode: 'Required Field',
    postCodeDirty: false,
    band: 'Required Field',
    bandDirty: false,
    date: 'Required Field',
    dateDirty: false,
  });
  const onBlurArea = (name: string) => {
    switch (name) {
      case 'name':
        return formData.name.length === 0
          ? setDataError({ ...dataError, nameDirty: true })
          : setDataError({ ...dataError, nameDirty: false });
      case 'address1':
        return formData.address1.length === 0
          ? setDataError({ ...dataError, addressDirty: true })
          : setDataError({ ...dataError, addressDirty: false });
      case 'key_contact':
        return formData.key_contact.length === 0
          ? setDataError({ ...dataError, contactDirty: true })
          : setDataError({ ...dataError, contactDirty: false });
      case 'phone':
        return formData.phone.length === 0
          ? setDataError({ ...dataError, phoneDirty: true })
          : setDataError({ ...dataError, phoneDirty: false });
      case 'city':
        return formData.city.length === 0
          ? setDataError({ ...dataError, cityDirty: true })
          : setDataError({ ...dataError, cityDirty: false });
      case 'email':
        return formData.email.length === 0
          ? setDataError({ ...dataError, emailDirty: true })
          : null;
      case 'postcode':
        return formData.postcode.length === 0
          ? setDataError({ ...dataError, postCodeDirty: true })
          : setDataError({ ...dataError, postCodeDirty: false });
      default:
        return setDataError({ ...dataError, addressDirty: false });
    }
  };
  const handleFieldChange = (value: string, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    if (fieldName === 'email') {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({
          ...dataError,
          email: 'Incorrect Email',
          emailDirty: true,
        });
      } else {
        setDataError({ ...dataError, email: '', emailDirty: false });
      }
      if (value.length !== 0) {
        onBlurArea(fieldName);
      } else {
        onBlurArea(fieldName);
      }
    }
    if (fieldName === 'phone') {
      const re = /^\d{1,}$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({
          ...dataError,
          phone: 'Incorrect Phone',
          phoneDirty: true,
        });
      } else {
        setDataError({ ...dataError, phone: '', phoneDirty: false });
      }
    }
  };
  const handleFormSubmit = () => {
    if (
      dataError.nameDirty === true ||
      dataError.contactDirty === true ||
      dataError.phoneDirty === true ||
      dataError.emailDirty === true ||
      dataError.addressDirty === true ||
      dataError.cityDirty === true ||
      dataError.postCodeDirty === true ||
      formData.band_id.length === 0 ||
      formData.invoice_date.length === 0 ||
      dataError.phone === 'Incorrect Phone' ||
      dataError.email === 'Incorrect Email'
    ) {
      alert('Please check all fields');
    } else {
      return onSubmit(formData);
    }
  };

  return (
    <Form className="host-create-form" onSubmit={handleFormSubmit}>
      <FormGroup>
        <Input
          title={
            dataError.name && dataError.nameDirty
              ? `Host Name-${dataError.name}`
              : 'Host Name'
          }
          onBlur={onBlurArea}
          error={dataError.name && dataError.nameDirty ? true : false}
          value={name}
          name="name"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.address && dataError.addressDirty
              ? `Address Line 1-${dataError.address}`
              : 'Address Line 1'
          }
          onBlur={onBlurArea}
          error={dataError.address && dataError.addressDirty ? true : false}
          value={address1}
          name="address1"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.contact && dataError.contactDirty
              ? `Key Contact-${dataError.contact}`
              : 'Key Contact'
          }
          onBlur={onBlurArea}
          error={dataError.contact && dataError.contactDirty ? true : false}
          value={key_contact}
          name="key_contact"
          onChange={handleFieldChange}
        />
        <Input
          title="Address Line 2"
          value={address2}
          name="address2"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.phone && dataError.phoneDirty
              ? `Phone Number-${dataError.phone}`
              : 'Phone Number'
          }
          onBlur={onBlurArea}
          error={dataError.phone && dataError.phoneDirty ? true : false}
          value={phone}
          name="phone"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.city && dataError.cityDirty
              ? `City/Town-${dataError.city}`
              : 'City/Town'
          }
          onBlur={onBlurArea}
          error={dataError.city && dataError.cityDirty ? true : false}
          value={city}
          name="city"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.email && dataError.emailDirty
              ? `Email Address-${dataError.email}`
              : 'Email Address'
          }
          onBlur={onBlurArea}
          error={dataError.email && dataError.emailDirty ? true : false}
          value={email}
          name="email"
          onChange={handleFieldChange}
        />
        <Input
          title={
            dataError.postCode && dataError.postCodeDirty
              ? `Postcode-${dataError.postCode}`
              : 'Postcode'
          }
          onBlur={onBlurArea}
          error={dataError.postCode && dataError.postCodeDirty ? true : false}
          value={postcode}
          name="postcode"
          onChange={handleFieldChange}
        />
        <SelectMenu
          onBlur={onBlurArea}
          error={dataError.band && dataError.bandDirty ? true : false}
          title={
            dataError.band && dataError.bandDirty
              ? `Pricing Band-${dataError.band}`
              : 'Pricing Band'
          }
          value={band_id}
          name="band_id"
          options={bandsItems}
          valueKey="id"
          labelKey="name"
          onChange={handleFieldChange}
        />
        <HostSelectMenu 
          initial_host_id={hostsState?.host?.data?.parent_host_id}
          onChange={(val) => {
            handleFieldChange(val, 'parent_host_id');
          }}
        />
        <Datepicker
          title={
            dataError.date && dataError.dateDirty
              ? `Invoice Date-${dataError.date}`
              : 'Invoice Date'
          }
          error={dataError.date && dataError.dateDirty ? true : false}
          value={invoice_date}
          name="invoice_date"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormFooter>
        <Button title={buttonTitle} disabled={isLoading} htmlType="submit" />
      </FormFooter>
    </Form>
  );
};
