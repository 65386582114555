import React, { useState, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { SVG_ICONS } from '../../_common/Svg/Svg';
import { Input } from '../../_common/_controls/Input/Input';
import { Form } from '../../_common/Form/Form';
import { Button } from '../../_common/_controls/Button/Button';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import CountriesCodes from '../../../constants/json/countries-phones.json';
import { AppUser, AppUserGuardian } from '../../../types/users';
import { useUsersRequest } from '../../../hooks/users-hooks';
import { CommonUtils } from '../../../utils/CommonUtils';
import { actionSetAppUser } from '../../../store/actions/users-actions';
import './UserEditForm.scss';

interface Props {
  user: AppUser;
  guardian: AppUserGuardian;
  onClose: () => void;
}

interface FormInputProps {
  control: any;
  name: string;
  Component: any;
  rules?: any;
  title?: string;
  showClearBtn?: boolean;
  labelKey?: string;
  valueKey?: string;
  options?: any;
  menuPlacement?: 'top' | 'bottom';
}

const FormInput: React.FC<FormInputProps> = ({ control, name, Component, rules, ...restInput }) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => <Component error={error} errorMessage={error?.message} value={value} onChange={onChange} {...restInput} {...rest} />}
    />
  );
}

interface UserInfo {
  user: AppUser,
  guardian: AppUserGuardian;
}

const UserEditForm: React.FC<Props> = ({ user, guardian, onClose }) => {
  const [guardianRequired, setGuardianRequired] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { updateAppUser, loadAppUserGuardian } = useUsersRequest();

  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm<UserInfo>({
    defaultValues: {
      user: user,
      guardian: guardian,
    },
  });

  const userDob = useWatch({
    control,
    name: 'user.dob',
  });

  useEffect(() => {
    // computes age based on dob of user
    const age = CommonUtils.computeAge(userDob);
    setGuardianRequired(age < 18);
  }, [userDob]);

  const onSubmit = async(values) => {
    setIsSubmitting(true);
    const { user, guardian } = values;
    const formData = {
      first_name: user.first_name,
      last_name: user.last_name,
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      postcode: user.postcode,
      country: user.country,
      state: user.state || '',
      dob: moment(user.dob).format('YYYY-MM-DD'),
      guardian: guardianRequired
        ? {
            ...guardian,
            name: `${guardian?.first_name} ${guardian?.last_name}`,
            dob: moment(guardian?.dob).format('YYYY-MM-DD'),
          }
        : {},
    };

    const res = await updateAppUser(user.id, formData);

    if(res) {
      dispatch(actionSetAppUser({
        ...user,
        ...res,
        dob: moment(res?.dob).format('YYYY-MM-DD'),
      }));
      await loadAppUserGuardian(user.id);
    }
    setIsSubmitting(false);
    onClose();
  }

  const renderForm = (form) => {
    const title =
      form === 'user' ? `User's Information` : `Guardian's Information`;

    return (
      <div className="user-edit-form__content">
        <div className="section">
          <p className="section__title section-form">{title}</p>
          <div className="section__row">
            <FormInput
              control={control}
              name={`${form}.first_name`}
              title="First Name"
              Component={Input}
              rules={{ required: 'Field is required' }}
            />
            <FormInput
              control={control}
              name={`${form}.last_name`}
              title="Last Name"
              Component={Input}
              rules={{ required: 'Field is required' }}
            />
            <FormInput
              control={control}
              name={`${form}.dob`}
              title="Date of Birth"
              Component={Datepicker}
              showClearBtn={false}
              rules={{
                required: 'Field is required',
                validate: (value: any) => {
                  if (form === 'guardian') {
                    return (
                      CommonUtils.computeAge(value) > 17 ||
                      'Guardian should be 17 and above.'
                    );
                  }
                  return;
                },
              }}
            />
          </div>
          <div className="section__row">
            <FormInput
              control={control}
              name={`${form}.address1`}
              title="Address Line 1"
              Component={Input}
              rules={{ required: 'Field is required' }}
            />
            <FormInput
              control={control}
              name={`${form}.address2`}
              title="Address Line 2"
              Component={Input}
            />
          </div>
          <div className="section__row">
            <FormInput
              control={control}
              name={`${form}.city`}
              title="City/Town"
              Component={Input}
              rules={{ required: 'Field is required' }}
            />
            <FormInput
              control={control}
              name={`${form}.state`}
              title="State"
              Component={Input}
            />
          </div>
          <div className="section__row">
            <FormInput
              control={control}
              name={`${form}.postcode`}
              title="Postcode"
              Component={Input}
              rules={{ required: 'Field is required' }}
            />
            <FormInput
              control={control}
              name={`${form}.country`}
              title="Country"
              Component={SelectMenu}
              labelKey="name"
              valueKey="code"
              options={CountriesCodes}
              menuPlacement="top"
              rules={{ required: 'Field is required' }}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="user-edit-form">
      <div className="user-edit-form__head">
        {/* header */}
        <p className="form-title">Update User Details</p>
        <Button
          icon={SVG_ICONS.CLOSE}
          title=""
          type="empty"
          onClick={onClose}
        />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="user-edit-form__body">
          {renderForm('user')}
          {/* show guardian info if age is < 18 */}
          {guardianRequired && renderForm('guardian')}
          {/* buttons */}
          <div className="user-edit-form__actions">
            <Button title="Cancel" variant="outlined" onClick={onClose} />
            <Button
              title="Save changes"
              htmlType="submit"
              disabled={isSubmitting}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default UserEditForm;