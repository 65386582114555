import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { FormFooter } from '../../_common/Form/FormFooter/FormFooter';
import { Button } from '../../_common/_controls/Button/Button';
import { vaccinationCenterCreateDefaults, vaccinationCenterDefault } from '../../../data/defaults';
import { VaccinationCenter, VaccinationCenterCreateFormEntity } from '../../../types/vaccination';

interface Props {
  isNew?: boolean;
  isSaved?: boolean;
  data?: VaccinationCenter;
  onSubmit: (data: VaccinationCenterCreateFormEntity) => void;
}

export const VaccinationCenterForm: React.FC<Props> = (props) => {
  const {isNew, data = vaccinationCenterDefault, isSaved, onSubmit} = props;
  const [formData, setFormData] = useState<VaccinationCenterCreateFormEntity>(vaccinationCenterCreateDefaults);
  const {name, address1, key_contact, address2, phone, city, email, postcode} = formData;
  const actionTitle = isNew ? 'Create' : 'Update';

  useEffect(() => {
    setFormData({
      name: data.name,
      key_contact: data.key_contact,
      email: data.email,
      postcode: data.postcode,
      city: data.city,
      address2: data.address2,
      address1: data.address1,
      phone: data.phone,
    });
  }, [data.id]);
  const [dataError, setDataError] = useState({
    name: 'Required Field',
    nameDirty: false,
    contact: 'Required Field',
    contactDirty: false,
    phone: 'Required Field',
    phoneDirty: false,
    email: 'Required Field',
    emailDirty: false,
    address: 'Required Field',
    addressDirty: false,
    city: 'Required Field',
    cityDirty: false,
    postCode: 'Required Field',
    postCodeDirty: false
  })
  const onBlurArea = (name: string) => {
    switch (name) {
      case 'name' :
        return formData.name.length === 0 ?
          setDataError({...dataError, nameDirty: true}) :
          setDataError({...dataError, nameDirty: false})
      case 'address1' :
        return formData.address1.length === 0 ?
          setDataError({...dataError, addressDirty: true}) :
          setDataError({...dataError, addressDirty: false})
      case 'key_contact' :
        return formData.key_contact.length === 0 ?
          setDataError({...dataError, contactDirty: true}) :
          setDataError({...dataError, contactDirty: false})
      case 'phone' :
        return formData.phone.length === 0 ?
          setDataError({...dataError, phoneDirty: true}) :
          setDataError({...dataError, phoneDirty: false})
      case 'city' :
        return formData.city.length === 0 ?
          setDataError({...dataError, cityDirty: true}) :
          setDataError({...dataError, cityDirty: false})
      case 'email' :
        return formData.email.length === 0 ?
          setDataError({...dataError, emailDirty: true}) :
          null
      case 'postcode' :
        return formData.postcode.length === 0 ?
          setDataError({...dataError, postCodeDirty: true}) :
          setDataError({...dataError, postCodeDirty: false})
      default:
        return setDataError({...dataError, addressDirty: false})
    }
  }
  useEffect(() => {
    if (isSaved) {
      setFormData(vaccinationCenterCreateDefaults);
    }
  }, [isSaved]);

  const handleFieldChange = (value: string, name: string) => {
    setFormData({...formData, [name]: value});
    if(name === 'email'){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({...dataError, email: 'Incorrect Email', emailDirty: true})
      } else {
        setDataError({...dataError, email: '', emailDirty: false})
      }
      if (value.length !== 0) {
        onBlurArea(name)
      } else {
        onBlurArea(name)
      }
    }
    if(name === 'phone'){
      const re = /^\d{1,}$/;
      if (!re.test(String(value).toLowerCase())) {
        setDataError({...dataError, phone: 'Incorrect Phone', phoneDirty: true})
      } else {
        setDataError({...dataError, phone: '', phoneDirty: false})
      }
    }
  };
  const handleFormSubmit = () => {
    if (dataError.nameDirty === true ||
      dataError.contactDirty === true ||
      dataError.phoneDirty === true ||
      dataError.emailDirty === true ||
      dataError.addressDirty === true ||
      dataError.cityDirty === true ||
      dataError.postCodeDirty === true ||
      formData.email.length === 0 ||
      dataError.phone === 'Incorrect Phone' ||
      dataError.email === 'Incorrect Email') {
      alert('Please check all fields')
    }else {
      return onSubmit(formData);
    }
  };

  return (
    <Form className="vc-form" onSubmit={handleFormSubmit}>
      <FormGroup>
        <Input
          value={name}
          onBlur={onBlurArea}
          error={dataError.name && dataError.nameDirty ? true : false}
          title={dataError.name && dataError.nameDirty ? `Centre Name-${dataError.name}` : "Centre Name"}
          placeholder="Centre Name"
          name="name"
          onChange={handleFieldChange}
        />
        <Input
          value={address1}
          onBlur={onBlurArea}
          error={dataError.address && dataError.addressDirty ? true : false}
          title={dataError.address && dataError.addressDirty ? `Address Line 1-${dataError.address}` : "Address Line 1"}
          placeholder="Address Line 1"
          name="address1"
          onChange={handleFieldChange}
        />
        <Input
          value={key_contact}
          title={dataError.contact && dataError.contactDirty ? `Key Contact-${dataError.contact}` : "Key Contact"}
          onBlur={onBlurArea}
          error={dataError.contact && dataError.contactDirty ? true : false}
          placeholder="Key Contact"
          name="key_contact"
          onChange={handleFieldChange}
        />
        <Input
          value={address2}
          title="Address Line 2"
          placeholder="Address Line 2"
          name="address2"
          onChange={handleFieldChange}
        />
        <Input
          value={phone}
          error={dataError.phone && dataError.phoneDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.phone && dataError.phoneDirty ? `Phone Number-${dataError.phone}` : "Phone Number"}
          placeholder="Phone Number"
          name="phone"
          onChange={handleFieldChange}
        />
        <Input
          value={city}
          error={dataError.city && dataError.cityDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.city && dataError.cityDirty ? `City/Town-${dataError.city}` : "City/Town"}
          placeholder="City/Town"
          name="city"
          onChange={handleFieldChange}
        />
        <Input
          value={email}
          error={dataError.email && dataError.emailDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.email && dataError.emailDirty ? `Email Address-${dataError.email}` : "Email Address"}
          placeholder="Email Address"
          name="email"
          onChange={handleFieldChange}
        />
        <Input
          value={postcode}
          error={dataError.postCode && dataError.postCodeDirty ? true : false}
          onBlur={onBlurArea}
          title={dataError.postCode && dataError.postCodeDirty ? `Postcode-${dataError.postCode}` : "Postcode"}
          placeholder="Postcode"
          name="postcode"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormFooter>
        <Button title={actionTitle} htmlType="submit" />
      </FormFooter>
    </Form>
  );
};
