import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { actionSetApplicationsList } from '../store/actions/common-actions';
import { ApplicationEntity } from '../types/common';

export function useCommonRequest() {
  const dispatch = useDispatch();
  const getApplicationsList = async () => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${API_ENDPOINTS.APPLICATIONS_LIST}`;

      const res: Array<ApplicationEntity> = await Http.get(url);

      if (res && !isEmpty(res)) {
        res.filter((app) => !isEmpty(app));

        dispatch(actionSetApplicationsList(res));
      } else {
        throw new Error('Applications list not available');
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return {
    getApplicationsList,
  };
}
