import { CustomAction } from '../index';
import { COMMON_ACTION_TYPES } from '../actions-types';
import { ApplicationEntity } from '../../types/common';
import { FirebaseApp } from 'firebase/app';

export const actionSetApplicationsList = (data: Array<ApplicationEntity>): CustomAction => {
  return {
    type: COMMON_ACTION_TYPES.SET_APPLICATIONS_LIST,
    data,
  };
};

export const actionSetFCMInstance = (instance: any): CustomAction => {
  return {
    type: COMMON_ACTION_TYPES.SET_FCM_INSTANCE,
    data: instance,
  };
};
