import React, { ChangeEvent } from 'react';
import classNames from 'classnames';

import './Form.scss';

interface Props {
  className?: string;
  title?: string;
  onSubmit(e: ChangeEvent<any>): void;
}

export const Form: React.FC<Props> = (props) => {
  const {children, title, className = '', onSubmit} = props;
  const formClass = classNames('form', {
    [className]: !!className,
  });

  const handleFormSubmit = (e: ChangeEvent<any>) => {
    e.preventDefault();

    onSubmit(e);
  };

  return (
    <form className={formClass} onSubmit={handleFormSubmit}>
      <div className="form__title">{title}</div>
      {children}
    </form>
  );
};
