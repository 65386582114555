import { filter, isEmpty, cloneDeep, some } from 'lodash';
import { LocalStorage } from '../services/storage';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { AdminUserPermission, AppUserAppNotificationSettings, CustomNotificationSettings } from '../types/users';
import { ADMIN_PERMISSIONS_MAP, AdminPermissionsNames, NOTIF_SETTING_TYPES } from '../constants/users';
import { CommonObject } from '../types/common';

export const UtilsUser = {
  isUserLoggedIn: () => {
    return !!LocalStorage.get(USER_TOKEN_NAMESPACE);
  },
  getUserPermissionsValues: (items: AdminUserPermission[]) => {
    const values = {};

    items.forEach((item) => {
      const valueName = ADMIN_PERMISSIONS_MAP[item.name];

      if (valueName) {
        values[valueName] = item.value;
      }
    });

    return values;
  },
  convertUserPermissions: (permissions: CommonObject): any[] => {
    return Object.keys(permissions).map((key) => {
      return {
        name: AdminPermissionsNames[key],
        value: permissions[key],
      };
    });
  },

  getAccessToken: () => {
    return LocalStorage.get(USER_TOKEN_NAMESPACE);
  },

  createUserNotificationSettingsForm: (notificationSettings: Array<AppUserAppNotificationSettings>): CustomNotificationSettings => {
    if (!notificationSettings || isEmpty(notificationSettings)) {
      return { notification_setting: NOTIF_SETTING_TYPES.NONE, applications: [] };
    }
    const enabledApplications = filter(notificationSettings, (app) => app.is_receiving);

    let notification_setting: NOTIF_SETTING_TYPES;
    if (notificationSettings.length == enabledApplications.length) notification_setting = NOTIF_SETTING_TYPES.ALL_APPLICATION;
    else if (enabledApplications.length == 0) notification_setting = NOTIF_SETTING_TYPES.NONE;
    else notification_setting = NOTIF_SETTING_TYPES.CUSTOM;

    return {
      notification_setting,
      applications: enabledApplications.map((app) => app.application_id),
    };
  },

  createUserNotficationSettingsRequestBody: (enabledApplications: Array<string>, notificationSettings: Array<AppUserAppNotificationSettings>): Array<AppUserAppNotificationSettings> => {
    const updatedNotificationSetting = notificationSettings.map((orig) => {
      const modApplication = cloneDeep(orig);
      if (some(enabledApplications, (x) => x === orig.application_id)) {
        modApplication.is_receiving = true;
      } else modApplication.is_receiving = false;
      return modApplication;
    });

    return updatedNotificationSetting;
  },
};
