import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { UserInfoItem } from '../../../../components/users/UserInfoItem/UserInfoItem';
import { Table } from '../../../../components/_common/Table/Table';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { useUsersRequest } from '../../../../hooks/users-hooks';
import { actionResetAppUser } from '../../../../store/actions/users-actions';
import { UserVaccinationDetails } from '../../../../components/users/UserVaccinationDetails/UserVaccinationDetails';
import { UserTestingDetails } from '../../../../components/users/UserTestingDetails/UserTestingDetails';
import { UserRegistrationDetails } from '../../../../components/users/UserRegistrationDetails/UserRegistrationDetails';
import { Svg, SVG_ICONS } from '../../../../components/_common/Svg/Svg';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import { Modal } from '../../../../components/_common/Modal/Modal';
import UserEditForm from '../../../../components/users/UserEditForm/UserEditForm';

import './UserPage.scss';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/common';
import { RegistrationStatus } from '../../../../constants/users';
import { UserNotificationSettings } from '../../../../components/users/UserNotificationSettings/UserNotificationSettings';

export const UserPage: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { appUser } = useSelector<ApplicationState, UsersState>(
    (state) => state.users
  );
  const {
    loadAppUser,
    loadAppUserGuardian,
    loadAppUserAudit,
    loadAppUserTickets,
    loadAppUserTesting,
  } = useUsersRequest();
  const userId = params.id;
  const { data, audits, tickets, testing, guardian } = appUser;
  const {
    id,
    name,
    address1,
    address2,
    phone,
    email,
    postcode,
    city,
    dob,
    vaccination,
    used_code,
    registration_status,
    mobile_registration_status,
    fmc_score,
    journey_id,
    duplicates,
  } = data;
  const {
    data: auditData,
    page: auditPage,
    total: auditTotal,
    limit: auditLimit,
  } = audits;
  const {
    data: ticketsData,
    page: ticketsPage,
    total: ticketsTotal,
    limit: ticketsLimit,
  } = tickets;
  const { code } = used_code;

  useEffect(() => {
    if (userId) {
      loadAppUser(userId);
      loadAppUserGuardian(userId);
      loadAppUserAudit(userId, 1);
      loadAppUserTickets(userId, 1);
      loadAppUserTesting(userId, 1);
    }

    return () => {
      dispatch(actionResetAppUser());
    };
  }, [userId]);

  const ticketsColumns = React.useMemo(
    () => [
      {
        Header: 'Host ID',
        accessor: 'host_id',
      },
      {
        Header: 'Event ID',
        accessor: 'event_id',
      },
      {
        Header: 'Venue',
        accessor: 'venue_address',
      },
      {
        Header: 'Time',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { event_time } = original;

          return moment(event_time).format(TIME_FORMAT);
        },
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { event_time } = original;

          return moment(event_time).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Downloaded',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { downloaded } = original;

          if (downloaded) {
            return <Svg icon={SVG_ICONS.CHECK_CIRCLE} />;
          }

          return '';
        },
      },
      {
        Header: 'Status',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { used } = original;

          return used ? 'Attended' : 'Purchased';
        },
      },
    ],
    []
  );
  const auditColumns = React.useMemo(
    () => [
      {
        Header: 'Action',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { action, event_id } = original;

          return `${action} - ${event_id}`;
        },
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date } = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Time',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date } = original;

          return moment(date).format(TIME_FORMAT);
        },
      },
    ],
    []
  );

  const handleGoToAuditNextPage = async () => {
    const nextPage = auditPage + 1;

    loadAppUserAudit(userId, nextPage);
  };

  const handleGoToAuditPrevPage = async () => {
    const nextPage = auditPage - 1;

    loadAppUserAudit(userId, nextPage);
  };

  const handleGoToTicketsNextPage = async () => {
    const nextPage = ticketsPage + 1;

    loadAppUserTickets(userId, nextPage);
  };

  const handleGoToTicketsPrevPage = async () => {
    const nextPage = ticketsPage - 1;

    loadAppUserTickets(userId, nextPage);
  };

  const renderEditUserButton = () => {
    const allowedEditStatus: string[] = [
      RegistrationStatus.ADMIN_REVIEW,
      RegistrationStatus.REGISTRATION_COMPLETED,
    ];
    const status = mobile_registration_status || registration_status;

    if (allowedEditStatus.includes(status)) {
      return (
        <Button
          title="Update User Detail"
          icon={SVG_ICONS.EDIT}
          onClick={() => setShowEdit(true)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="user">
        <div className="user__head">
          <p>MatchFit ID: {id}</p>
          {renderEditUserButton()}
        </div>
        <div className="user__common">
          <UserInfoItem title="Name" value={name} />
          <UserInfoItem title="Address Line 1" value={address1} />
          <UserInfoItem title="Phone" value={phone} />
          <UserInfoItem title="Address Line 2" value={address2} />
          <UserInfoItem title="Email Address" value={email} />
          <UserInfoItem title="City/Town" value={city} />
          <UserInfoItem title="Date of Birth" value={dob} />
          <UserInfoItem title="Postcode" value={postcode} />
        </div>
        <UserRegistrationDetails
          registration_status={
            mobile_registration_status || registration_status
          }
          fmcScore={fmc_score}
          journeyId={journey_id}
          code={code}
          id={id}
          duplicates={duplicates}
          loadAppUser={loadAppUser}
        />
        <UserNotificationSettings id={id} />
        <UserVaccinationDetails data={vaccination} />
        <UserTestingDetails data={testing} />
        <div className="user__items">
          <div className="user__items-section">
            <div className="user__items-title">Tickets</div>
            <Table
              data={ticketsData}
              columns={ticketsColumns}
              page={ticketsPage}
              total={ticketsTotal}
              limit={ticketsLimit}
              onPrev={handleGoToTicketsPrevPage}
              onNext={handleGoToTicketsNextPage}
            />
          </div>
          <div className="user__items-section">
            <div className="user__items-title">Audit trail</div>
            <Table
              data={auditData}
              page={auditPage}
              total={auditTotal}
              columns={auditColumns}
              limit={auditLimit}
              onNext={handleGoToAuditNextPage}
              onPrev={handleGoToAuditPrevPage}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={showEdit} onClose={() => setShowEdit(false)} noPadding>
        <UserEditForm
          user={data}
          guardian={guardian}
          onClose={() => setShowEdit(false)}
        />
      </Modal>
    </>
  );
};
