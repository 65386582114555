import React, { useMemo } from 'react';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import { DATE_FORMAT } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../Svg/Svg';

import './Datepicker.scss';

interface Props {
  error?: boolean;
  errorMessage?: string;
  value: Date | string;
  title?: string;
  name?: string;
  placeholder?: string;
  returnString?: boolean;
  disabled?: boolean;
  onChange(value: Date | string, name?: string): void;
  showClearBtn?: boolean;
}

export const Datepicker: React.FC<Props> = (props) => {
  const {
    value,
    title,
    name,
    placeholder,
    error,
    returnString,
    disabled,
    onChange,
    showClearBtn = true,
    errorMessage = '',
  } = props;
  const convertedValue = useMemo(() => value ? moment(value).toDate() : undefined, [value]);
  const wrapperClass = classNames('datepicker', {
    'datepicker--disabled': disabled,
    'datepicker--error': !!errorMessage
  });

  const handleChange = (date: Moment | string) => {
    if (returnString) {
      onChange(moment(date).toISOString(), name);

      return;
    }

    onChange && onChange(moment(date).toDate(), name);
  };

  const handleClear = () => {
    onChange && onChange(null, name);
  };

  const checkError = error ? {color: 'red'} : {color: 'black'}

  return (
    <div className={wrapperClass}>
      {title && (
        <div style={checkError} className="datepicker__title">
          {title}
        </div>
      )}
      <div className="datepicker__wrap">
        <Datetime
          value={convertedValue}
          timeFormat={false}
          closeOnSelect
          inputProps={{
            placeholder,
            value: convertedValue
              ? moment(convertedValue).format(DATE_FORMAT)
              : '',
          }}
          onChange={handleChange}
        />
        {showClearBtn && (
          <button
            type="button"
            className="datepicker__clear-btn"
            onClick={handleClear}
          >
            <Svg icon={SVG_ICONS.CLOSE_CIRCLE} />
          </button>
        )}
      </div>
      { errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};
