import React from 'react';

import './Card.scss';

interface Props {
  title: string;
}

export const Card: React.FC<Props> = (props) => {
  const { title, children } = props;

  return (
    <div className="profile-card">
      <div className="profile-card__head">
        <div className="profile-card__title">{title}</div>
      </div>
      <div className="profile-card__body">{children}</div>
    </div>
  );
};
