export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_VACCINES_BASE_URL = process.env.REACT_APP_VACCINATION_API_URL;
export const API_TESTING_BASE_URL = process.env.REACT_APP_TESTING_API_URL;
// export const API_BASE_URL = 'https://stage-api-host.mfpstaging.technology/host';
// export const API_VACCINES_BASE_URL = 'https://stage-api-vaccine.mfpstaging.technology/vaccine';
// export const API_TESTING_BASE_URL = 'https://stage-api-testing.mfpstaging.technology/testing';

export const API_ENDPOINTS = {
  LOGIN: 'auth/signin',
  ME: 'auth/me',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  USER_DATA: 'auth/me',
  APP_USERS: 'app-users',
  GUARDIAN: 'guardian-info',
  ADMIN_USERS: 'admin-users',
  HOSTS: 'hosts',
  TICKETS: 'tickets',
  AUDIT_TRAIL: 'audit-trail',
  TRACKING: 'tracking',
  HOST_USER: 'host-user',
  INVOICES: 'invoices',
  INVOICE_CREATE: 'invoices/schedule',
  BANDS: 'invoices/bands',
  CODES: 'app-user-codes',
  BROADCAST: 'broadcast',
  EVENTS: 'events',
  USERS: 'users',
  APPLICATIONS_LIST: 'applications/list',

  // Notifications
  NOTIFICATIONS_LIST: 'notifications', // TODO: notif api
  NOTIFICATION_VIEW: 'notifications/{id}/view',
  NOTIFICATION_RECEIVER: 'notifications/register-reciever',

  // App Users
  OVERRIDE_STATUS: 'app-users/{mfp_id}/override_status',
  USER_PHOTO: '/photo',
  PHONE_VERIFICATION_STATUS: 'app-users/{mfp_id}/phone_verification_status',
  USER_NOTIFICATION_SETTINGS: 'app-users/{mfp_id}/settings/notification',

  // Entry Criteria
  ENTRY_CRITERIA: '/entry-criteria',

  // Vaccines
  VACCINATION_CENTERS: 'vaccination-centres',
  ADMINISTERED_VACCINES: 'administered-vaccines',
  REGISTERED_VACCINES: 'registered-vaccines',
  VACCINATION_USERS: 'vaccination-users',
  VACCINATION_BROADCAST: 'broadcast/centres',
  VACCINATION_BROADCAST_BATCHES: 'broadcast/centres/{centre_id}/batches',
  VACCINATION_BROADCAST_CODES: 'broadcast/centres/{centre_id}/batches/{id}/identity-codes',
  VACCINATION_BROADCAST_DATES:
    'broadcast/centres/{centre_id}/batches/{batch_id}/identity-codes/{identity_code}/administered-dates',
  VACCINATION_AUDIT: 'vaccination-centres/audit',
  VACCINATION_USER_BATCHES: 'vaccination-users/{mfp_id}/active-batches',
  VACCINATION_USER_INOCULATED: 'vaccination-users/{mfp_id}/users-innoculated',
  VACCINATION_CENTER_BATCHES: 'vaccination-centres/{mfp_id}/active-batches',
  VACCINATION_CENTER_INOCULATED: 'vaccination-centres/{mfp_id}/users-innoculated',

  // Testing
  TESTING: 'testing',
  TESTING_CENTERS: 'testing-centres',
  TESTING_CENTER_REGISTERED: 'testing-centres/{mfp_id}/registered-tests',
  TESTING_CENTER_ADMINISTERED: 'testing-centres/{mfp_id}/administered-tests',
  TESTING_USERS: 'testing-users',
  TESTING_TESTS: 'tests',
  TESTING_ADMINISTERED: 'tests/administered',
  TESTING_REGISTERED: 'tests/registered',
  TESTING_AUDIT: 'testing-centres/audit',
  TESTING_USER_REGISTERED: 'testing-users/{mfp_id}/registered-tests',
  TESTING_USER_ADMINISTERED: 'testing-users/{mfp_id}/administered-tests',
  TESTING_DOCTOR: 'testing-centres/{id}/doctor',
  TESTING_DOCTOR_SIGNATURE: 'testing-centres/{id}/doctor/signature',
  TESTING_BROADCAST_CENTRES: 'broadcast/centres',
  TESTING_BROADCAST_TYPES: 'broadcast/centres/{centre_id}/test-types',
  TESTING_BROADCAST_LOTS: 'broadcast/centres/{centre_id}/test-types/{type}/lot-noms',
  TESTING_BROADCAST_CODES: 'broadcast/centres/{centre_id}/test-types/{type}/lot-noms/{lot_no}/identity-codes',
  TESTING_BROADCAST_DATES:
    'broadcast/centres/{centre_id}/test-types/{type}/lot-noms/{lot_no}/identity-codes/{identity_code}/administered-dates',
};
