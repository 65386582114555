import React from 'react';
import { ROUTES } from '../../../configs/routes';
import { PageHeader } from '../../_common/PageHeader/PageHeader';

interface Props {
  title: string;
  content?: React.ReactNode;
}

export const VaccinationHeader: React.FC<Props> = (props) => {
  const {title, content} = props;

  const pageSections = [
    {
      route: ROUTES.VACCINATION_CENTERS,
      name: 'View Current Vaccination Centers',
    },
    {
      route: ROUTES.VACCINATION_CENTER_CREATE,
      name: 'Add a Vaccination Centre',
    },
    {
      route: ROUTES.VACCINATION_BATCHES,
      name: 'View Vaccine Batches',
    },
    {
      route: ROUTES.VACCINATION_ADMINISTERED,
      name: 'View Administered Vaccines',
    },
  ];

  return (
    <PageHeader
      title={title}
      content={content}
      sections={pageSections}
    />
  );
};
