import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PageHeader } from '../../../../components/_common/PageHeader/PageHeader';
import { HostForm } from '../../../../components/hosts/HostForm/HostForm';
import { Table } from '../../../../components/_common/Table/Table';
import { Tabs } from '../../../../components/_common/Tabs/Tabs';
import { HostUserEditForm } from '../../../../components/hosts/HostUserEditForm/HostUserEditForm';
import { EntryCriteria } from '../../../../components/hosts/EntryCriteria/EntryCriteria';
import { HostUserCreateForm } from '../../../../components/hosts/HostUserCreateForm/HostUserCreateForm';
import { useHostsRequest } from '../../../../hooks/hosts-hooks';
import { ApplicationState } from '../../../../store';
import { HostsState } from '../../../../store/reducers/hosts-reducer';
import {
  Host,
  HostUserEditFormEntity,
  HostUserFormEntity,
} from '../../../../types/hosts';
import { actionResetHost } from '../../../../store/actions/hosts-actions';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import {
  useInvoicesDownload,
  useInvoicesTicketsExport,
} from '../../../../hooks/invoices-hooks';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../../../components/_common/Svg/Svg';

import './HostPage.scss';

export const HostPage: React.FC = () => {
  const dispatch = useDispatch();
  const { downloadInvoice } = useInvoicesDownload();
  const { exportInvoicesTicketsToCsv } = useInvoicesTicketsExport();
  const { host } = useSelector<ApplicationState, HostsState>(
    (state) => state.hosts
  );

  const params: any = useParams();
  const [hostUserIsCreated, setHostUserIsCreated] = useState(false);
  const {
    loadHost,
    updateHost,
    createHostUser,
    loadHostTickets,
    loadHostAudit,
    loadHostUsers,
    updateHostUser,
    loadHostInvoices,
  } = useHostsRequest();
  const hostId = params.id;
  const { data, tickets, audit, invoices } = host;
  const { id } = data;
  const {
    data: auditData,
    page: auditPage,
    total: auditTotal,
    limit: auditLimit,
  } = audit;
  const {
    data: ticketsData,
    page: ticketsPage,
    total: ticketsTotal,
    limit: ticketsLimit,
  } = tickets;

  useEffect(() => {
    loadHost(hostId);
    loadHostUsers(hostId);
    loadHostInvoices(hostId);
    loadHostTickets(hostId, 1);
    loadHostAudit(hostId, 1);

    return () => {
      dispatch(actionResetHost());
    };
  }, [hostId]);

  const ticketsColumns = React.useMemo(
    () => [
      {
        Header: 'Ticket ID',
        accessor: 'ticket_id',
      },
      {
        Header: 'Time',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { event_time } = original;

          return moment(event_time).format(TIME_FORMAT);
        },
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { event_time } = original;

          return moment(event_time).format('YYYY-MM-DD');
        },
      },
      {
        Header: 'Downloaded',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { downloaded } = original;

          if (downloaded) {
            return (
              <div className="host__download-check">
                <Svg icon={SVG_ICONS.CHECK_CIRCLE} />
              </div>
            );
          }

          return null;
        },
      },
      {
        Header: 'Status',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { used } = original;

          return used ? 'Attended' : 'Purchased';
        },
      },
    ],
    []
  );

  const auditColumns = React.useMemo(
    () => [
      {
        Header: 'Action',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { action, ticket_id } = original;

          return `${action} - ${ticket_id}`;
        },
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date } = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Time',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date } = original;

          return moment(date).format(TIME_FORMAT);
        },
      },
    ],
    []
  );

  const invoicesColumns = React.useMemo(
    () => [
      {
        Header: 'Date',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { invoice_date } = original;

          return moment(invoice_date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Invoice',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { id } = original;

          const handleClick = () => {
            downloadInvoice(id);
          };

          return <Button type="empty" title="View" onClick={handleClick} />;
        },
      },
      {
        Header: 'Schedule',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { id } = original;
          const handlerClick = () => {
            console.log(id);
            exportInvoicesTicketsToCsv(id);
            // const result = host.invoices.filter((item)=>{
            //   return item.id === id
            // })
            // CommonUtils.exportItemsToCSV(result, 'Invoice')
          };
          return <Button type="empty" title="View" onClick={handlerClick} />;
        },
      },
    ],
    []
  );

  const handleHostUpdate = async (data: Host) => {
    debugger;
    await updateHost(hostId, data);
  };

  const handleHostUserCreate = async (data: HostUserFormEntity) => {
    setHostUserIsCreated(false);
    const isSaved = await createHostUser(hostId, data);
    setHostUserIsCreated(isSaved);
  };

  const handleHostUserUpdate = (data: HostUserEditFormEntity) => {
    updateHostUser(hostId, data);
  };

  const handleGoToAuditNextPage = async () => {
    const nextPage = auditPage + 1;

    loadHostAudit(hostId, nextPage);
  };

  const handleGoToAuditPrevPage = async () => {
    const nextPage = auditPage - 1;

    loadHostAudit(hostId, nextPage);
  };

  const handleGoToTicketsNextPage = async () => {
    const nextPage = ticketsPage + 1;

    loadHostTickets(hostId, nextPage);
  };

  const handleGoToTicketsPrevPage = async () => {
    const nextPage = ticketsPage - 1;

    loadHostTickets(hostId, nextPage);
  };

  return (
    <div className="host">
      <PageHeader title={`Host ID: ${id}`} />
      <div className="host__content">
        <div className="host__main">
          <HostForm data={data} isEdit onSubmit={handleHostUpdate} />
          <div className="host__invoices">
            <Table data={invoices} columns={invoicesColumns} />
          </div>
        </div>
        <div className="host__entrycriteria">
          <EntryCriteria hostId={hostId} />
        </div>
        <div className="host__forms">
          <Tabs titles={['Create a New User', 'Edit an Active User']}>
            <div>
              <HostUserCreateForm
                isSaved={hostUserIsCreated}
                onSubmit={handleHostUserCreate}
              />
            </div>
            <div>
              <HostUserEditForm onSubmit={handleHostUserUpdate} />
            </div>
          </Tabs>
        </div>
        <div className="host__items">
          <div className="host__items-section">
            <div className="host__items-title">Tickets Issued</div>
            <Table
              data={ticketsData}
              columns={ticketsColumns}
              page={ticketsPage}
              total={ticketsTotal}
              limit={ticketsLimit}
              onPrev={handleGoToTicketsPrevPage}
              onNext={handleGoToTicketsNextPage}
            />
          </div>
          <div className="host__items-section">
            <div className="host__items-title">Audit Trail</div>
            <Table
              data={auditData}
              page={auditPage}
              columns={auditColumns}
              total={auditTotal}
              limit={auditLimit}
              onNext={handleGoToAuditNextPage}
              onPrev={handleGoToAuditPrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
