import { useDispatch } from 'react-redux';
import { API_ENDPOINTS, API_VACCINES_BASE_URL } from '../constants/api';
import { Http } from '../services/http';
import { CommonEntityRes, CommonObject } from '../types/common';
import {
  AdministeredVaccine,
  RegisteredVaccine,
  VaccinationCenter, VaccinationCenterCreateFormEntity,
  VaccinationCenterUser, VaccinationCenterUserUpdate,
  VaccineUserFormEntity
} from '../types/vaccination';
import {
  actionSetAdministeredItems,
  actionSetRegisteredItems,
  actionSetVacCenter,
  actionSetVacCenterBatches,
  actionSetVacCenterInoculated,
  actionSetVacCenters,
  actionSetVaccineUsers
} from '../store/actions/vaccination-actions';
import { UtilsUser } from '../utils/UtilsUser';
import { notification } from '../services/notifications';
import { CommonUtils } from '../utils/CommonUtils';
import { VaccinationUser, VaccinationUserFull } from '../types/users';
import { AdminPermissionsNames } from '../constants/users';

export function useVacCentersLoadingRequest() {
  const dispatch = useDispatch();

  const loadCenters = async (page?: number, searchParams?: CommonObject) => {
    try {
      const {search = ''} = searchParams || {};
      const limit = page ? 10 : 300;
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}?page=${page || ''}&limit=${limit}&search=${search}`;
      const res: CommonEntityRes<VaccinationCenter> = await Http.get(url);

      dispatch(actionSetVacCenters(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadCenters};
}

export function useVacCenterLoadingRequest() {
  const dispatch = useDispatch();

  const loadCenter = async (id: string) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}/${id}`;
      const res: VaccinationCenter = await Http.get(url);
      dispatch(actionSetVacCenter(res));

      const batchesUrl = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTER_BATCHES.replace('{mfp_id}', id)}`;
      const batchesRes = await Http.get(batchesUrl);
      dispatch(actionSetVacCenterBatches(batchesRes.data));

      const inoculatedUrl = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTER_INOCULATED.replace('{mfp_id}', id)}`;
      const inoculatedRes = await Http.get(inoculatedUrl);
      dispatch(actionSetVacCenterInoculated(inoculatedRes.data));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadCenter};
}

export function useVacCenterUpdateRequest() {
  const dispatch = useDispatch();

  const updateCenter = async (id: string, data: VaccinationCenterCreateFormEntity) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}/${id}`;
      const res: VaccinationCenter = await Http.put(url, data);

      notification.showSuccess('Center Is Updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return {updateCenter};
}

export function useVacCenterUserRequest() {
  const dispatch = useDispatch();
  const {loadUsers} = useVacCenterUsersLoadRequest();

  const createUser = async (data: VaccineUserFormEntity, centerId: string) => {
    try {
      const {mfp_id, ...permissions} = data;
      const convertedPermissions = UtilsUser.convertUserPermissions(permissions);
      const filteredPermissions = convertedPermissions.filter(item => {
        return item.name !== AdminPermissionsNames.AdministeredTestTypes
          && item.name !== AdminPermissionsNames.RegisteredTestTypes;
      });
      const requestData: VaccinationCenterUser = {
        mfp_id,
        permissions: filteredPermissions,
      };

      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}/${centerId}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<VaccinationCenter> = await Http.post(url, requestData);

      loadUsers(centerId);
      notification.showSuccess('User is created');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return {createUser};
}

export function useVacCenterUsersLoadRequest() {
  const dispatch = useDispatch();

  const loadUsers = async (centerId: string) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}/${centerId}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<VaccinationUser> = await Http.get(url);

      dispatch(actionSetVaccineUsers(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadUsers};
}

export function useAdministeredItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject) => {
    try {
      const {search} = searchParams;
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.ADMINISTERED_VACCINES}?page=${page}&limit=10&search=${search}`;
      const res = await Http.get(url);

      dispatch(actionSetAdministeredItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadItems};
}

export function useRegisteredItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject) => {
    try {
      const {search} = searchParams;
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.REGISTERED_VACCINES}?page=${page}&limit=10&search=${search}`;
      const res = await Http.get(url);

      dispatch(actionSetRegisteredItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadItems};
}

export function useRegisteredExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.REGISTERED_VACCINES}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<RegisteredVaccine> = await Http.get(url);

      CommonUtils.exportItemsToCSV(foundItems.data, 'Registered Vaccines');
    } catch (e) {
      console.log(e)
    }
  };

  return {exportItemsToCsv};
}

export function useAdministeredExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.ADMINISTERED_VACCINES}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<AdministeredVaccine> = await Http.get(url);

      CommonUtils.exportItemsToCSV(foundItems.data, 'Administered Vaccines');
    } catch (e) {
      console.log(e)
    }
  };

  return {exportItemsToCsv};
}

export function useVacCenterGetUserRequest() {
  const getUser = async (id: string) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USERS}/${id}`;
      const res: VaccinationUserFull = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  return {getUser};
}

export function useVacCenterUserUpdateRequest() {
  const updateUser = async (data: VaccineUserFormEntity) => {
    try {
      const {mfp_id, ...permissions} = data;
      const convertedPermissions = UtilsUser.convertUserPermissions(permissions);
      const filteredPermissions = convertedPermissions.filter(item => {
        return item.name !== AdminPermissionsNames.AdministeredTestTypes
          && item.name !== AdminPermissionsNames.RegisteredTestTypes;
      });
      const requestData: VaccinationCenterUserUpdate = {
        permissions: filteredPermissions,
      };

      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_USERS}/${mfp_id}`;
      await Http.patch(url, requestData);

      notification.showSuccess('User is updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return {updateUser};
}

export function useVacCenterCreate() {
  const createCenter = async (data: VaccinationCenterCreateFormEntity) => {
    try {
      const url = `${API_VACCINES_BASE_URL}/${API_ENDPOINTS.VACCINATION_CENTERS}`;
      await Http.post(url, data);

      notification.showSuccess('Center Is Created');
      return true;
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
      return false;
    }
  };

  return {createCenter};
}
