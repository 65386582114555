import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { UserInfoItem } from '../../../../components/users/UserInfoItem/UserInfoItem';
import { Tabs } from '../../../../components/_common/Tabs/Tabs';
import { Table } from '../../../../components/_common/Table/Table';
import { useVaccinationUserRequest } from '../../../../hooks/users-hooks';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { ApplicationState } from '../../../../store';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/common';

import './VaccinationUserPage.scss';

export const VaccinationUserPage: React.FC = () => {
  const params: any = useParams();
  const userId = params.id;
  const {loadUser} = useVaccinationUserRequest();
  const usersState = useSelector<ApplicationState, UsersState>(state => state.users);
  const {vaccinationUser} = usersState;
  const {data, batches, inoculated} = vaccinationUser;
  const {email, name, phone} = data || {};

  useEffect(() => {
    loadUser(userId);
  }, [userId]);

  const batchesColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
    ],
    [],
  );

  const usersColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Date Vaccine',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {inoculation_time} = original;

          return moment(inoculation_time).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Time Inoculated',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {inoculation_time} = original;

          return moment(inoculation_time).format(TIME_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
    ],
    [],
  );

  return (
    <div className="v-user">
      <div className="v-user__head">MatchFit ID: {userId}</div>
      <div className="v-user__common">
        <UserInfoItem title="Name" value={name} />
        <UserInfoItem title="Phone Number" value={phone} />
        <UserInfoItem title="Email Address" value={email} />
      </div>
      <div className="v-user__status">Inoculation Administered</div>
      <Tabs titles={['Active Batches', 'User\'s Inoculated']}>
        <div>
          <Table
            data={batches}
            columns={batchesColumns}
          />
        </div>
        <div>
          <Table
            data={inoculated}
            columns={usersColumns}
          />
        </div>
      </Tabs>
    </div>
  );
};
