import React from 'react';
import moment from 'moment';
import { UserInfoItem } from '../UserInfoItem/UserInfoItem';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { AppUserVaccinationEntity } from '../../../types/users';
import { DATE_FORMAT } from '../../../constants/common';

import './UserVaccinationDetails.scss';

interface Props {
  data: AppUserVaccinationEntity;
}

export const UserVaccinationDetails: React.FC<Props> = (props) => {
  const { data } = props;
  const { mfp_user, vaccine_centre, vaccine_check, centre, date_inoculated, batch_number, identity_code } = data;
  const handledDate = date_inoculated ? moment(date_inoculated).format(DATE_FORMAT) : '';
  const centreName = centre ? centre.name : '';

  return (
    <div className="uv-details">
      <h2 className="uv-details__title">Vaccination</h2>
      <div className="uv-details__info">
        <UserInfoItem title="Vaccination Centre" value={centreName} />
        <UserInfoItem title="Date Inoculated" value={handledDate} />
        <UserInfoItem title="Batch Number" value={batch_number || ''} />
        <UserInfoItem title="Identity Code" value={identity_code || ''} />
      </div>
      <div className="uv-details__checks">
        <Checkbox checked={vaccine_check} text="Vaccine Check" />
        <Checkbox checked={vaccine_centre} text="Vaccine Centre" />
        <Checkbox checked={mfp_user} text="MFP User" />
      </div>
    </div>
  );
};
