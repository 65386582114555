export enum InputTypes {
  Password = 'password',
  Text = 'text',
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const TIME_FORMAT = 'HH:mm';

export enum NotificationTypes {
  LFT_REVIEW = 'LFT_REVIEW',
  USER_REVIEW = 'USER_APPLICATION_REVIEW',
}
