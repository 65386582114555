export const notification = {
  showError: (message: string) => {
    const errorMessage = message && typeof message === 'string' ? message : 'Something went wrong';

    alert(errorMessage);
  },
  showSuccess: (message: string) => {
    alert(message);
  },
};
