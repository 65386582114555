import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { FormFooter } from '../../_common/Form/FormFooter/FormFooter';
import { Button } from '../../_common/_controls/Button/Button';
import { Code, CodeDetails } from '../../../types/codes';
import { codeCreateDefaults } from '../../../data/defaults';
import { ApplicationState } from '../../../store';
import { HostsState } from '../../../store/reducers/hosts-reducer';
import { UtilsCodes } from '../../../utils/UtilsCodes';

import './CodeForm.scss';
import HostSelectMenu from '../../hosts/HostSelectMenu/HostSelectMenu';

interface Props {
  data: Code;
  isNew: boolean;
  onSubmit: (data: CodeDetails) => void;
}

export const CodeForm: React.FC<Props> = (props) => {
  const {data, isNew, onSubmit} = props;
  const hostsState = useSelector<ApplicationState, HostsState>(state => state.hosts);
  const [formData, setFormData] = useState<CodeDetails>(codeCreateDefaults);
  const [allHosts, setAllHosts] = useState(false);
  const {voucher_name, voucher_code, expiration_date, assigned_to_host_id, one_time_usage, max_codes} = formData;
  const {hostsItems} = hostsState;
  const actionText = isNew ? 'Create' : 'Update';
console.log(data)
  useEffect(() => {
    const newData = UtilsCodes.convertCodeToCodeDetails(data);
    setFormData(newData);
    if(data.assigned_to_host_id === false ||
      data.assigned_to_host_id === null) {
      setAllHosts(true)
    }
  }, [data.id]);

  const handleFieldChange = (value: any, fieldName: string) => {
    let newValue = value;
    const newFormData = {...formData};

    if (fieldName === 'max_codes') {
      newValue = value ? parseInt(value) : '';
    }

    if (fieldName === 'one_time_usage') {
      newFormData.max_codes = 1;
    }

    setFormData({
      ...newFormData,
      [fieldName]: newValue,
    });
  };
  const handleAllHostsChange = (value) => {
      setAllHosts(value);
  };

  const handleFormSubmit = () => {
    const finalData = {...formData};

    if (one_time_usage) {
      delete finalData.expiration_date;
    }

    if (allHosts === true) {
      delete finalData.assigned_to_host_id;
    }
console.log(finalData)
    onSubmit(finalData);
  };

  return (
    <Form className="code-form" onSubmit={handleFormSubmit}>
      <FormGroup fullWidth>
        <Input
          title="Voucher Name"
          placeholder="Voucher Name"
          value={voucher_name}
          name="voucher_name"
          onChange={handleFieldChange}
        />
        <Input
          title="Voucher Code"
          placeholder="Voucher Code"
          value={voucher_code}
          name="voucher_code"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormGroup title="Assigned To">
        <Checkbox
          checked={allHosts}
          text="All"
          name="allHosts"
          onChange={handleAllHostsChange}
        />
        {/* <SelectMenu
          title="Host"
          placeholder="Host"
          value={assigned_to_host_id}
          name="assigned_to_host_id"
          labelKey="name"
          valueKey="id"
          disabled={allHosts}
          options={hostsItems.data}
          onChange={handleFieldChange}
        /> */}
        <HostSelectMenu 
          // initial_host_id={hostsState?.host?.data?.parent_host_id}
          disabled={allHosts}
          onChange={(val) => {
            handleFieldChange(val, 'assigned_to_host_id');
          }}
        />
      </FormGroup>
      <FormGroup title="Usage">
        <Checkbox
          text="One Time Usage"
          checked={one_time_usage}
          name="one_time_usage"
          onChange={handleFieldChange}
        />
        <Datepicker
          title="Expiry Date"
          placeholder="Expiry Date"
          value={expiration_date}
          name="expiration_date"
          returnString
          disabled={one_time_usage}
          onChange={handleFieldChange}
        />
        <Input
          title="Max Codes"
          placeholder="Max Codes"
          value={max_codes}
          name="max_codes"
          htmlType="number"
          disabled={one_time_usage}
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormFooter>
        <Button title={actionText} htmlType="submit" />
      </FormFooter>
    </Form>
  );
};
