import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SelectMenu } from '../../../_common/_controls/SelectMenu/SelectMenu';
import { CheckboxGroup } from '../../../_common/_controls/Checkbox/Checkbox';
import { Input } from '../../../_common/_controls/Input/Input';
import { Field } from '../../../_common/_controls/Field/Field';
import {
  VACCINE_STATUS,
  VaccineStatus,
  VACCINE_DOSES,
  VaccineDoses,
} from '../../../../constants/vaccines';

import '../index.scss';

interface Props {
  form: any;
}

const CriteriaVaccineForm: React.FC<Props> = ({ form }) => {
  const { control, setValue } = form;
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const vaccineStatus = useWatch({
    control,
    name: 'vaccination_status.status',
  });

  useEffect(() => {
    if (vaccineStatus === VACCINE_STATUS.NOT_REQUIRED) {
      setIsRequired(false);
      setValue('vaccination_status.dose_count', VACCINE_DOSES.NOT_REQUIRED);
      setValue('vaccination_status.min_vaccine_age', 0);
      setValue('vaccination_status.max_vaccine_age', 0);
    } else {
      setIsRequired(true);
    }
  }, [vaccineStatus]);

  const vaccineStatuses = [
    {
      label: VaccineStatus[VACCINE_STATUS.REQUIRED],
      value: VACCINE_STATUS.REQUIRED,
    },
    {
      label: VaccineStatus[VACCINE_STATUS.NOT_REQUIRED_WITH_TEST_RESULT],
      value: VACCINE_STATUS.NOT_REQUIRED_WITH_TEST_RESULT,
    },
    {
      label: VaccineStatus[VACCINE_STATUS.NOT_REQUIRED],
      value: VACCINE_STATUS.NOT_REQUIRED,
    },
  ];

  const dosesRequired = [
    {
      label: VaccineDoses[VACCINE_DOSES.NOT_REQUIRED],
      value: VACCINE_DOSES.NOT_REQUIRED,
    },
    {
      label: VaccineDoses[VACCINE_DOSES.PARTIAL],
      value: VACCINE_DOSES.PARTIAL,
    },
    {
      label: VaccineDoses[VACCINE_DOSES.COMPLETED],
      value: VACCINE_DOSES.COMPLETED,
    },
    {
      label: VaccineDoses[VACCINE_DOSES.BOOSTER],
      value: VACCINE_DOSES.BOOSTER,
    },
  ];

  return (
    <div className="entry-form-criteria">
      <Field
        control={control}
        name="vaccination_status.status"
        component={SelectMenu}
        options={vaccineStatuses}
        title="Vaccination Status"
        labelKey="label"
        valueKey="value"
        disableSearch
      />
      <div className="divider" />
      {/* divider */}
      <div className={isRequired ? '' : 'entry-form-criteria__disabled'}>
        <Field
          control={control}
          name="vaccination_status.dose_count"
          component={SelectMenu}
          options={dosesRequired}
          title="Number of Doses Required"
          labelKey="label"
          valueKey="value"
          disableSearch
          disabled={!isRequired}
        />
        <Field
          control={control}
          name="vaccination_status.min_vaccine_age"
          component={Input}
          rules={{
            required: isRequired,
            min: isRequired ? 1 : 0, // Fix for min validation when NOT_REQUIRED
            max: 50, //adjust if needed
          }}
          title="Minimum Vaccination Age"
          htmlType="number"
          endAdornment={<p>week/s from latest dose</p>}
          disabled={!isRequired}
        />
        <Field
          control={control}
          name="vaccination_status.max_vaccine_age"
          component={Input}
          rules={{
            required: isRequired,
            min: isRequired ? 1 : 0, // Fix for min validation when NOT_REQUIRED
            max: 50, //adjust if needed
          }}
          title="Maximum Vaccination Age"
          htmlType="number"
          endAdornment={<p>month/s from latest dose</p>}
          disabled={!isRequired}
        />
      </div>
    </div>
  );
};

export default CriteriaVaccineForm;
