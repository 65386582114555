import React, { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { Modal } from '../../_common/Modal/Modal';
import { Tabs } from '../../_common/Tabs/Tabs';
import { Form } from '../../_common/Form/Form';
import { SVG_ICONS } from '../../_common/Svg/Svg';
import { Button } from '../../_common/_controls/Button/Button';
import {
  CriteriaAgeRestrictionForm,
  CriteriaVaccineForm,
  CriteriaTestingForm,
  CriteriaEntryTimingForm,
} from '../criteriaForms';
import { useEntryCriteriaRequests } from '../../../hooks/hosts-hooks';

import './EditEntryCriteriaModal.scss';
import { HostEntryCriteria } from '../../../types/hosts';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: HostEntryCriteria) => void;
  initialValues: any;
}

const EditEntryCriteriaModal: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  initialValues,
}) => {
  const { handleSubmit, reset, ...formProps } = useForm({
    defaultValues: useMemo(() => {
      return initialValues;
    }, [initialValues]),
  });
  const { control } = formProps;
  const { errors } = useFormState({ control });

  // Listen to changes in props then reset. Fetch E.C values triggers this)
  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const onSaveCriteria = async (formValues) => {
    onSubmit(formValues);
  };

  const onModalClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={visible} onClose={onModalClose} noPadding>
      <div className="criteria-form">
        {/* header */}
        <div className="criteria-form__header">
          <p>Entry Criteria</p>
          <Button
            icon={SVG_ICONS.CLOSE}
            title=""
            type="empty"
            onClick={onModalClose}
          />
        </div>
        <div className="criteria-form__content">
          <Form onSubmit={handleSubmit(onSaveCriteria)}>
            <Tabs
              titles={[
                'Age Restriction',
                'Covid-19 Vaccination',
                'Covid-19 Testing',
                ...(initialValues.entry_timing ? ['Entry and Timing'] : []),
              ]}
            >
              <div className="form-tab">
                <CriteriaAgeRestrictionForm form={formProps} />
              </div>
              <div className="form-tab">
                <CriteriaVaccineForm form={formProps} />
              </div>
              <div className="form-tab">
                <CriteriaTestingForm form={formProps} />
              </div>
              {initialValues.entry_timing && (
                <div className="form-tab">
                  <CriteriaEntryTimingForm form={formProps} />
                </div>
              )}
            </Tabs>
            <div className="form-actions">
              <Button
                title="Cancel"
                variant="outlined"
                type="purple"
                onClick={onModalClose}
              />
              <Button
                title="Undo changes"
                variant="outlined"
                type="purple"
                onClick={() => reset()}
              />
              <Button title="Save criteria" type="purple" htmlType="submit" />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default EditEntryCriteriaModal;
