import { Code, CodeDetails } from '../types/codes';

export const UtilsCodes = {
  convertCodeToCodeDetails: (data: Code): CodeDetails => {
    const {voucher_name, voucher_code, assigned_to_host_id, expiration_date, one_time_usage, max_codes, allHosts} = data;

    return {
      assigned_to_host_id,
      expiration_date,
      voucher_code,
      voucher_name,
      one_time_usage,
      max_codes,
    }
  },
};
