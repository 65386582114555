import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import './App.scss';
import './assets/styles/main.scss';
import { Svg, SVG_ICONS } from './components/_common/Svg/Svg';
import { URLS } from './constants/urls';
import { AdminPermissionsNames } from './constants/users';
import { getMessageListener, useInitializeFirebase } from './hooks/firebase-hooks';
import { viewNotification } from './hooks/notification-hooks';
import { useUserPermissions } from './hooks/user-hooks';
import Router from './Router';
import { ApplicationState, store } from './store';
import { CommonState } from './store/reducers/common-reducer';
import { UtilsUser } from './utils/UtilsUser';

require('dotenv').config();

export const queryClient = new QueryClient({
  // TODO: Tune default config
  // https://react-query.tanstack.com/guides/important-defaults
  // https://react-query.tanstack.com/reference/useQuery
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

export default function App() {

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactApp />
      </QueryClientProvider>
    </Provider>
  );
}

const ReactApp: React.FC = () => {
  const { fcmInstance } = useSelector<ApplicationState, CommonState>((state) => state.common);
  const { hasPermissions } = useUserPermissions();
  React.useEffect(() => {
    if (fcmInstance) {
      getMessageListener(fcmInstance)
        .then((payload: any) => {
          console.log({ payload });
          toast(getToast(payload.data.userId), {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            onClick: () => openNotification(payload.messageId, payload.data.userId),
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => console.log('failed: ', err));
    } else {
      if (UtilsUser.isUserLoggedIn()) {
        useInitializeFirebase();
      }
    }
  }, [fcmInstance]);

  const openNotification = async (msgId, mfpId) => {
    await viewNotification(msgId);
    if (hasPermissions(AdminPermissionsNames.Users)) {
      window.location.assign(`${URLS.USER}/${mfpId}`);
    } else {
      toast.warn('Restricted access.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getToast = (mfpId) => {
    return (
      <div className="custom-toast">
        <Svg className="custom-toast__icon" icon={SVG_ICONS.NOTIFICATION_WHITE} />
        <h5 className="custom-toast__text">
          User Account Application for Review. User ID No. <span className="custom-toast__mfpid">{mfpId}</span>
        </h5>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router />
    </React.Fragment>
  );
};
