import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavMenuItem } from '../_common/NavMenuItem/NavMenuItem';
import { SVG_ICONS } from '../_common/Svg/Svg';
import { ROUTES } from '../../configs/routes';
import { useUserPermissions } from '../../hooks/user-hooks';
import { AdminPermissionsNames } from '../../constants/users';

import './Sidebar.scss';
import { URLS } from '../../constants/urls';

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const {hasPermissions} = useUserPermissions();
  const currentRoute = location.pathname;
  const usersItemIsActive = currentRoute.indexOf('user') !== -1;
  const hostItemIsActive = currentRoute.indexOf('host') !== -1;
  const auditItemIsActive = currentRoute.indexOf('audit') !== -1;
  const codesItemIsActive = currentRoute.indexOf('code') !== -1;
  const invoiceItemIsActive = currentRoute.indexOf('invoice') !== -1;
  const vaccinationItemIsActive = currentRoute.indexOf('vaccination') !== -1;
  const testingItemIsActive = currentRoute.indexOf(URLS.TESTING) !== -1
    && currentRoute.indexOf(URLS.TESTING_USER) === -1
    && currentRoute.indexOf(URLS.AUDIT) === -1;

  const isUserCanUseUsers = hasPermissions(AdminPermissionsNames.Users);
  const isUserCanUseHosts = hasPermissions(AdminPermissionsNames.Hosts);
  const isUserCanUseAudit = hasPermissions(AdminPermissionsNames.Audit);
  const isUserCanUseInvoice = hasPermissions(AdminPermissionsNames.Invoice);
  const isUserCanUseBroadcast = hasPermissions(AdminPermissionsNames.BroadcastMessage);
  const isUserCanUseVaccination = hasPermissions(AdminPermissionsNames.Vaccination);
  const isUserCanUseTesting = hasPermissions(AdminPermissionsNames.CovidTesting);
  const isUserCanUseCodes = hasPermissions(AdminPermissionsNames.Codes);

  return (
    <div className="sidebar">
      <div className="sidebar__menu">
        <NavMenuItem
          route={ROUTES.USERS}
          icon={SVG_ICONS.USER}
          title="Users"
          isActive={usersItemIsActive}
          displayed={isUserCanUseUsers}
        />
        <NavMenuItem
          route={ROUTES.HOSTS}
          icon={SVG_ICONS.USERS}
          title="Hosts"
          isActive={hostItemIsActive}
          displayed={isUserCanUseHosts}
        />
        <NavMenuItem
          route={ROUTES.VACCINATION_CENTERS}
          icon={SVG_ICONS.GLOBE}
          title="Vaccination"
          isActive={vaccinationItemIsActive}
          displayed={isUserCanUseVaccination}
        />
        <NavMenuItem
          route={ROUTES.TESTING_CENTERS}
          icon={SVG_ICONS.TEST}
          title="Testing"
          isActive={testingItemIsActive}
          displayed={isUserCanUseTesting}
        />
        <NavMenuItem
          route={ROUTES.AUDIT_TRAIL}
          icon={SVG_ICONS.BOOK}
          title="Audit Trail"
          isActive={auditItemIsActive}
          displayed={isUserCanUseAudit}
        />
        <NavMenuItem
          route={ROUTES.INVOICE}
          icon={SVG_ICONS.TICKET}
          title="Invoice"
          isActive={invoiceItemIsActive}
          displayed={isUserCanUseInvoice}
        />
        <NavMenuItem
          route={ROUTES.BROADCAST_MESSAGE}
          icon={SVG_ICONS.COMMENT}
          title="Broadcast Message"
          isActive={currentRoute === ROUTES.BROADCAST_MESSAGE}
          displayed={isUserCanUseBroadcast}
        />
        <NavMenuItem
          route={ROUTES.CODES}
          icon={SVG_ICONS.CLOUD}
          title="User Codes"
          isActive={codesItemIsActive}
          displayed={isUserCanUseCodes}
        />
      </div>
      <div className="sidebar__actions" />
    </div>
  );
};
