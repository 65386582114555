import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { VaccinationHeader } from '../../../../components/vaccination/VaccinationHeader/VaccinationHeader';
import { Table } from '../../../../components/_common/Table/Table';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import { useRegisteredExport, useRegisteredItemsRequest } from '../../../../hooks/vaccination-hooks';
import { CommonObject } from '../../../../types/common';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { VaccinationState } from '../../../../store/reducers/vaccination-reducer';
import { DATE_FORMAT } from '../../../../constants/common';

export const VaccinationBatchesPage: React.FC = () => {
  const {loadItems} = useRegisteredItemsRequest();
  const {exportItemsToCsv} = useRegisteredExport();
  const vaccinesState = useSelector<ApplicationState, VaccinationState>(state => state.vaccination);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {search} = searchParams;
  const {registeredVaccines} = vaccinesState;
  const {page = 1, total = 0, limit, data} = registeredVaccines;

  useEffect(() => {
    loadItems(1, searchParams);
  }, []);

  const searchForItems = useCallback(debounce(async (params: CommonObject) => {
    loadItems(1, params);
  }, 400), []);

  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForItems(newParams);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Date',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {date} = original;

          return moment(date).format(DATE_FORMAT);
        },
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
    ],
    [],
  );

  const handleExport = () => {
    exportItemsToCsv(searchParams.search, total);
  };

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadItems(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadItems(prevPage, searchParams);
  };

  return (
    <div>
      <VaccinationHeader
        title="View Vaccine Batches"
        content={(
          <div className="export-wrap">
            <Input placeholder="Search batches" value={search} search onChange={handleSearch} />
            <div className="export-wrap__actions">
              <div className="audit-header__export-title">Export Search to Report</div>
              <Button title="Export" onClick={handleExport} />
            </div>
          </div>
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
