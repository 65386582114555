import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { CommonEntityRes, CommonObject, EntityCursorResponse } from '../types/common';
import {
  Host,
  HostEntryCriteria,
  HostUser,
  HostUserCreateEditEntity,
  HostUserEditFormEntity,
  HostUserFormEntity,
} from '../types/hosts';
import {
  actionSetHost,
  actionSetHostAudit,
  actionSetHostInvoices,
  actionSetHosts,
  actionSetHostTickets,
  actionSetHostUsers,
} from '../store/actions/hosts-actions';
import { ROUTES } from '../configs/routes';
import { notification } from '../services/notifications';
import { AppUserTicket } from '../types/users';
import { Audit } from '../types/audit';
import { ApplicationState } from '../store';
import { HostsState } from '../store/reducers/hosts-reducer';
import { AdminPermissionsNames } from '../constants/users';
import { useInfiniteQuery, useQuery } from 'react-query';
import { API } from '../services/axios';

export const getHosts = async (props: {
  search?: string,
  limit?: number,
  afterCursor?: string
}) => {
  try {
    const { search = '', limit = 10, afterCursor } = props;
    const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/v2`; 
    const res = await API.get(url, { 
      params: {
        limit,
        search,
        afterCursor
      }
    });
    const data: EntityCursorResponse<Host> = res?.data;
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export const useHostsSearch = (search: string, limit?: number) => {
  return useInfiniteQuery(
    ['hosts-search', search], 
    (props) => getHosts({ search, limit, afterCursor: props.pageParam }),
    { 
      getNextPageParam: (page) => page?.cursor.afterCursor,
      getPreviousPageParam: (page) => page?.cursor.beforeCursor 
    }
  )
}

export function useHostsRequest() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { host } = useSelector<ApplicationState, HostsState>(
    (state) => state.hosts
  );
  const { users } = host;

  const loadHosts = async (page?: number, searchParams?: CommonObject) => {
    try {
      const { search = '' } = searchParams || {};
      const limit = page ? 10 : 300;
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}?page=${
        page || ''
      }&limit=${limit}&search=${search}`;
      const res: CommonEntityRes<Host> = await Http.get(url);

      dispatch(actionSetHosts(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadHost = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${id}`;
      const res: Host = await Http.get(url);
      const tickets: Host = await Http.get(`${url}/${API_ENDPOINTS.TICKETS}`);

      dispatch(actionSetHost(res));
    } catch (e) {
      console.log(e);
    }
  };

  const updateHost = async (id: string, data: Host) => {
    try {
      delete data.configuration_id;
      delete data.entry_criteria_id;
      const { id, created_at, updated_at, band, ...restFields } = data;
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${id}`;
      await Http.put(url, restFields);

      alert('Host is updated');
    } catch (e) {
      console.log(e);
    }
  };

  const createHost = async (data: Host) => {
    try {
      setIsLoading(true);
      const { id, created_at, updated_at, band, ...restFields } = data;
      const requestData = { ...restFields };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}`;

      await Http.post(url, requestData);

      setIsLoading(false);
      push(ROUTES.HOSTS);
    } catch (e) {
      setIsLoading(false);
      notification.showError(e.message);
    }
  };

  const updateHostUser = async (
    hostId: string,
    data: HostUserEditFormEntity
  ) => {
    try {
      const { id, ...permissions } = data;
      console.log(permissions);
      const userData = users.find((item) => item.id === id);
      const { email, name, phone } = userData;
      const permissionsArr = Object.keys(permissions).map((key) => {
        return {
          name: AdminPermissionsNames[key],
          value: permissions[key],
        };
      });
      const requestData = {
        permissions: permissionsArr,
        email,
        name,
        phone,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}/${API_ENDPOINTS.HOST_USER}/${id}`;
      await Http.put(url, requestData);

      alert('Host user is updated');
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };

  const loadHostTickets = async (userId: string, page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${userId}/${API_ENDPOINTS.TICKETS}?page=${page}&limit=10`;
      const tickets: CommonEntityRes<AppUserTicket> = await Http.get(url);

      dispatch(actionSetHostTickets(tickets));
    } catch (e) {
      console.log(e);
    }
  };

  const loadHostAudit = async (userId: string, page: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${userId}/${API_ENDPOINTS.AUDIT_TRAIL}?page=${page}&limit=10`;
      const audits: CommonEntityRes<Audit> = await Http.get(url);

      dispatch(actionSetHostAudit(audits));
    } catch (e) {
      console.log(e);
    }
  };

  const loadHostUsers = async (hostId: string, limit = 100) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}/${API_ENDPOINTS.HOST_USER}?limit=${limit}`;
      const res: CommonEntityRes<HostUser> = await Http.get(url);

      dispatch(actionSetHostUsers(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  const createHostUser = async (hostId: string, data: HostUserFormEntity) => {
    try {
      const { mfp_id, ...permissions } = data;
      const permissionsArr = Object.keys(permissions).map((key) => {
        return {
          name: AdminPermissionsNames[key],
          value: permissions[key],
        };
      });
      const requestData: HostUserCreateEditEntity = {
        mfp_id,
        permissions: permissionsArr,
      };
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}/${API_ENDPOINTS.HOST_USER}`;
      await Http.post(url, requestData);

      loadHostUsers(hostId);
      alert('Host user is created');

      return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  const loadHostInvoices = async (hostId: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}/${API_ENDPOINTS.INVOICES}`;
      const res = await Http.get(url);

      dispatch(actionSetHostInvoices(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  return {
    isLoading,
    loadHosts,
    createHost,
    loadHost,
    updateHost,
    createHostUser,
    loadHostTickets,
    loadHostAudit,
    loadHostUsers,
    updateHostUser,
    loadHostInvoices,
  };
}

export function useEntryCriteriaRequests() {
  const loadEntryCriteria = async (hostId: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}${API_ENDPOINTS.ENTRY_CRITERIA}`;
      const res = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const updateEntryCriteria = async (
    hostId: string,
    data: HostEntryCriteria
  ) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.HOSTS}/${hostId}${API_ENDPOINTS.ENTRY_CRITERIA}`;
      const res = await Http.put(url, data);
      if (res?.error || res?.statucCode === 400) {
        throw new Error(res?.message || 'Updating entry criteria failed');
      } else return true;
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false;
    }
  };

  return {
    loadEntryCriteria,
    updateEntryCriteria,
  };
}
