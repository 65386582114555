import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SelectMenu } from '../../../_common/_controls/SelectMenu/SelectMenu';
import { RadioGroup } from '../../../_common/_controls/Checkbox/Checkbox';
import { Input } from '../../../_common/_controls/Input/Input';
import { Field } from '../../../_common/_controls/Field/Field';
import '../index.scss';
import {
  EntryTiming,
  EntryTypes,
  ENTRY_TIMING,
  ENTRY_TYPES,
} from '../../../../constants/entrycriteria';

interface Props {
  form: any;
}

const CriteriaEntryTimingForm: React.FC<Props> = ({ form }) => {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const { control, setValue } = form;

  const entryTiming = useWatch({
    control,
    name: 'entry_timing.timing_of_entry',
  });

  useEffect(() => {
    if (entryTiming === ENTRY_TIMING.ALL_DAY) {
      setIsRequired(false);
      setValue('entry_timing.before_start_time', 0);
      setValue('entry_timing.after_start_time', 0);
    } else {
      setIsRequired(true);
    }
  }, [entryTiming]);

  const entryTypeOptions = [
    {
      label: EntryTypes[ENTRY_TYPES.UNIQUE_TICKET_REQUIRED],
      value: ENTRY_TYPES.UNIQUE_TICKET_REQUIRED,
    },
    {
      label: EntryTypes[ENTRY_TYPES.UNIQUE_TICKET_GUEST_LIST],
      value: ENTRY_TYPES.UNIQUE_TICKET_GUEST_LIST,
    },
    {
      label: EntryTypes[ENTRY_TYPES.GUEST_LIST],
      value: ENTRY_TYPES.GUEST_LIST,
    },
    {
      label: EntryTypes[ENTRY_TYPES.SQRC],
      value: ENTRY_TYPES.SQRC,
    },
  ];

  const entryTimingOptions = [
    {
      label: EntryTiming[ENTRY_TIMING.ALL_DAY],
      value: ENTRY_TIMING.ALL_DAY,
    },
    {
      label: EntryTiming[ENTRY_TIMING.SPECIFIC],
      value: ENTRY_TIMING.SPECIFIC,
    },
  ];

  return (
    <div className="entry-form-criteria">
      <Field
        control={control}
        name="entry_timing.type_of_entry"
        component={SelectMenu}
        options={entryTypeOptions}
        title="Type of Entry"
        disableSearch
      />
      <div className="divider" />
      <Field
        control={control}
        name="entry_timing.timing_of_entry"
        component={RadioGroup}
        options={entryTimingOptions}
        title="Time of Entry"
        labelKey="label"
        valueKey="value"
      />
      <div className={isRequired ? '' : 'entry-form-criteria__disabled'}>
        <Field
          control={control}
          name="entry_timing.before_start_time"
          component={Input}
          title="Before Event Start Time"
          htmlType="number"
          rules={{
            required: isRequired,
            min: isRequired ? 1 : 0, // Fix for min validation when NOT_REQUIRED
            max: 1000, //adjust if needed
          }}
          endAdornment={<p>minutes</p>}
          disabled={!isRequired}
        />
        <Field
          control={control}
          name="entry_timing.after_start_time"
          component={Input}
          title="After Event Start Time"
          htmlType="number"
          rules={{
            required: isRequired,
            min: isRequired ? 1 : 0, // Fix for min validation when NOT_REQUIRED
            max: 1000, //adjust if needed
          }}
          endAdornment={<p>minutes</p>}
          disabled={!isRequired}
        />
      </div>
    </div>
  );
};

export default CriteriaEntryTimingForm;
