import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../../components/_common/PageHeader/PageHeader';
import { Button } from '../../../../components/_common/_controls/Button/Button';
import { ROUTES } from '../../../../configs/routes';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { useUsersRequest } from '../../../../hooks/users-hooks';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import moment from 'moment';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { DATE_FORMAT } from '../../../../constants/common';

export const MfpUsersPage: React.FC = () => {
  const {push} = useHistory();
  const {loadAdminUsers} = useUsersRequest();
  const {adminUsers} = useSelector<ApplicationState, UsersState>((state) => state.users);
  const {data, total, limit} = adminUsers;
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadAdminUsers(page);
  }, []);

  const pageSections = [
    {
      route: ROUTES.USERS,
      name: 'View Current App Users',
    },
    {
      route: ROUTES.MFP_USERS,
      name: 'View MFP Users',
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Last Active',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {last_active} = original;

          if (!last_active) return '';

          return moment(last_active).format(DATE_FORMAT);
        },
      },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.MFP_USER}/${id}`;

          return (
            <Link to={url}>View details</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadAdminUsers(nextPage);
    setPage(nextPage);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadAdminUsers(prevPage);
    setPage(prevPage);
  };

  const handleCreateNew = () => {
    push(URLS.MFP_USER);
  };

  return (
    <div>
      <UsersHeader
        title="MFP Users"
        content={<Button title="Create New User" onClick={handleCreateNew} />}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
