import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

enum CHECKBOX_TYPES {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}
interface CheckboxProps {
  checked: boolean;
  name?: string;
  minified?: boolean;
  disabled?: boolean;
  text: React.ReactNode;
  type?: CHECKBOX_TYPES;
  onChange?: (value: boolean, name: string) => void;
}

interface CheckboxGroupProps {
  options: any;
  labelKey: string;
  valueKey: string;
  onChange: (value: any) => void;
  title?: string;
  value: any;
  error: any;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    checked,
    text,
    name = '',
    minified,
    disabled,
    type = CHECKBOX_TYPES.CHECKBOX,
    onChange,
  } = props;
  const wrapperClass = classNames('checkbox', {
    'checkbox--minified': minified,
    'checkbox--disabled': disabled,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.checked, name);
    }
  };

  return (
    <div className={wrapperClass}>
      <label className="checkbox__label">
        <input
          type={type === CHECKBOX_TYPES.CHECKBOX ? 'checkbox' : 'radio'}
          checked={checked}
          onChange={handleChange}
          {...(type === CHECKBOX_TYPES.RADIO && { name: 'radio_group' })}
        />
        {type === CHECKBOX_TYPES.CHECKBOX ? (
          <div className="checkbox__mark" />
        ) : (
          <div className="checkbox__radio" />
        )}
        <div className="checkbox__text">{text}</div>
      </label>
    </div>
  );
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  title,
  labelKey,
  valueKey,
  onChange,
  value = [],
  error,
  ...rest
}) => {
  const wrapperClass = classNames('checkbox__group', {
    'checkbox__group--error': error,
  });

  const selected = value;

  const onSelectCheckbox = (val) => {
    let index = selected.indexOf(val);

    if (index === -1) {
      selected.push(val);
    } else {
      selected.splice(index, 1);
    }
    onChange(selected);
  };

  const renderCheckbox = () => {
    const isChecked = (val) => value.includes(val);

    return options.map((items) => (
      <Checkbox
        checked={isChecked(items[valueKey])}
        text={items[labelKey]}
        key={items[valueKey]}
        onChange={() => onSelectCheckbox(items[valueKey])}
        {...rest}
      />
    ));
  };

  return (
    <div className={wrapperClass}>
      {!!title && <p>{title}</p>}
      {renderCheckbox()}
    </div>
  );
};

export const RadioGroup = ({
  options,
  title,
  labelKey,
  valueKey,
  onChange,
  value,
  error,
  ...rest
}) => {
  const wrapperClass = classNames('checkbox__group', {
    'checkbox__group--error': error,
  });

  let selected = value;

  const onSelectCheckbox = (val) => {
    selected = val;
    onChange(selected);
  };

  const renderCheckbox = () => {
    const isChecked = (val) => value === val;

    return options.map((items) => (
      <Checkbox
        checked={isChecked(items[valueKey])}
        text={items[labelKey]}
        key={items[valueKey]}
        onChange={() => onSelectCheckbox(items[valueKey])}
        type={CHECKBOX_TYPES.RADIO}
        {...rest}
      />
    ));
  };

  return (
    <div className={wrapperClass}>
      {!!title && <p>{title}</p>}
      {renderCheckbox()}
    </div>
  );
};
