import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Button } from '../../_common/_controls/Button/Button';
import { Form } from '../../_common/Form/Form';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { TextArea } from '../../_common/_controls/TextArea/TextArea';
import { ApplicationState } from '../../../store';
import { TestingBroadcastFormEntity } from '../../../types/broadcast';
import { testingBroadcastFormEntityDefaults } from '../../../data/defaults';
import { useBroadcastTestingCentresRequest, useBroadcastTestingCodesRequest, useBroadcastTestingDatesRequest, useBroadcastTestingLotsRequest, useBroadcastTestingTypesRequest } from '../../../hooks/broadcast-hooks';
import { BroadcastState } from '../../../store/reducers/broadcast-reducer';
import { CommonState } from '../../../store/reducers/common-reducer';
import { isEmpty } from 'lodash';
import { useCommonRequest } from '../../../hooks/common-hooks';

interface Props {
  onSubmit: (data: TestingBroadcastFormEntity) => void;
}

export const TestingBroadcastForm: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const { loadCentres } = useBroadcastTestingCentresRequest();
  const { loadTypes } = useBroadcastTestingTypesRequest();
  const { loadLotNumbers } = useBroadcastTestingLotsRequest();
  const { loadCodes } = useBroadcastTestingCodesRequest();
  const { loadDates } = useBroadcastTestingDatesRequest();
  const { getApplicationsList } = useCommonRequest();
  const broadcastState = useSelector<ApplicationState, BroadcastState>((state) => state.broadcast);
  const { applicationsList } = useSelector<ApplicationState, CommonState>((state) => state.common);

  const { testing } = broadcastState;
  const { centres, types, lots, codes, dates } = testing;

  const [formData, setFormData] = useState<TestingBroadcastFormEntity>(testingBroadcastFormEntityDefaults);
  const { centre_id, type, identity_code, date, lot_no, all_centres, message, application_id } = formData;

  useEffect(() => {
    if (isEmpty(applicationsList)) getApplicationsList();
    loadCentres();
  }, []);

  useEffect(() => {
    if (centre_id) {
      loadTypes(centre_id);

      setFormData({
        ...formData,
        type: '',
        date: '',
        lot_no: '',
        identity_code: '',
      });
    }
  }, [centre_id]);

  useEffect(() => {
    if (type) {
      loadLotNumbers(centre_id, type);

      setFormData({
        ...formData,
        date: '',
        lot_no: '',
        identity_code: '',
      });
    }
  }, [type]);

  useEffect(() => {
    if (lot_no) {
      loadCodes(centre_id, type, lot_no);

      setFormData({
        ...formData,
        date: '',
        identity_code: '',
      });
    }
  }, [lot_no]);

  useEffect(() => {
    if (identity_code) {
      loadDates(centre_id, type, lot_no, identity_code);

      setFormData({
        ...formData,
        date: '',
      });
    }
  }, [identity_code]);
  const [dataError, setDataError] = useState({
    message: 'Required Field',
    messageDirty: false,
  });

  const onBlurArea = (name: string) => {
    switch (name) {
      case 'message':
        return formData.message.length === 0 ? setDataError({ ...dataError, messageDirty: true }) : setDataError({ ...dataError, messageDirty: false });
      default:
        return setDataError({ ...dataError, messageDirty: false });
    }
  };
  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async () => {
    if (dataError.messageDirty === true || application_id === '') {
      alert('Please check all fields');
    } else {
      const isBroadcastSent = await onSubmit(formData);
      // @ts-ignore
      if (isBroadcastSent) {
        setFormData(testingBroadcastFormEntityDefaults);
      }
    }
  };
  const checkMessage = formData.message.length === 0 ? true : false;
  return (
    <Form className="broadcast-form" onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={
          <FormGroup title="Broadcast" fullWidth>
            <div className="broadcast-form__group">
              <SelectMenu title="Unit" value={centre_id} name="centre_id" valueKey="id" labelKey="name" options={centres} disabled={all_centres} onChange={handleFieldChange} />
              <Checkbox checked={all_centres} name="all_centres" text="All" onChange={handleFieldChange} />
            </div>
            <SelectMenu title="Test Type" value={type} name="type" valueKey="type" labelKey="type" options={types} onChange={handleFieldChange} />
            <SelectMenu title="Lot No." value={lot_no} name="lot_no" valueKey="lot_no" labelKey="lot_no" options={lots} placeholder="Lot No." onChange={handleFieldChange} />
            <SelectMenu title="Identity Code" value={identity_code} name="identity_code" valueKey="identity_code" labelKey="identity_code" options={codes} placeholder="Identity Code" onChange={handleFieldChange} />
            <SelectMenu title="Date Administered" placeholder="Date Administered" value={date} name="date" valueKey="date" labelKey="label" options={dates} onChange={handleFieldChange} />
          </FormGroup>
        }
        rightContent={
          <FormGroup fullWidth>
            <SelectMenu title="Application" value={application_id} name="application_id" labelKey="name" valueKey="id" options={applicationsList || []} onChange={handleFieldChange} />
            <TextArea
              error={dataError.message && dataError.messageDirty ? true : false}
              onBlur={onBlurArea}
              title={dataError.message && dataError.messageDirty ? `Message-${dataError.message}` : 'Message'}
              placeholder="Message"
              value={message}
              onChange={handleFieldChange}
              name="message"
            />
          </FormGroup>
        }
        footer={<Button disabled={checkMessage} title="Create" htmlType="submit" />}
      />
    </Form>
  );
};
