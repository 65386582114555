import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { Input } from '../../../../components/_common/_controls/Input/Input';
import { Table } from '../../../../components/_common/Table/Table';
import { URLS } from '../../../../constants/urls';
import { useVaccinationUsersRequest } from '../../../../hooks/users-hooks';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { UsersState } from '../../../../store/reducers/users-reducer';
import { CommonObject } from '../../../../types/common';

export const VaccinationUsersPage: React.FC = () => {
  const {loadVaccinationUsers} = useVaccinationUsersRequest();
  const appUsersState = useSelector<ApplicationState, UsersState>((state) => state.users);
  const [searchParams, setSearchParams] = useState({search: ''});
  const {vaccinationUsers} = appUsersState;
  const {data, limit, total, page} = vaccinationUsers;
  const {search} = searchParams;

  useEffect(() => {
    loadVaccinationUsers(1, searchParams);
  }, []);

  const searchForUsers = useCallback(debounce(async (params: CommonObject) => {
    loadVaccinationUsers(1, params);
  }, 400), []);


  const handleSearch = (searchValue: string) => {
    const newParams = {...searchParams, search: searchValue};
    setSearchParams(newParams);
    searchForUsers(newParams);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Match Fit ID',
        accessor: 'id',
      },
      {
        Header: 'Vaccine Centre',
        accessor: 'centre_name',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Last Active',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {last_active} = original;
          console.log(data)
          return moment(last_active).format('YYYY-MM-DD');
          const checkActive = last_active !== null ? moment(last_active).format('YYYY-MM-DD') : null
          return checkActive
        },
      },
      {
        Header: 'View Profile',
        Cell: (data: any) => {
          const {row} = data;
          const {original} = row;
          const {id} = original;
          const url = `${URLS.VACCINATION_USER}/${id}`;

          return (
            <Link to={url}>View details</Link>
          );
        },
      },
    ],
    [],
  );

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadVaccinationUsers(nextPage, searchParams);
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadVaccinationUsers(prevPage, searchParams);
  };

  return (
    <div>
      <UsersHeader
        title="MFP Users"
        content={(
          <Input placeholder="Search users" value={search} search onChange={handleSearch} />
        )}
      />
      <div className="page-content">
        <Table
          data={data}
          columns={columns}
          page={page}
          limit={limit}
          total={total}
          onNext={handleGoToNextPage}
          onPrev={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};
