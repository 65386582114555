import { CommonEntityRes } from '../../types/common';
import { Band, Invoice } from '../../types/invoices';
import { CustomAction } from '../index';
import { INVOICES_ACTION_TYPES } from '../actions-types';
import { bandDefaults } from '../../data/defaults';

export interface InvoicesState {
  invoicesItems: CommonEntityRes<Invoice>;
  bandsItems: Band[];
  activeBand: Band;
}

const defaultState: InvoicesState = {
  invoicesItems: {data: [], page: 1},
  bandsItems: [],
  activeBand: bandDefaults,
};

export default function invoicesReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case INVOICES_ACTION_TYPES.SET_INVOICES: {
      return {
        ...state,
        invoicesItems: action.data,
      };
    }
    case INVOICES_ACTION_TYPES.SET_BANDS: {
      return {
        ...state,
        bandsItems: action.data,
      };
    }
    case INVOICES_ACTION_TYPES.SET_BAND: {
      return {
        ...state,
        activeBand: action.data,
      };
    }
    default:
      return state;
  }
}
