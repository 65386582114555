import React from 'react';
import { Svg } from '../../Svg/Svg';
import classNames from 'classnames';

import './Button.scss';

interface Props {
  title: string;
  htmlType?: "button" | "submit" | "reset";
  type?: 'primary'| 'primary-2' | 'secondary'| 'empty' | 'purple' | 'outline';
  variant?: 'solid' | 'outlined';
  tooltip?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: string;
}

export const Button: React.FC<Props> = (props) => {
  const {title, htmlType = 'button', type = 'primary', disabled, onClick, tooltip, icon, variant = 'solid' } = props;
  const wrapClass = classNames('button', {
    [`button--${type}`]: !!type,
    'button--disabled': disabled,
    'button--outlined': variant === 'outlined',
    [`button--outlined__${type}`]: variant === 'outlined',
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      title={tooltip}
      className={wrapClass}
      type={htmlType}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon && <Svg icon={icon} className='button--icon' />}
      <p>{title}</p>
    </button>
  );
};
