import React, { useState } from 'react';
import { Modal } from '../../_common/Modal/Modal';

import './ImageViewable.scss';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';

interface Props {
  imageUrl: string;
  placeholder: string;
}

export const ImageViewable: React.FC<Props> = (props) => {
  const { imageUrl, placeholder } = props;
  const [showFullSize, setShowFullSize] = useState(false);

  const isLoaded = !!imageUrl;

  const toggleFullSize = () => {
    setShowFullSize(!showFullSize);
  };

  return (
    <>
      <div className="pp-info">
        {isLoaded ? (
          <button
            type="button"
            className="pp-info__image-wrap"
            onClick={toggleFullSize}
          >
            <span className="pp-info__zoom-ic">
              <Svg icon={SVG_ICONS.ZOOM_IN} size={20} />
            </span>
            <img src={imageUrl} alt="" />
          </button>
        ) : (
          <div>{placeholder}</div>
        )}
      </div>
      <Modal isOpen={showFullSize} onClose={toggleFullSize}>
        <div className="pp-info-full">
          <img src={imageUrl} alt="" />
        </div>
      </Modal>
    </>
  );
};
