import React, { useEffect, useState } from 'react';
import { InvoiceHeader } from '../../../../components/invoice/InvoiceHeader/InvoiceHeader';
import { CreateInvoiceForm } from '../../../../components/invoice/CreateInvoiceForm/CreateInvoiceForm';
import { useHostsRequest } from '../../../../hooks/hosts-hooks';
import { useInvoicesRequest } from '../../../../hooks/invoices-hooks';
import { CreateInvoiceData } from '../../../../types/invoices';

export const InvoiceCreatePage: React.FC = () => {
  const {loadHosts} = useHostsRequest();
  const {createInvoice} = useInvoicesRequest();
  const [invoiceSaved, setInvoiceSaved] = useState(false);

  useEffect(() => {
    loadHosts();
  }, []);

  const handleSave = async (data: CreateInvoiceData) => {
    setInvoiceSaved(false);

    const invoiceCreated = createInvoice(data);

    if (invoiceCreated) {
      setInvoiceSaved(true);
    }
  };

  return (
    <div>
      <InvoiceHeader title="Create an Invoice and Support Schedule" hideActions />
      <div className="page-content">
        <CreateInvoiceForm isSaved={invoiceSaved} onSubmit={handleSave} />
      </div>
    </div>
  );
};
