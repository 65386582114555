import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { ApplicationState } from '../../../store';
import { VaccinationState } from '../../../store/reducers/vaccination-reducer';
import { useSelector } from 'react-redux';
import { VaccineUserFormEntity } from '../../../types/vaccination';
import { vaccineFormEntityDefaults } from '../../../data/defaults';
import { useVacCenterGetUserRequest } from '../../../hooks/vaccination-hooks';
import { UtilsUser } from '../../../utils/UtilsUser';

import './VaccineUserEditForm.scss';

interface Props {
  onSubmit(data: VaccineUserFormEntity): void;
}

export const VaccineUserEditForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const {getUser} = useVacCenterGetUserRequest();
  const vaccinationState = useSelector<ApplicationState, VaccinationState>(state => state.vaccination);
  const [formData, setFormData] = useState<VaccineUserFormEntity>(vaccineFormEntityDefaults);
  const {
    mfp_id,
    SubUserManagement,
    BroadcastMessage,
    AdministeredVaccines,
    RegisteredVaccines,
    CentreDetails,
    GenerateQRVaccine,
  } = formData;
  const {users} = vaccinationState;

  const loadUser = async (id: string) => {
    const userData = await getUser(id);

    if (userData) {
      const permissions = userData.permissions;
      const convertedPermissions = UtilsUser.getUserPermissionsValues(permissions);

      setFormData({
        ...formData,
        ...convertedPermissions,
      })
    }
  };

  useEffect(() => {
    if (mfp_id) {
      loadUser(mfp_id);
    }
  }, [mfp_id]);

  const handleFieldChange = (value: string | boolean, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form className="vcu-form" onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
            <SelectMenu
              title="Active User"
              value={mfp_id}
              name="mfp_id"
              labelKey="name"
              valueKey="id"
              options={users}
              placeholder="Active User"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={RegisteredVaccines}
              name="RegisteredVaccines"
              text="Registered Vaccines"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AdministeredVaccines}
              name="AdministeredVaccines"
              text="Administered Vaccines"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={CentreDetails}
              name="CentreDetails"
              text="Centre Details"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={GenerateQRVaccine}
              name="GenerateQRVaccine"
              text="Generate QR Vaccine"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Save" htmlType="submit" />
        )}
      />
    </Form>
  );
};
