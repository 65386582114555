import React, { useState } from 'react';
import { useNotificationsCount } from '../../hooks/notification-hooks';
import { Svg, SVG_ICONS } from '../_common/Svg/Svg';

import './Notifications.scss';
import { NotificationsPanel } from './NotificationsPanel/NotificationsPanel';

export const Notifications: React.FC = () => {
  const [showPanel, setShowPanel] = useState(false);
  const { data, isLoading, isError } = useNotificationsCount();

  const notificationsCount = isLoading || isError ? 0 : data.totalUnread;

  const getExtended = () => {
    if (notificationsCount > 99) return 'extended-2';
    if (notificationsCount > 9) return 'extended-1';
    return '';
  };

  return (
    <div className="notifications">
      <button className="notifications-button" onClick={() => setShowPanel(!showPanel)}>
        <Svg icon={SVG_ICONS.BELL} size={30} className="bell" />
      </button>
      {notificationsCount > 0 && (
        <div className={`notifications-badge ${getExtended()}`}>
          <div className={`notifications-badge-content ${getExtended()}`}>
            <div className="notifications-badge-content-counter">{notificationsCount > 99 ? '99+' : notificationsCount}</div>
          </div>
        </div>
      )}
      {showPanel && <NotificationsPanel hidePanel={() => setShowPanel(false)} />}
    </div>
  );
};
