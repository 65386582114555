import React, { ReactNode } from 'react';

import './EditCard.scss';

interface Props {
  leftContent: ReactNode;
  rightContent: ReactNode;
  footer: ReactNode;
}

export const EditCard: React.FC<Props> = (props) => {
  const {leftContent, rightContent, footer} = props;

  return (
    <div className="edit-card">
      <div className="edit-card__left">{leftContent}</div>
      <div className="edit-card__right">{rightContent}</div>
      <div className="edit-card__footer">{footer}</div>
    </div>
  );
};
