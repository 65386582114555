import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { FormFooter } from '../../_common/Form/FormFooter/FormFooter';
import { Button } from '../../_common/_controls/Button/Button';
import { ApplicationState } from '../../../store';
import { HostsState } from '../../../store/reducers/hosts-reducer';
import { CreateInvoiceData } from '../../../types/invoices';
import { invoiceCreateDefaults } from '../../../data/defaults';
import HostSelectMenu from '../../hosts/HostSelectMenu/HostSelectMenu';

interface Props {
  isSaved: boolean;
  onSubmit(data: CreateInvoiceData): void;
}

export const CreateInvoiceForm: React.FC<Props> = (props) => {
  const {isSaved, onSubmit} = props;
  const hostsState = useSelector<ApplicationState, HostsState>(state => state.hosts);
  const [formData, setFormData] = useState<CreateInvoiceData>(invoiceCreateDefaults);
  const {host_id, start, end} = formData;
  const {hostsItems} = hostsState;

  useEffect(() => {
    if (isSaved) {
      setFormData(invoiceCreateDefaults);
    }
  }, [isSaved]);
  const [dataError, setDataError] = useState({
    hostId: 'Required Field',
    hostIdDirty: false,
    start: 'Required Field',
    startDirty: false,
    end: 'Required Field',
    endDirty: false,
  })
  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    switch (fieldName) {
      case 'name' :
        return formData.host_id.length === 0 ?
          setDataError({...dataError, hostIdDirty: true}) :
          setDataError({...dataError, hostIdDirty: false})
      case 'address1' :
        return formData.start.length === 0 ?
          setDataError({...dataError, startDirty: true}) :
          setDataError({...dataError, startDirty: false})
      case 'key_contact' :
        return formData.end.length === 0 ?
          setDataError({...dataError, endDirty: true}) :
          setDataError({...dataError, endDirty: false})
      default:
        return setDataError({...dataError, endDirty: false})
    }

  };
  const handleFormSubmit = () => {
    if (formData.host_id.length === 0 ||
      formData.start.length === 0 ||
      formData.end.length === 0) {
      alert('Please check all fields')
    }else {
      return onSubmit(formData);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup fullWidth>
        <HostSelectMenu 
          initial_host_id={hostsState?.host?.data?.parent_host_id}
          onChange={(val) => {
            handleFieldChange(val, 'host_id');
          }}
        />
        {/* <SelectMenu
          error={dataError.hostId && dataError.hostIdDirty ? true : false}
          title={dataError.hostId && dataError.hostIdDirty ? `Select Host ID-${dataError.hostId}` : "Select Host ID"}
          placeholder="Select Host ID"
          value={host_id}
          options={hostsItems.data}
          name="host_id"
          labelKey="name"
          valueKey="id"
          onChange={handleFieldChange}
        /> */}
        <Datepicker
          error={dataError.start && dataError.startDirty ? true : false}
          title={dataError.start && dataError.startDirty ? `Between the following Dates:-${dataError.start}` : "Between the following Dates:"}
          placeholder="Start Date"
          name="start"
          value={start}
          returnString
          onChange={handleFieldChange}
        />
        <Datepicker
          error={dataError.end && dataError.endDirty ? true : false}
          title={dataError.end && dataError.endDirty ? `End Date-${dataError.hostId}` : "End Date"}
          placeholder="End Date"
          name="end"
          value={end}
          returnString
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormFooter>
        <Button title="Create" htmlType="submit" />
      </FormFooter>
    </Form>
  );
};
