import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { vaccineFormEntityDefaults } from '../../../data/defaults';
import { VaccineUserFormEntity } from '../../../types/vaccination';

interface Props {
  isSaved?: boolean;
  onSubmit(data: VaccineUserFormEntity): void;
}

export const VaccineUserCreateForm: React.FC<Props> = (props) => {
  const {isSaved, onSubmit} = props;

  const [formData, setFormData] = useState<VaccineUserFormEntity>(vaccineFormEntityDefaults);
  const {
    mfp_id,
    SubUserManagement,
    BroadcastMessage,
    RegisteredVaccines,
    AdministeredVaccines,
    CentreDetails,
    GenerateQRVaccine,
  } = formData;

  useEffect(() => {
    if (isSaved) {
      setFormData(vaccineFormEntityDefaults);
    }
  }, [isSaved]);

  const handleFieldChange = (value: string | boolean, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
            <Input title="MFP ID" value={mfp_id} name="mfp_id" placeholder="MFP ID" onChange={handleFieldChange} />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={RegisteredVaccines}
              name="RegisteredVaccines"
              text="Registered Vaccines"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AdministeredVaccines}
              name="AdministeredVaccines"
              text="Administered Vaccines"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={CentreDetails}
              name="CentreDetails"
              text="Centre Details"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={GenerateQRVaccine}
              name="GenerateQRVaccine"
              text="Generate QR Vaccine"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Create" htmlType="submit" />
        )}
      />
    </Form>
  );
};
