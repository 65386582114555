import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { OVERRIDE_STATUS, RegistrationStatus } from '../../../constants/users';
import { useAppUserRegistrationDetails } from '../../../hooks/users-hooks';
import { Button } from '../../_common/_controls/Button/Button';
import { UserInfoItem } from '../UserInfoItem/UserInfoItem';
import { CommonUtils } from '../../../utils/CommonUtils';
import { Card } from '../../_common/Card/Card';
import { Modal } from '../../_common/Modal/Modal';
import { ImageViewable } from '../../_common/ImageViewable/ImageViewable';

import './UserRegistrationDetails.scss';

interface Props {
  registration_status: string;
  id: string;
  code: string;
  fmcScore?: number;
  journeyId?: string;
  duplicates?: string | null;
  loadAppUser: Function;
}

export const UserRegistrationDetails: React.FC<Props> = (props) => {
  const {
    registration_status,
    code,
    id,
    loadAppUser,
    fmcScore,
    journeyId,
    duplicates = null,
  } = props;
  const {
    updateUserOverrideStatus,
    updatePhoneVerificationStatus,
    getUserPhoto,
  } = useAppUserRegistrationDetails();

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);
  const [showDuplicates, setShowDuplicates] = useState(false);

  useEffect(() => {
    if (id) getUserPhotos();
  }, [id]);

  const getUserPhotos = async () => {
    const profilePhotoResponse = await getUserPhoto(id, 'profile');
    if (
      profilePhotoResponse &&
      !profilePhotoResponse.error &&
      profilePhotoResponse.image
    ) {
      setProfilePhoto(
        CommonUtils.getImageUrlFromBase64(profilePhotoResponse.image)
      );
    } else {
      setProfilePhoto('');
    }
    const idPhotoResponse = await getUserPhoto(id, 'id');
    if (idPhotoResponse && !idPhotoResponse.error && idPhotoResponse.image) {
      setIdPhoto(CommonUtils.getImageUrlFromBase64(idPhotoResponse.image));
    } else {
      setIdPhoto('');
    }
  };

  const handleOverride = async (override_status: OVERRIDE_STATUS) => {
    await updateUserOverrideStatus(id, override_status, true);
    loadAppUser(id);
  };

  const handleSkipPhoneVerification = async () => {
    await updatePhoneVerificationStatus(id);
    loadAppUser(id);
  };

  const getDuplicatesModal = () => {
    let parsedDupes = [];
    try {
      parsedDupes = duplicates ? JSON.parse(duplicates) : null;
    } catch (e) {
      return null;
    }
    if (parsedDupes && !isEmpty(parsedDupes)) {
      // filter out null elements
      parsedDupes = parsedDupes.filter((dupe) => !isEmpty(dupe));
      return (
        <Modal isOpen={showDuplicates} onClose={() => setShowDuplicates(false)}>
          <div className="duplicates-list">
            <div className="duplicates-list__item-container-header">
              <div className="duplicates-list__item">
                <span className="duplicates-list__header-item">MFP ID</span>
              </div>
              <div className="duplicates-list__item">
                <span className="duplicates-list__header-item">
                  Duplicate Status
                </span>
              </div>
            </div>
            {parsedDupes.map((duplicate) => (
              <div className="duplicates-list__item-container">
                <div className="duplicates-list__item">{duplicate.id}</div>
                <div className="duplicates-list__item">
                  {duplicate.duplicateStatus}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      );
    }
    return null;
  };

  return (
    <div className="rd-details">
      {getDuplicatesModal()}
      <h2 className="rd-details__title">Registration</h2>
      <div className="rd-details__content">
        <div className="rd-details__info">
          <Card title="Registration Details">
            {code && <UserInfoItem title="Active Voucher Code" value={code} />}
            <UserInfoItem
              title="Registration Status"
              value={registration_status}
            />
            {registration_status === RegistrationStatus.ADMIN_REVIEW && (
              <UserInfoItem
                title="Reason for Review"
                value={CommonUtils.getReviewLabel(duplicates)}
                onPress={
                  CommonUtils.getReviewLabel(duplicates) === 'Duplicate'
                    ? () => setShowDuplicates(true)
                    : null
                }
              />
            )}
            {journeyId && (
              <UserInfoItem title="GBG Journey ID" value={journeyId} />
            )}
            {fmcScore && (
              <UserInfoItem
                title="FMC Score"
                value={`${CommonUtils.getPercentageFromDec(fmcScore)}%`}
              />
            )}
          </Card>
          <div className="rd-details__photos_container">
            <Card title="Profile Photo">
              {profilePhoto !== null && (
                <ImageViewable
                  imageUrl={profilePhoto}
                  placeholder="No Profile Photo"
                />
              )}
            </Card>
            <Card title="ID Document">
              {idPhoto !== null && (
                <ImageViewable
                  imageUrl={idPhoto}
                  placeholder="No ID Document"
                />
              )}
            </Card>
          </div>
        </div>
        <div className="rd-details__buttons">
          <Card title="Registration Actions">
            <div className="rd-details__actions">
              {registration_status === RegistrationStatus.PHONE_VERIFICATION ? (
                <Button
                  title="Skip Phone Verification"
                  type="secondary"
                  onClick={() => handleSkipPhoneVerification()}
                  tooltip="Allow user to skip phone verification"
                />
              ) : (
                <>
                  <Button
                    title="Complete Registration"
                    onClick={() => handleOverride(OVERRIDE_STATUS.OVERRIDE)}
                    tooltip="Completes Registration status through override"
                  />
                  <Button
                    title="Reject Registration"
                    type="secondary"
                    onClick={() => handleOverride(OVERRIDE_STATUS.REJECT)}
                    tooltip="Rejects/Restricts user access through override"
                  />
                  <Button
                    title="Revert Override"
                    type="secondary"
                    onClick={() => handleOverride(OVERRIDE_STATUS.DISABLE)}
                    tooltip="Reverts existing overrides and return to original registration status"
                  />
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
