import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Button } from '../../_common/_controls/Button/Button';
import { Input } from '../../_common/_controls/Input/Input';
import { bandCreateDefaults } from '../../../data/defaults';
import { Band, CreateBandData } from '../../../types/invoices';
import { UtilsBands } from '../../../utils/UtilsBands';

interface Props {
  data: Band;
  isSaved: boolean;
  isNew: boolean;
  onSubmit(data: CreateBandData): void;
}

export const BandForm: React.FC<Props> = (props) => {
  const {isSaved, isNew, data, onSubmit} = props;
  const [formData, setFormData] = useState<CreateBandData>(bandCreateDefaults);
  const {name, annual_charge, venue_size, price_per_ticket} = formData;
  const actionText = isNew ? 'Create' : 'Update';

  useEffect(() => {
    if (isSaved) {
      setFormData(bandCreateDefaults);
    }
  }, [isSaved]);

  useEffect(() => {
    const newData = UtilsBands.convertBandToBandDetails(data);
    setFormData(newData);
  }, [data.id]);

  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form title="Add a New Pricing Bands" onSubmit={handleFormSubmit}>
      <FormGroup fullWidth>
        <Input
          title="Band Name"
          placeholder="Band Name"
          value={name}
          name="name"
          onChange={handleFieldChange}
        />
        <Input
          title="Annual Charge"
          placeholder="Annual Charge"
          value={annual_charge}
          name="annual_charge"
          onChange={handleFieldChange}
        />
        <Input
          title="Venue Size"
          placeholder="Venue Size"
          value={venue_size}
          name="venue_size"
          onChange={handleFieldChange}
        />
        <Input
          title="Price Per Ticket"
          placeholder="Price Per Ticket"
          value={price_per_ticket}
          name="price_per_ticket"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormGroup fullWidth>
        <Button title={actionText} htmlType="submit" />
      </FormGroup>
    </Form>
  );
};
